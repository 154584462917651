import styled from '@emotion/styled';
import {
  BaseTheme,
  Box,
  dim,
  Header2,
  Header4,
  Logo,
  Text,
} from '@astc/frontend-components';
import {
  isSanityHeaderOrBody,
  isSanityImage,
  isSanityVimeoVideo,
} from '@/utils/sanity';
import { SourcedImage } from '@/components/media/SourcedImage';
import React from 'react';
import { Maybe } from '../../../../graphql-types';
import { down } from 'styled-breakpoints';
import Vimeo from '@u-wave/react-vimeo';

export const StyledGenericPage = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dim(956)};
  padding: 0 ${({ theme }) => (theme as BaseTheme).space[3]};
`;

export const GenericPageHeader = styled(Header2)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
  margin-bottom: ${({ theme }) => (theme as BaseTheme).space[4]};
  padding-top: ${({ theme }) => (theme as BaseTheme).space[5]};
  ${down('mobile')} {
    padding-top: ${({ theme }) => (theme as BaseTheme).space[4]};
  }
`;

export const VideoPlayer = styled(Box)`
  width: 100%;
  .vimeo {
    width: 100%;
  }
`;

export const GenericPage: React.FC<{
  title?: Maybe<string>;
  elements: unknown[];
}> = ({ title, elements }) => {
  return (
    <StyledGenericPage>
      <GenericPageHeader>
        {title}
        <Logo width={dim(68)} />
      </GenericPageHeader>

      {elements?.map((element) => {
        if (isSanityHeaderOrBody(element)) {
          return element._type === 'header' ? (
            <Header4 key={element._key} mb="3">
              {element.text}
            </Header4>
          ) : (
            <Box key={element._key} mb="3">
              {element.text?.split('\n').map((para) => (
                <Text key={para.substring(0, 15)} mb="2">
                  {para}
                </Text>
              ))}
            </Box>
          );
        }
        if (isSanityImage(element)) {
          return (
            <Box mb="4">
              <SourcedImage key={element._key} source={element} />
            </Box>
          );
        }

        if (isSanityVimeoVideo(element) && element.id) {
          return (
            <VideoPlayer mb="4">
              <Vimeo responsive className="vimeo" video={element.id} muted />
            </VideoPlayer>
          );
        }

        return null;
      })}
    </StyledGenericPage>
  );
};
