import { useDispatch } from 'react-redux';
import WelcomeStep from '@/app/features/user/components/WelcomeStep';
import { updateRole } from '@/app/features/user/slice';
import { UserRole } from '@astc/frontend-components';
import { navigate } from 'gatsby';
import { useHasUser } from '@/utils/hooks';
import { useEffect } from 'react';

interface WelcomePageContainerProps {}

const wpc: React.FC<WelcomePageContainerProps> = () => {
  const dispatch = useDispatch();
  const hasUser = useHasUser();

  useEffect(() => {
    if (!hasUser) {
      navigate('/onboard/signIn');
    }
  }, [hasUser]);

  const handleRoleSelect = (role: UserRole) => {
    return () => {
      dispatch(updateRole(role));
    };
  };

  const handleNextClick = () => {
    navigate('/');
  };

  /*
   * TODO: fetching user data on state idle goes here
   * */

  return (
    <WelcomeStep
      title="Welcome"
      body="Welcome! This is the body text"
      onRoleSelect={handleRoleSelect}
      onNextClick={handleNextClick}
    />
  );
};

export default wpc;
