import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Box } from '@astc/frontend-components';
import { RootState } from '../../app/store';
import { ForgetPasswordStep } from '../../app/features/user/components/ForgetPasswordStep';

interface ForgetPasswordPageContainerProps {}

const fppc: React.FC<ForgetPasswordPageContainerProps> = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => !!state.user.user?.uuid,
  );

  // TODO: double check that useEffect is what we want to do
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    navigate('/profile/view');
    // TODO: this might benefit from an onboarding state machine
  }, [isAuthenticated]);

  return (
    <Box>
      <ForgetPasswordStep
        title="Recover password"
        subtitle="You can recover your password here by entering your email address"
        body="This is the body text of the forget password page"
        onSubmit={() => {}}
      />
    </Box>
  );
};

export default fppc;
