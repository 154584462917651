import styled from '@emotion/styled';
import { BaseTheme, dim, Header1, Header3 } from '@astc/frontend-components';
import { position, PositionProps } from 'styled-system';
import { down } from 'styled-breakpoints';

export const CountryListSectionHeader1 = styled(Header1)<
  {
    alignment?: 'left' | 'right';
    alignmentMobile?: 'left' | 'right';
  } & PositionProps
>`
  position: absolute;
  display: flex;
  justify-content: ${({ alignment }) =>
    alignment === 'right' ? 'flex-end' : 'flex-start'};
  background: ${({ theme }) => (theme as BaseTheme).colors.white};
  border: ${dim(7)} solid ${({ theme }) => (theme as BaseTheme).colors.black};
  padding: ${({ theme }) => (theme as BaseTheme).space[4]};
  margin: 0 ${dim(223)} 0 ${dim(48)};
  ${down('mobile')} {
    justify-content: ${({ alignmentMobile }) =>
      alignmentMobile === 'right' ? 'flex-end' : 'flex-start'};
    border: ${dim(3)} solid ${({ theme }) => (theme as BaseTheme).colors.black};
    padding: ${({ theme }) => (theme as BaseTheme).space[3]};
    margin: 0 ${dim(16)};
    font-size: ${dim(64)};
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0;
  }
  ${position};
`;

export const CategoryListSectionHeader1 = styled(Header1)<
  {
    alignment?: 'left' | 'right';
  } & PositionProps
>`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => (theme as BaseTheme).colors.white};
  border: ${dim(7)} solid ${({ theme }) => (theme as BaseTheme).colors.black};
  padding: ${({ theme }) => (theme as BaseTheme).space[4]};
  margin: 0 ${dim(83)} 0 ${dim(48)};
  ${down('mobile')} {
    justify-content: ${({ alignment }) =>
      alignment === 'right' ? 'flex-end' : 'flex-start'};
    border: ${dim(3)} solid ${({ theme }) => (theme as BaseTheme).colors.black};
    padding: ${({ theme }) => (theme as BaseTheme).space[3]};
    margin: 0 ${dim(16)};
  }
  ${position};
`;
export const CountryListSectionHeader3 = styled(Header3)<{
  alignment?: 'left' | 'right';
}>`
  display: flex;
  flex-direction: ${({ alignment }) =>
    alignment === 'right' ? 'row-reverse' : 'row'};
`;

export const CategoryListSectionHeader3 = styled(Header3)<{
  alignment?: 'left' | 'right';
}>`
  display: flex;
  text-align: ${({ alignment }) => alignment};
  margin-bottom: ${dim(64)};
  ${down('mobile')} {
    margin-bottom: ${dim(32)};
  }
`;
