import fetch from 'isomorphic-fetch';

export const fetchAuthedData = async (path: string, token: string) => {
  return await fetch(`${process.env.GATSBY_READER_DATA_API_URL}${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postAuthedData = async (
  path: string,
  data: Record<string, string | number>,
  token: string,
) => {
  return await fetch(`${process.env.GATSBY_READER_DATA_API_URL}${path}`, {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putAuthedData = async (
  path: string,
  data: Record<string, string | number>,
  token: string,
) => {
  return await fetch(`${process.env.GATSBY_READER_DATA_API_URL}${path}`, {
    method: 'PUT',
    body: JSON.stringify({ data }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAuthedData = async (path: string, token: string) => {
  return await fetch(`${process.env.GATSBY_READER_DATA_API_URL}${path}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
