import React from 'react';
import { ReadingList } from '../../../../../types';
import { useDeleteReadingList, usePutReadingList } from '../../hooks';
import { ReadingListsOverlay } from '@astc/frontend-components';

export const ReadingListsPageEditOverlay: React.FC<{
  readingList: ReadingList;
  onClose: () => void;
}> = ({ readingList, onClose }) => {
  const { handlePutReadingList } = usePutReadingList(readingList.id);

  return (
    <ReadingListsOverlay
      title="Edit Reading List"
      listName={readingList.name}
      listDescription={readingList.description}
      isNew={false}
      onReadingListChange={(name, description) => {
        handlePutReadingList(name, description);
        onClose();
      }}
      onRequestClose={onClose}
    />
  );
};
export const ReadingListsPageDeleteOverlay: React.FC<{
  readingList: ReadingList;
  onClose: () => void;
}> = ({ readingList, onClose }) => {
  const { handleDeleteReadingList } = useDeleteReadingList(readingList.id);

  return (
    <ReadingListsOverlay
      title="Delete Reading List"
      listName={readingList.name}
      listDescription={readingList.description}
      isNew={false}
      isDeleting={true}
      onReadingListChange={() => {
        handleDeleteReadingList();
        onClose();
      }}
      onRequestClose={onClose}
    />
  );
};
