import { CreateAssignmentPage } from '@/app/features/panelResponses/components/CreateAssignmentPage';
import { StringParam, useQueryParam } from 'use-query-params';
import { useFetchUser } from '@/app/features/user/hooks';
import {
  useAvailableCourses,
  useCreateAssignment,
} from '@/app/features/panelResponses/hooks';
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { PageLoading } from '@/components/PageLoading';
import { useHasUser } from '@/utils/hooks';

interface CreateAssignmentPageContainerProps {}

const capc: React.FC<CreateAssignmentPageContainerProps> = () => {
  const [token] = useQueryParam('access_token', StringParam);
  const { user } = useFetchUser();
  const hasUser = useHasUser();
  const { submitted, url, error, handleCreate } = useCreateAssignment(
    token,
    user?.jwt,
  );
  const courses = useAvailableCourses(token, user?.jwt);

  useEffect(() => {
    if (hasUser !== false) {
      return;
    }
    navigate('/onboard/signIn');
  }, [hasUser]);

  useEffect(() => {
    if (hasUser === true && !token) {
      navigate('/profile/view');
    }
    return;
  }, [hasUser]);

  if (hasUser !== true) {
    return <PageLoading loading={'pending'} error={null} />;
  }

  const loading = submitted ? 'pending' : error ? 'failed' : 'idle';

  return (
    <CreateAssignmentPage
      courses={courses}
      loading={loading}
      error={error}
      url={url}
      onCreate={handleCreate}
    />
  );
};

export default capc;
