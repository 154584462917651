import styled from '@emotion/styled';
import {
  BaseTheme,
  Box,
  Toggle,
  dim,
  OptionLabel,
  ReadingExperience,
  roundedDesktopContainer,
  Button1,
  Text,
  baseTheme,
} from '@astc/frontend-components';
import { Icon } from '@iconify/react';
import caretUp from '@iconify/icons-radix-icons/caret-up';
import React, { useCallback, useState } from 'react';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export type StoryModeControlProps = {
  hasUser?: boolean;
  readingExperience?: ReadingExperience;
  onReadingExperienceSelect(mode: ReadingExperience): void;
};

const StyledStoryModeControl = styled(Box)`
  display: flex;
  box-sizing: border-box;
  position: fixed;
  bottom: ${dim(100)};
  right: ${({ theme }) => (theme as BaseTheme).space[3]};
  padding: ${({ theme }) => (theme as BaseTheme).space[3]};
  ${down('mobile')} {
    display: flex;
    bottom: ${({ theme }) => (theme as BaseTheme).space[4]};
    top: auto;
    right: auto;
    width: 100%;
  }
  z-index: 8;
`;

const StoryModeContainer = styled(Box)<{ hasUser?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 130px;
  ${down('mobile')} {
    height: auto;
    flex-direction: row;
    width: ${({ hasUser }) => (!!hasUser ? '100%' : 'auto')};
    margin: 0 auto;
    ${({ theme }) => roundedDesktopContainer(theme as BaseTheme)};
    padding: ${({ theme }) => (theme as BaseTheme).space[3]};
  }
  padding: ${({ theme }) => (theme as BaseTheme).space[3]};
`;

export const StoryModeOptionSet = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${down('mobile')} {
    width: auto;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
`;

export const StoryModeOptionLabel = styled(OptionLabel)`
  color: ${({ theme }) => (theme as BaseTheme).colors.grays[80]};
  font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[1]};
  margin: 0;
  align-items: baseline;
  ${down('mobile')} {
    font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[0]};
  }
`;

export const StoryModeTopLink = styled.a`
  color: ${({ theme }) => (theme as BaseTheme).colors.blues[60]};
  font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[1]};
  ${down('mobile')} {
    font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[0]};
  }

  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => (theme as BaseTheme).colors.blues[60]};
  font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[1]};
  margin: ${dim(28)} 0 0 0;
  ${down('mobile')} {
    margin-top: 0;
    width: auto;
    font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[0]};
  }
  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    text-decoration: none;
    color: ${({ theme }) => (theme as BaseTheme).colors.blues[60]};
  }
`;

export const StoryModeControl: React.FC<StoryModeControlProps> = ({
  hasUser,
  readingExperience,
  onReadingExperienceSelect,
}) => {
  const [readingExperienceValue, setReadingExperienceValue] = useState(
    readingExperience || ReadingExperience.INTERACTIVE,
  );

  const handleReadingExperienceInteractiveChange = useCallback(() => {
    setReadingExperienceValue(ReadingExperience.INTERACTIVE);
    onReadingExperienceSelect(ReadingExperience.INTERACTIVE);
  }, []);

  const handleReadingExperienceReadOnlyChange = useCallback(() => {
    setReadingExperienceValue(ReadingExperience.READ_ONLY);
    onReadingExperienceSelect(ReadingExperience.READ_ONLY);
  }, []);

  const { mobile: isMobile } = useBreakpoint();

  return (
    <StyledStoryModeControl>
      {!isMobile && (
        <StoryModeContainer hasUser={hasUser}>
          {hasUser && (
            <Toggle
              top="Learning Mode"
              bottom="Read-Only Mode"
              initial={readingExperienceValue === ReadingExperience.READ_ONLY}
              onChange={(value: boolean) => {
                if (value) {
                  handleReadingExperienceInteractiveChange();
                } else {
                  handleReadingExperienceReadOnlyChange();
                }
              }}
            />
          )}
          <StoryModeTopLink href="#top">
            <Button1 palette="blue">
              <Box py="2">
                <Box m={0} p={0} style={{ position: 'absolute', top: dim(-2) }}>
                  <Icon icon={caretUp} height={32} />
                </Box>
                <Box mt={dim(12)} p={0}>
                  TOP
                </Box>
              </Box>
            </Button1>
          </StoryModeTopLink>
        </StoryModeContainer>
      )}
      {isMobile && (
        <StoryModeContainer hasUser={hasUser}>
          {hasUser && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingRight: dim(16),
              }}
            >
              <StoryModeOptionSet>
                <input
                  type="radio"
                  id="interactive"
                  name="readingExperience"
                  checked={
                    readingExperienceValue === ReadingExperience.INTERACTIVE
                  }
                  onChange={handleReadingExperienceInteractiveChange}
                  style={{ height: '10px' }}
                />
                <StoryModeOptionLabel htmlFor="interactive">
                  Learning Mode
                </StoryModeOptionLabel>
              </StoryModeOptionSet>
              <StoryModeOptionSet>
                <input
                  type="radio"
                  id="read-only"
                  name="readingExperience"
                  checked={
                    readingExperienceValue === ReadingExperience.READ_ONLY
                  }
                  onChange={handleReadingExperienceReadOnlyChange}
                  style={{ height: '10px' }}
                />
                <StoryModeOptionLabel htmlFor="read-only">
                  Read-Only Mode
                </StoryModeOptionLabel>
              </StoryModeOptionSet>
            </Box>
          )}
          <StoryModeOptionSet>
            <StoryModeTopLink href="#top">
              <Box m={0} p={0} style={{ position: 'absolute', top: dim(-4) }}>
                <Icon icon={caretUp} height={32} />
              </Box>
              <Text
                mt={dim(16)}
                p={0}
                style={{
                  color: baseTheme.colors.blues[60],
                }}
              >
                Top
              </Text>
            </StoryModeTopLink>
          </StoryModeOptionSet>
        </StoryModeContainer>
      )}
    </StyledStoryModeControl>
  );
};
