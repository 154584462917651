import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import badgesReducer from './features/badges/slice';
import panelResponsesReducer from './features/panelResponses/slice';
import readingListsReducer from './features/readingLists/slice';
import userReducer from './features/user/slice';
import feedbackReducer from './features/feedbacks/slice';

const reducer = combineReducers({
  badges: badgesReducer,
  panelResponses: panelResponsesReducer,
  readingLists: persistReducer(
    { key: 'readingLists', version: 1, storage, whitelist: ['recentlyRead'] },
    readingListsReducer,
  ),
  user: persistReducer(
    {
      key: 'user',
      version: 1,
      storage,
      whitelist: ['user'],
      transforms: [
        expireReducer('user', {
          expireSeconds: 60 * 60 * 12, // TODO: make expiration longer than 12 hours
          expiredState: {},
          persistedAtKey: 'issuedAt',
        }),
      ],
    },
    userReducer,
  ),
  feedbacks: feedbackReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
