import { createAsyncThunk } from '@reduxjs/toolkit';
import { isPlainObject, UserDataState } from '@/types';
import { ReadingExperience, UserRole } from '@astc/frontend-components';
import fetch from 'isomorphic-fetch';

export interface FetchUserResponseData {
  jwt: string;
  user: {
    uuid: string;
    email: string;
  };
}

export const isFetchUserResponseData = (
  data: unknown,
): data is FetchUserResponseData => {
  if (!isPlainObject(data)) {
    return false;
  }
  if (!data.hasOwnProperty('user') || !isPlainObject(data.user)) {
    return false;
  }
  return (
    data.hasOwnProperty('jwt') &&
    data.hasOwnProperty('user') &&
    data.user.hasOwnProperty('uuid') &&
    data.user.hasOwnProperty('email')
  );
};

export const fetchUser = createAsyncThunk<
  UserDataState,
  string,
  { rejectValue: Error }
>('user/fetchUser', async (token, thunkAPI) => {
  const response = await fetch(
    `${process.env.GATSBY_AUTH_CALLBACK_URL}?access_token=${token}`,
  );
  const data = await response.json();
  if (!isFetchUserResponseData(data)) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }
  const {
    jwt,
    user: { uuid, email },
  } = data;

  if (!jwt) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }
  const jwtDataString = atob(jwt.split('.')[1]);
  const jwtData = JSON.parse(jwtDataString);
  if (
    !isPlainObject(jwtData) &&
    !jwtData.hasOwnProperty('iat') &&
    !jwtData.hasOwnProperty('exp')
  ) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }

  const { iat, exp } = jwtData;

  const user: UserDataState = {
    user: {
      uuid,
      email,
      jwt,
      issuedAt: iat * 1000,
      expiresAt: exp * 1000,
      role: UserRole.STUDENT, // TODO: set role from auth service
      mode: ReadingExperience.INTERACTIVE,
    },
  };

  try {
    return user;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkAPI.rejectWithValue(error);
    }
    return thunkAPI.rejectWithValue(new Error('Unknown error'));
  }
});

export const fetchEmailUser = createAsyncThunk<
  UserDataState,
  { email: string; password: string },
  { rejectValue: Error }
>('user/fetchEmailUser', async ({ email, password }, thunkAPI) => {
  const response = await fetch(
    `${process.env.GATSBY_READER_DATA_API_URL}/auth/local`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier: email, password }),
    },
  );
  const data = await response.json();

  if (!isFetchUserResponseData(data)) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }
  const {
    jwt,
    user: { uuid },
  } = data;

  if (!jwt) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }
  const jwtDataString = atob(jwt.split('.')[1]);
  const jwtData = JSON.parse(jwtDataString);
  if (
    !isPlainObject(jwtData) &&
    !jwtData.hasOwnProperty('iat') &&
    !jwtData.hasOwnProperty('exp')
  ) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }

  const { iat, exp } = jwtData;

  const user: UserDataState = {
    user: {
      uuid,
      email,
      jwt,
      issuedAt: iat * 1000,
      expiresAt: exp * 1000,
      role: UserRole.STUDENT, // TODO: set role from auth service
      mode: ReadingExperience.INTERACTIVE,
    },
  };

  try {
    return user;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkAPI.rejectWithValue(error);
    }
    return thunkAPI.rejectWithValue(new Error('Unknown error'));
  }
});

export const fetchEmailSignUpUser = createAsyncThunk<
  UserDataState,
  { email: string; password: string },
  { rejectValue: Error }
>('user/fetchEmailSignUpUser', async ({ email, password }, thunkAPI) => {
  const response = await fetch(
    `${process.env.GATSBY_READER_DATA_API_URL}/auth/local/register`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
        email,
        password,
      }),
    },
  );
  const data = await response.json();
  if (!isFetchUserResponseData(data)) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }
  const {
    jwt,
    user: { uuid },
  } = data;

  if (!jwt) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }
  const jwtDataString = atob(jwt.split('.')[1]);
  const jwtData = JSON.parse(jwtDataString);
  if (
    !isPlainObject(jwtData) &&
    !jwtData.hasOwnProperty('iat') &&
    !jwtData.hasOwnProperty('exp')
  ) {
    return thunkAPI.rejectWithValue(new Error('Invalid data'));
  }

  const { iat, exp } = jwtData;

  const user: UserDataState = {
    user: {
      uuid,
      email,
      jwt,
      issuedAt: iat * 1000,
      expiresAt: exp * 1000,
      role: UserRole.STUDENT, // TODO: set role from auth service
      mode: ReadingExperience.INTERACTIVE,
    },
  };

  try {
    return user;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkAPI.rejectWithValue(error);
    }
    return thunkAPI.rejectWithValue(new Error('Unknown error'));
  }
});
