import React from 'react';
import { ErrorBoundary, FallbackRender } from '@sentry/react';
import { Link } from 'gatsby';

export interface ErrorBoundaryContainerProps {
  children: React.ReactNode | (() => React.ReactNode);
}

// TODO: style this ErrorBoundary FallbackRender as appropriate
const Fallback: FallbackRender = ({
  error,
  resetError,
}: {
  error: Error;
  resetError: () => void;
}) => (
  <div role="alert">
    <p>Something went wrong</p>
    <pre>{error.message}</pre>
    <Link to="/" onClick={resetError}>
      <a>Try again</a>
    </Link>
  </div>
);

const ErrorBoundaryContainer: React.FC<ErrorBoundaryContainerProps> = ({
  children,
}) => <ErrorBoundary fallback={Fallback}>{children}</ErrorBoundary>;

export default ErrorBoundaryContainer;
