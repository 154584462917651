import React from 'react';
import { Box } from '@astc/frontend-components';
import { graphql, PageProps } from 'gatsby';
import styled from '@emotion/styled';
import { SanityPageHome, SanityPageHomeQuery } from '../../graphql-types';
import { Intro } from '@/components/home/Intro';
import { FeaturedProps, IntroProps } from '@/types';
import { Featured } from '@/components/home/Featured';
import { FeaturedStories } from '@/components/FeaturedStories';
//  import { SignUp } from '@/components/home/SignUp';
//  import { useHasUser } from '@/utils/hooks';

type HomeProps = { data: SanityPageHomeQuery } & PageProps;

const StyledHome = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Home: React.FC<HomeProps> = ({ data }) => {
  const { sanityPageHome } = data;
  //  const hasUser = useHasUser();

  if (!sanityPageHome) return null;

  const { intro, featured, featureBlocks } = sanityPageHome;

  return (
    <StyledHome>
      {intro && <Intro intro={intro as IntroProps['intro']} />}
      {featured && (
        <Featured featured={featured as FeaturedProps['featured']} />
      )}
      {featureBlocks &&
        (featureBlocks as SanityPageHome['featureBlocks'])?.map(
          (block, index) => {
            if (!block) return null;
            return (
              <FeaturedStories
                key={index}
                title={block.title}
                subtitle={block.subtitle}
                stories={block.stories}
                size={block.size}
                clear={true}
              />
            );
          },
        )}
    </StyledHome>
  );
};

export const query = graphql`
  query SanityPageHome {
    sanityPageHome {
      featureBlocks {
        size
        subtitle
        title
        stories {
          _id
          layeredThumbnailsDesktopHorizontal: layeredThumbnails {
            ...SanityVisualDesktopHorizontalLayeredThumbnailFragment
          }
          layeredThumbnailsMobileHorizontal: layeredThumbnails {
            ...SanityVisualMobileHorizontalLayeredThumbnailFragment
          }
          layeredThumbnailsDesktopVertical: layeredThumbnails {
            ...SanityVisualDesktopVerticalLayeredThumbnailFragment
          }
          layeredThumbnailsMobileVertical: layeredThumbnails {
            ...SanityVisualMobileVerticalLayeredThumbnailFragment
          }
          thumbnail {
            ...SanityImageFragment
          }
          title
          creators {
            creator {
              _key
              ...SanityCreatorFragment
            }
          }
          categories {
            _key
            name
          }
          country {
            _key
            name
          }
          description {
            ...SanityBlockFragment
          }
        }
        backgroundDesktop {
          ...SanityImageFragment
        }
        backgroundMobile {
          ...SanityImageFragment
        }
      }
      featured {
        title
        backgroundDesktop {
          ...SanityImageFragment
        }
        backgroundMobile {
          ...SanityImageFragment
        }
        featured {
          title
          subtitle
          size
          backgroundDesktop {
            ...SanityImageFragment
          }
          backgroundMobile {
            ...SanityImageFragment
          }
          stories {
            _id
            categories {
              _key
              name
            }
            country {
              _key
              name
            }
            creators {
              role
              creator {
                _key
                name
                bio {
                  ...SanityBlockFragment
                }
                image {
                  ...SanityImageFragment
                }
              }
            }
            layeredThumbnailsDesktopHorizontal: layeredThumbnails {
              ...SanityVisualDesktopHorizontalLayeredThumbnailFragment
            }
            layeredThumbnailsMobileHorizontal: layeredThumbnails {
              ...SanityVisualMobileHorizontalLayeredThumbnailFragment
            }
            layeredThumbnailsDesktopVertical: layeredThumbnails {
              ...SanityVisualDesktopVerticalLayeredThumbnailFragment
            }
            layeredThumbnailsMobileVertical: layeredThumbnails {
              ...SanityVisualMobileVerticalLayeredThumbnailFragment
            }
            thumbnail {
              ...SanityImageFragment
            }
            layeredThumbnails {
              midLayer {
                asset {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: BLURRED
                    width: 120
                  )
                }
              }
              upperLayer {
                asset {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: BLURRED
                    width: 120
                  )
                }
              }
              alt
              lowerLayer {
                asset {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: BLURRED
                    width: 120
                  )
                }
              }
              type
            }
            description {
              ...SanityBlockFragment
            }
            title
          }
        }
        stories {
          _id
          categories {
            _key
            name
          }
          country {
            _key
            name
          }
          creators {
            creator {
              _key
              name
            }
          }
          description {
            ...SanityBlockFragment
          }
          featuredArt {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 576
              )
            }
          }
          featuredArtAlignment
          featuredArtButtonTheme
          featuredArtCaptionColor {
            _key
            hex
          }
          thumbnail {
            ...SanityImageFragment
          }
          title
          description {
            ...SanityBlockFragment
          }
        }
      }
      intro {
        backgroundDesktop {
          ...SanityImageFragment
        }
        backgroundMobile {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              width: 1080
            )
          }
        }
        body
        title
        mainArtDesktop: mainArt {
          asset {
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 750)
          }
        }
        mainArtMobile: mainArt {
          asset {
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 350)
          }
        }
        artLionDesktop: artLion {
          ...SanityImageFragment
        }
        artLionMobile: artLion {
          ...SanityImageFragment
        }
      }
      signup {
        title
        bodyDesktop {
          ...SanityBlockFragment
        }
        _rawBodyDesktop
        titleMobile
        subtitleMobile
        backgroundDesktop {
          ...SanityImageFragment
        }
        backgroundMobile {
          ...SanityImageFragment
        }
      }
    }
  }
`;

export default Home;
