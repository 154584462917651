import BackgroundImage, {
  IBackgroundImageProps,
  IFluidObject,
} from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import styled from '@emotion/styled';
import React from 'react';
import { Box } from '@astc/frontend-components';
import {
  layout,
  LayoutProps,
  padding,
  PaddingProps,
  space,
  SpaceProps,
} from 'styled-system';
import { Maybe, SanityImage } from '../../../graphql-types';
import { useMobileSized } from '../../utils/hooks';

type PageBackgroundProps = {
  desktop?: Maybe<SanityImage>;
  mobile?: Maybe<SanityImage>;
  className?: string;
  minHeight?: string;
  style?: React.CSSProperties;
};

function convertSanityToBgImage(image?: Maybe<SanityImage>) {
  const imageData = image?.asset
    ? getImage(image?.asset?.gatsbyImageData)
    : undefined;
  return imageData ? convertToBgImage(imageData) : null;
}

export const _PageBackground: React.FC<PageBackgroundProps> = ({
  desktop,
  mobile,
  className,
  style,
  minHeight: _minHeight,
  children,
}) => {
  const { isMobile, sized } = useMobileSized();
  const backgroundDesktopImage = convertSanityToBgImage(desktop);
  const backgroundMobileImage = convertSanityToBgImage(mobile);

  if (
    !sized ||
    (isMobile && !backgroundMobileImage) ||
    (!isMobile && !backgroundDesktopImage)
  ) {
    return (
      <Box
        className={className}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        style={style}
      >
        {children}
      </Box>
    );
  }

  const minHeight =
    _minHeight ??
    (isMobile
      ? mobile?.asset?.gatsbyImageData?.height
      : desktop?.asset?.gatsbyImageData?.height) ??
    'auto';

  const { fluid: desktopFluid } =
    (backgroundDesktopImage as IBackgroundImageProps) || { fluid: undefined };
  const { fluid: mobileFluid } =
    (backgroundMobileImage as IBackgroundImageProps) || { fluid: undefined };
  const fluid: IFluidObject[] = [mobileFluid, desktopFluid].reduce<
    IFluidObject[]
  >((acc: IFluidObject[], fluid: IFluidObject, index): IFluidObject[] => {
    if (!fluid) {
      return acc;
    }
    if (index === 0) {
      return [...acc, fluid];
    }
    return [
      ...acc,
      {
        ...fluid,
        media: `(min-width: 1080px)`,
      },
    ];
  }, [] as IFluidObject[]);

  return (
    <BackgroundImage
      Tag="section"
      fluid={fluid}
      className={className}
      style={{
        ...style,
        minHeight,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {children}
      </Box>
    </BackgroundImage>
  );
};

export const PageBackground = styled(_PageBackground)<
  LayoutProps & SpaceProps & PaddingProps & { position?: string }
>`
  box-sizing: border-box;
  background-position: ${({ position }) => {
    return position || 'top center';
  }};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  ${layout};
  ${space};
  ${padding};

  @media (max-width: 1080px) and (min-width: 768px) {
    background-image: none !important;
    &::before,
    &::after {
      background-image: none !important;
    }
  }
`;
