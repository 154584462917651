import React from 'react';
import {
  Maybe,
  SanityCategory,
  SanityCountry,
  SanityImage,
  SanityStory,
} from 'graphql-types';
import styled from '@emotion/styled';
import { Box, dim } from '@astc/frontend-components';
import { PageBackground } from '@/components/PageBackground';
import {
  CategoryListSectionHeader1,
  CategoryListSectionHeader3,
} from '@/components/collections/ListSectionHeader';
import { isAlignment } from '@/components/collections';
import {
  ListSectionCategoryContent,
  ListSectionCategoryStories,
} from '@/components/collections/ListSectionStories';
import { ComicCardContainer } from '@/components/FeaturedStories/ComicCardContainer';

type CategoryListSectionProps = {
  index: number;
  country: SanityCountry;
  category: SanityCategory;
  stories: SanityStory[];
  alignment: string;
  isDisplayingCountry: boolean;
  backgroundDesktop?: Maybe<SanityImage>;
  backgroundMobile?: Maybe<SanityImage>;
};

const _CategoryListSection: React.FC<CategoryListSectionProps> = ({
  country,
  category,
  stories,
  alignment,
  isDisplayingCountry,
  backgroundDesktop,
  backgroundMobile,
}) => {
  return (
    <PageBackground desktop={backgroundDesktop} mobile={backgroundMobile}>
      <Box key={category.name} mt="4" mb="3" width="100%">
        {isDisplayingCountry && (
          <Box
            width="100%"
            height="100%"
            position="absolute"
            top={[dim(85), dim(153)]}
            display="flex"
            flexDirection="row"
            justifyContent={alignment === 'left' ? 'flex-end' : 'flex-start'}
          >
            <CategoryListSectionHeader1
              alignment={alignment === 'left' ? 'right' : 'left'}
              mb="4"
            >
              {country.name}
            </CategoryListSectionHeader1>
          </Box>
        )}
        <ListSectionCategoryContent
          top={[
            dim(isDisplayingCountry ? 398 : 64),
            dim(isDisplayingCountry ? 977 : 146),
          ]}
          width="100%"
          alignment={isAlignment(alignment) ? alignment : 'left'}
        >
          <CategoryListSectionHeader3
            alignment={isAlignment(alignment) ? alignment : 'left'}
            mb="4"
          >
            {category.name}
          </CategoryListSectionHeader3>
          <ListSectionCategoryStories
            alignment={isAlignment(alignment) ? alignment : 'left'}
          >
            {stories.map((story) => {
              return (
                <ComicCardContainer
                  key={story._key}
                  story={story}
                  clear={true}
                />
              );
            })}
          </ListSectionCategoryStories>
        </ListSectionCategoryContent>
      </Box>
    </PageBackground>
  );
};

export const CategoryListSection = styled(_CategoryListSection)`
  margin-top: ${dim(43)};
`;
