import {
  BadgeReader,
  BadgeReaderResult,
  BadgeResult,
  BadgesResult,
  BadgeStoryResult,
  RawBadge,
  RawBadgeReader,
} from '@/types';
import { Maybe, SanityImage } from '../../../../graphql-types';

export const convertRawBadgeToBadgeResult = (
  rawBadge: RawBadge,
): BadgeResult[] => {
  const stories = rawBadge.badges;

  return stories.map((badge) => {
    const thumbnail = badge.image as Maybe<SanityImage>;
    const full = badge.image as Maybe<SanityImage>;

    return {
      id: badge._id,
      rawBadge,
      name: badge.name,
      trigger: badge.trigger,
      images: {
        thumbnail,
        full,
      },
      stories: [rawBadge._id], // the story_id is the same as the rawBadge._id
      unlocked: false,
      loading: 'succeeded',
      error: null,
    };
  });
};

export const convertRawBadgeToBadgeStoryResult = (
  rawBadge: RawBadge,
): BadgeStoryResult => {
  const badges = rawBadge.badges.map((badge) => {
    return {
      ...badge,
      id: badge._id,
      images: {
        thumbnail: badge.image as Maybe<SanityImage>,
        full: badge.image as Maybe<SanityImage>,
      },
      stories: [rawBadge._id],
      unlocked: false,
    };
  });

  return {
    id: rawBadge._id,
    title: rawBadge.title,
    thumbnail: rawBadge.thumbnail as Maybe<SanityImage>,
    rawBadge,
    badges,
    loading: 'succeeded',
    error: null,
  };
};

export const convertRawBadgeReaderToBadgeReader = (
  badgeReader: RawBadgeReader,
): BadgeReader => {
  return {
    badgeId: badgeReader.attributes.badge_id,
  };
};

export const convertBadgeReaderToBadgeReaderResult = (
  badgeReader: BadgeReader,
): BadgeReaderResult => ({
  badgeReader,
  loading: 'succeeded',
  error: null,
});

export function constructBadgeUpdate(badgesResult: BadgesResult) {
  return (badgeReader: BadgeReader) => {
    const stories = badgesResult.entities[badgeReader.badgeId]?.stories;
    if (!stories || stories.length === 0) {
      return {
        id: badgeReader.badgeId,
        changes: {},
      };
    }
    return {
      id: badgeReader.badgeId,
      changes: {
        stories: { ...stories },
      },
    };
  };
}
