import styled from '@emotion/styled';
import { Box, dim } from '@astc/frontend-components';
import { position, PositionProps } from 'styled-system';
import { down } from 'styled-breakpoints';

export const ListSectionStories = styled(Box)<
  { alignment: 'left' | 'right' } & {
    alignmentMobile: 'left' | 'right';
  } & PositionProps
>`
  display: flex;
  position: absolute;
  width: 100%;
  padding: 0 ${dim(80)};
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${({ alignment }) =>
    alignment === 'right' ? 'flex-end' : 'flex-start'};

  ${down('mobile')} {
    justify-content: ${({ alignmentMobile }) =>
      alignmentMobile === 'right' ? 'flex-end' : 'flex-start'};
    padding: 0 ${dim(8)};
  }

  ${position}
`;

export const ListSectionCategoryContent = styled(Box)<
  { alignment: 'left' | 'right' } & PositionProps
>`
  display: flex;
  position: absolute;
  width: 100%;
  padding: ${dim(160)} ${dim(56)} 0 ${dim(56)};
  align-items: ${({ alignment }) =>
    alignment === 'right' ? 'flex-end' : 'flex-start'};
  flex-direction: column;
  justify-content: center;

  ${down('mobile')} {
    padding: 0 ${dim(8)};
  }

  ${position}
`;

export const ListSectionCategoryStories = styled(Box)<
  { alignment: 'left' | 'right' } & PositionProps
>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${({ alignment }) =>
    alignment === 'right' ? 'flex-end' : 'flex-start'};

  ${down('mobile')} {
    padding: 0;
  }

  ${position}
`;

export const isAlignment = (
  alignment: unknown,
): alignment is 'left' | 'right' => {
  return (
    typeof alignment === 'string' &&
    (alignment === 'left' || alignment === 'right')
  );
};
