import {
  Box,
  Button1,
  Header5,
  Header6,
  PageBoundary,
  RoundedBox,
  Text,
} from '@astc/frontend-components';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useFetchUser } from '@/app/features/user/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectReadingListStoryById } from '@/app/features/readingLists/slice';
import { SelectInput } from '@/app/features/panelResponses/components/CreateAssignmentPage';
import { PageBackground } from '@/components/PageBackground';
import { useAvailableCourses } from '@/app/features/panelResponses/hooks';
import { navigate } from 'gatsby';
import { PageLoading } from '@/components/PageLoading';
import { useHasUser } from '@/utils/hooks';

export const ProfileRedirectPage: React.FC = () => {
  const [token] = useQueryParam('access_token', StringParam);
  const [storyId, setStoryId] = useState<string | null>('story_id');
  const { user } = useFetchUser();
  const hasUser = useHasUser();
  const [activeCourse, setActiveCourse] = useState<Record<
    string,
    string
  > | null>(null);
  const [cws, setCws] = useState<Record<string, string>[]>([]);
  const [activeCw, setActiveCw] = useState<Record<string, string> | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const story = useSelector((state: RootState) => {
    if (!storyId) return;
    return selectReadingListStoryById(state.readingLists, storyId);
  });

  useEffect(() => {
    setStoryId(localStorage.getItem('submittedStory'));
  }, []);

  const courses = useAvailableCourses(token, user?.jwt);

  useEffect(() => {
    if (!token || !user || !activeCourse) {
      return;
    }
    fetch(
      `${process.env.GATSBY_READER_DATA_API_URL}/responses/courseWork/${activeCourse.id}?token=${token}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.jwt}`,
        },
      },
    ).then(async (response) => {
      if (!response.ok) {
        setError('You must be logged in to submit an assignment.');
      }
      setCws(await response.json());
    });
  }, [user, token, activeCourse]);

  useEffect(() => {
    if (hasUser !== false) {
      return;
    }
    navigate('/onboard/signIn');
  }, [hasUser]);

  if (hasUser !== true) {
    return <PageLoading loading={'pending'} error={null} />;
  }

  const onSubmit = () => {
    if (!token || !user || !activeCw || !activeCourse || !story) {
      return;
    }
    setSubmitted(true);
    fetch(
      `${process.env.GATSBY_READER_DATA_API_URL}/responses/submission?token=${token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.jwt}`,
        },
        body: JSON.stringify({
          data: {
            course_id: activeCourse.id,
            cw_id: activeCw.id,
            url: `${process.env.GATSBY_SITE_URL}/story/${story.id}`,
          },
        }),
      },
    ).then(async (response) => {
      const result = await response.json();
      if (result.error) {
        // TODO handle the error
        setError(result.error);
        setSubmitted(false);
        return;
      }
      setUrl(result.alternateLink);
      setSubmitted(false);
    });
  };

  if (!story?.story) return null;

  return (
    <PageBackground>
      <PageBoundary>
        <Header5 mt="4">Submit Your Work</Header5>
        <Text>Submit your work on "{story.story.title}" to...</Text>
        <RoundedBox>
          {url && (
            <Text mt="3">
              Great! You can see your work on Google Classroom by going{' '}
              <a href={url}>here</a>.
            </Text>
          )}
          {error && (
            <Text mt="3" color="red.60">
              Sorry! There was a problem with your submission. Are you a student
              (and not a teacher) in this class?
            </Text>
          )}
          {courses.length > 0 ? (
            <Box pb="4">
              <Header6 mt="3">Courses</Header6>
              <SelectInput
                disabled={submitted}
                onChange={(ev) => {
                  setActiveCourse(
                    courses.find((course) => course.id === ev.target.value) ||
                      {},
                  );
                }}
              >
                <option>Select a course</option>
                {courses.map((course) => (
                  <option value={course.id}>{course.name}</option>
                ))}
              </SelectInput>
            </Box>
          ) : (
            <Text my="4">Loading Courses</Text>
          )}
          {cws.length > 0 ? (
            <Box pb="4">
              <Header6 mt="3">Assignments</Header6>
              <SelectInput
                disabled={submitted}
                onChange={(ev) => {
                  setActiveCw(
                    cws.find((cw) => cw.id === ev.target.value) || {},
                  );
                }}
              >
                <option>Select an assignment</option>
                {cws.map((cw) => (
                  <option value={cw.id}>{cw.title}</option>
                ))}
              </SelectInput>
            </Box>
          ) : (
            activeCourse && <Text my="4">Loading Assignments</Text>
          )}
          {activeCourse && activeCw && (
            <Box mt="3" pb="4" display="flex" justifyContent="flex-end">
              <Button1
                palette="blue"
                variant="secondary"
                onClick={onSubmit}
                disabled={submitted}
              >
                Submit
              </Button1>
            </Box>
          )}
        </RoundedBox>
      </PageBoundary>
    </PageBackground>
  );
};

export default ProfileRedirectPage;
