import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  selectAllBadgeReaders,
  selectAllBadges,
  selectBadgeReaderById,
  selectBadgeStories,
  selectBadgeStoryById,
} from './slice';
import { useEffect } from 'react';
import { fetchBadgeReaders, postBadgeReader } from './thunks';
import { selectAllPanelResponses } from '@/app/features/panelResponses/slice';

export const useFetchBadgesWithBadgeReaders = () => {
  const dispatch = useDispatch();
  const badges = useSelector((state: RootState) => {
    return selectAllBadges(state.badges);
  });
  const badgeReaders = useSelector((state: RootState) => {
    return selectAllBadgeReaders(state.badges);
  });
  const badgeStories = useSelector((state: RootState) => {
    return selectBadgeStories(state.badges);
  });
  const loading = useSelector((state: RootState) => state.badges.loading);
  const error = useSelector((state: RootState) => state.badges.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    if (user && loading === 'idle') {
      dispatch(fetchBadgeReaders());
    }
  }, [user, loading]);
  return { badges, badgeReaders, badgeStories, loading, error };
};

export const useAwardReadingBadge = (storyId?: string, hasUser?: boolean) => {
  const dispatch = useDispatch();
  if (!storyId) return;
  const badgeStory = useSelector((state: RootState) => {
    return selectBadgeStoryById(state.badges, storyId);
  });
  const badgeId = badgeStory?.badges?.find(
    (badge) => badge.trigger === 'reading',
  )?.id;
  const exists = useSelector((state: RootState) => {
    return selectBadgeReaderById(state.badges, storyId);
  });
  useEffect(() => {
    if (exists) return;
    if (!badgeId) return;
    if (!hasUser) return;
    dispatch(postBadgeReader({ badgeId }));
  }, [badgeId, hasUser]);
};

export const useAwardInteractiveBadge = (
  storyId?: string,
  hasUser?: boolean,
) => {
  const dispatch = useDispatch();
  if (!storyId) return;
  const badgeStory = useSelector((state: RootState) => {
    return selectBadgeStoryById(state.badges, storyId);
  });
  const badgeId = badgeStory?.badges?.find(
    (badge) => badge.trigger === 'interactive',
  )?.id;
  const exists = useSelector((state: RootState) => {
    return selectBadgeReaderById(state.badges, storyId);
  });
  const responded = useSelector((state: RootState) => {
    const allResponses = selectAllPanelResponses(state.panelResponses);
    allResponses.filter((response) => {
      return response.panelResponse?.storyId === storyId;
    });
    return allResponses.length > 0; // if the reader submits at least one response
  });
  useEffect(() => {
    if (exists) return;
    if (!badgeId) return;
    if (!hasUser) return;
    if (!responded) return;
    dispatch(postBadgeReader({ badgeId }));
  }, [exists, badgeId, hasUser, responded]);
};
