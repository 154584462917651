import { useDispatch } from 'react-redux';
import { ReadingExperience } from '@astc/frontend-components';
import ReadingExperienceStep from '@/app/features/user/components/ReadingExperienceStep';
import { updateMode } from '@/app/features/user/slice';
import { navigate } from 'gatsby';
import { useHasUser } from '@/utils/hooks';
import { useEffect } from 'react';

interface ReadingExperiencePageContainerProps {}

export const repc: React.FC<ReadingExperiencePageContainerProps> = () => {
  const dispatch = useDispatch();
  const hasUser = useHasUser();

  useEffect(() => {
    if (!hasUser) {
      navigate('/onboard/signIn');
    }
  }, [hasUser]);

  const handleReadingExperienceSelect = (mode: ReadingExperience) => () => {
    dispatch(updateMode(mode));
  };

  const handleNextClick = () => {
    navigate('/onboard/welcome');
  };

  return (
    <ReadingExperienceStep
      title={'Reading Experience'}
      subtitle={'Subtitle'}
      footer={'Footer text'}
      onReadingExperienceSelect={handleReadingExperienceSelect}
      onNextClick={handleNextClick}
    />
  );
};

export default repc;
