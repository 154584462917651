import React from 'react';
import {
  isPanelAnimation,
  isPanelComparison,
  isPanelDrawing,
  isPanelHotspot,
  isPanelImage,
  isPanelImmersiveText,
  isPanelMatching,
  isPanelMultipleChoice,
  isPanelShortResponse,
  isPanelTimeline,
  PanelType,
} from '@/types';
import {
  AnimationPanelContainer,
  ComparisonPanelContainer,
  DrawingPanelContainer,
  HotspotPanelContainer,
  ImagePanelContainer,
  ImmersiveTextPanelContainer,
  MultipleChoicePanelContainer,
  ShortResponsePanelContainer,
  TimelinePanelContainer,
} from '@/components/panels';
import { MatchingPanelContainer } from '@/components/panels/MatchingPanelContainer';
// import { useSelector } from 'react-redux';
// import { RootState } from '@/app/store';
// import { selectPanelResponseById } from '@/app/features/panelResponses/slice';

export const PanelContainer: React.FC<{ panel: PanelType }> = ({ panel }) => {
  // const panelResponseData = useSelector((state: RootState) => {
  //   if (!panel._id) {
  //     return;
  //   }
  //   return selectPanelResponseById(state.panelResponses, panel._id)
  //     ?.panelResponse?.data;
  // });
  if (isPanelAnimation(panel))
    return <AnimationPanelContainer key={panel._key} panel={panel} />;

  if (isPanelComparison(panel))
    return <ComparisonPanelContainer key={panel._key} panel={panel} />;

  if (isPanelHotspot(panel))
    return <HotspotPanelContainer key={panel._key} panel={panel} />;

  if (isPanelImmersiveText(panel))
    return <ImmersiveTextPanelContainer key={panel._key} panel={panel} />;

  if (isPanelMatching(panel))
    return <MatchingPanelContainer key={panel._key} panel={panel} />;

  if (isPanelMultipleChoice(panel))
    return <MultipleChoicePanelContainer key={panel._key} panel={panel} />;

  if (isPanelTimeline(panel))
    return <TimelinePanelContainer key={panel._key} panel={panel} />;

  if (isPanelDrawing(panel))
    return <DrawingPanelContainer key={panel._key} panel={panel} />;

  if (isPanelShortResponse(panel))
    return <ShortResponsePanelContainer key={panel._key} panel={panel} />;

  if (isPanelImage(panel))
    return <ImagePanelContainer key={panel._key} panel={panel} />;

  return null;
};
