import { createAsyncThunk } from '@reduxjs/toolkit';
import { isPlainObject, PanelResponse } from '@/types';
import { RootState } from '@/app/store';
import fetch from 'isomorphic-fetch';
import { postAuthedData } from '@/app/readerDataClient';

export type FetchPanelResponseResponseData = {
  panel_id: string;
  story_id: string;
  data: string;
}[];

export const isFetchPanelResponseResponseData = (
  data: unknown,
): data is FetchPanelResponseResponseData => {
  if (!Array.isArray(data)) {
    return false;
  }
  return data.every(
    (item) =>
      isPlainObject(item) &&
      item.hasOwnProperty(`panel_id`) &&
      typeof item.panel_id === `string` &&
      item.hasOwnProperty(`story_id`) &&
      typeof item.story_id === `string` &&
      item.hasOwnProperty(`data`) &&
      (typeof item.data === `string` || typeof item.data === `object`),
  );
};

export type PostPanelResponseResponseData = {
  panel_id: string;
  story_id: string;
  data: string;
};

export const isPostPanelResponseResponseData = (
  data: unknown,
): data is PostPanelResponseResponseData => {
  return (
    isPlainObject(data) &&
    data.hasOwnProperty(`panel_id`) &&
    typeof data.panel_id === `string` &&
    data.hasOwnProperty(`story_id`) &&
    typeof data.story_id === `string` &&
    data.hasOwnProperty(`data`) &&
    (typeof data === `string` || typeof data === `object`)
  );
};

export const fetchPanelResponses = createAsyncThunk<
  PanelResponse[],
  void,
  { state: RootState; rejectValue: string }
>('panelResponse/fetchPanelResponses', async (_, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) thunkAPI.rejectWithValue('No token found');
  const response = await fetch(
    `${process.env.GATSBY_READER_DATA_API_URL}/responses`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to fetch panel responses: ${response.statusText}`,
    );
  }
  const responseData = await response.json();
  if (!isFetchPanelResponseResponseData(responseData)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return responseData.map((item) => ({
    panelId: item.panel_id,
    storyId: item.story_id,
    data: item.data,
  }));
});

export const postPanelResponse = createAsyncThunk<
  PanelResponse,
  PanelResponse & { data: string },
  { state: RootState; rejectValue: string }
>(
  'panelResponse/postPanelResponse',
  async ({ panelId, storyId, data }, thunkAPI) => {
    const token = thunkAPI.getState().user.user.jwt;
    if (!token) return thunkAPI.rejectWithValue('No token found');

    const response = await postAuthedData(
      '/responses',
      { panel_id: panelId, story_id: storyId, data },
      token,
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(
        `Failed to post badge reader: ${response.statusText}`,
      );
    }
    const _data = await response.json();
    if (!isPostPanelResponseResponseData(_data)) {
      return thunkAPI.rejectWithValue('Invalid response data');
    }
    return {
      panelId: _data.panel_id,
      storyId: _data.story_id,
      data: _data.data,
    };
  },
);
