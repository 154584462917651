import React from 'react';
import {
  Button2,
  Header1,
  Text,
  UserRole,
  WelcomeBoundary,
  WelcomeButton,
  WelcomeControls,
  WelcomeHeader,
  WelcomeRoles,
} from '@astc/frontend-components';

export interface WelcomeProps {
  title?: string;
  body: string;

  onRoleSelect(role: UserRole): () => void;

  onNextClick(): void;
}

export const WelcomeStep: React.FC<WelcomeProps> = ({
  title = 'Welcome',
  body,
  onRoleSelect,
  onNextClick,
}) => {
  return (
    <WelcomeBoundary>
      <WelcomeHeader>
        <Header1>{title}</Header1>
        <Text>{body}</Text>
      </WelcomeHeader>
      <WelcomeControls>
        <Text>Are you a:</Text>
        <WelcomeRoles>
          <WelcomeButton onClick={onRoleSelect(UserRole.STUDENT)} palette="red">
            <Text>Student</Text>
          </WelcomeButton>
          <WelcomeButton
            onClick={onRoleSelect(UserRole.EDUCATOR)}
            palette="red"
          >
            <Text>Educator</Text>
          </WelcomeButton>
          <WelcomeButton onClick={onRoleSelect(UserRole.NEITHER)} palette="red">
            <Text>Neither</Text>
          </WelcomeButton>
        </WelcomeRoles>
        <Button2 onClick={onNextClick} palette="red">
          Next
        </Button2>
      </WelcomeControls>
    </WelcomeBoundary>
  );
};
export default WelcomeStep;
