import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RawReadingListStory,
  RawRecentlyReadStory,
  ReadingListsState,
  ReadingListStory,
  ReadingListStoryResult,
  RecentlyReadStoryResult,
} from '@/types';
import {
  deleteReadingList,
  deleteReadingListStory,
  fetchReadingListById,
  fetchReadingLists,
  postReadingList,
  postReadingListStory,
  putReadingList,
} from '@/app/features/readingLists/thunks';
import {
  convertRawReadingListStoryToReadingListStoryResult,
  convertRawRecentlyReadStoryToRecentlyReadStoryResult,
  updateSuccessfulReadingLists,
} from '@/app/features/readingLists/utils';
import {
  initialState,
  readingListsAdapter,
  readingListStoriesAdapter,
  recentlyReadEntriesAdapter,
  recentlyReadStoriesAdapter,
} from '@/app/features/readingLists/initialState';
import { logOut } from '@/app/features/user/slice';

const slice = createSlice({
  name: 'readingLists',
  initialState,
  reducers: {
    resetReadingLists: (state) => {
      readingListStoriesAdapter.removeAll(state.readingLists);
      state.loading = 'idle';
      state.error = null;
    },
    populateReadingListStories: (
      state,
      action: PayloadAction<RawReadingListStory[]>,
    ) => {
      // TODO unify the schema and get rid of the raw badge distinction
      const readingListStoriesResult =
        action.payload.map<ReadingListStoryResult>(
          convertRawReadingListStoryToReadingListStoryResult,
        );
      readingListStoriesAdapter.upsertMany(
        state.readingListStories,
        readingListStoriesResult,
      );
    },
    populateRecentlyReadStories: (
      state,
      action: PayloadAction<RawRecentlyReadStory[]>,
    ) => {
      const recentlyReadStoriesResult =
        action.payload.map<RecentlyReadStoryResult>(
          convertRawRecentlyReadStoryToRecentlyReadStoryResult,
        );
      recentlyReadStoriesAdapter.upsertMany(
        state.recentlyReadStories,
        recentlyReadStoriesResult,
      );
    },
    addRecentlyRead: (state, action: PayloadAction<{ id: string }>) => {
      recentlyReadEntriesAdapter.upsertOne(state.recentlyRead, {
        id: action.payload.id,
        read: new Date().getUTCDate(),
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReadingLists.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchReadingLists.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        readingListsAdapter.upsertMany(
          state.readingLists,
          Object.values(action.payload).map(
            updateSuccessfulReadingLists(state.readingListStories.entities),
          ),
        );
      })
      .addCase(fetchReadingLists.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload || null;
      })
      .addCase(fetchReadingListById.pending, (state, action) => {
        readingListsAdapter.upsertOne(state.readingLists, {
          id: action.meta.arg,
          loading: 'pending',
          error: null,
        });
      }) // TODO figure out how to handle this without a reading list
      .addCase(fetchReadingListById.fulfilled, (state, action) => {
        const stories = [
          ...action.payload.storyIds
            .map((id) => {
              return (
                state.readingListStories.entities[id]?.story ||
                state.readingListStories.entities['drafts.' + id]?.story
              );
              // TODO: remove the drafts prefix when we have a real story id
            })
            .filter((s): s is ReadingListStory => s !== undefined),
        ];

        const readingList = {
          ...action.payload,
          stories,
        };

        readingListsAdapter.upsertOne(state.readingLists, {
          id: action.payload.id,
          readingList,
          loading: 'succeeded',
          error: null,
        });
      })
      .addCase(fetchReadingListById.rejected, (state, action) => {
        readingListsAdapter.upsertOne(state.readingLists, {
          id: action.meta.arg,
          loading: 'failed',
          error: action.payload || null,
        });
      })
      .addCase(postReadingList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postReadingList.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(postReadingList.fulfilled, (state, action) => {
        const stories = [
          ...action.payload.storyIds
            .map((id) => {
              return (
                state.readingListStories.entities[id]?.story ||
                state.readingListStories.entities['drafts.' + id]?.story
              );
              // TODO: remove the drafts prefix when we have a real story id
            })
            .filter((s): s is ReadingListStory => s !== undefined),
        ];

        const readingList = {
          ...action.payload,
          stories,
        };

        readingListsAdapter.upsertOne(state.readingLists, {
          id: readingList.id,
          readingList,
          loading: 'succeeded',
          error: null,
        });
        state.loading = 'succeeded';
      })
      .addCase(putReadingList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(putReadingList.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(putReadingList.fulfilled, (state, action) => {
        const stories = [
          ...action.payload.storyIds
            .map((id) => {
              return (
                state.readingListStories.entities[id]?.story ||
                state.readingListStories.entities['drafts.' + id]?.story
              );
              // TODO: remove the drafts prefix when we have a real story id
            })
            .filter((s): s is ReadingListStory => s !== undefined),
        ];

        const readingList = {
          ...action.payload,
          stories,
        };

        readingListsAdapter.upsertOne(state.readingLists, {
          id: readingList.id,
          readingList,
          loading: 'succeeded',
          error: null,
        });
        state.loading = 'succeeded';
      })
      .addCase(deleteReadingList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteReadingList.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(deleteReadingList.fulfilled, (state, action) => {
        const uuid = action.payload;
        readingListsAdapter.removeOne(state.readingLists, uuid);
        state.loading = 'succeeded';
      })
      .addCase(postReadingListStory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postReadingListStory.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(postReadingListStory.fulfilled, (state, action) => {
        const stories = [
          ...action.payload.storyIds
            .map((id) => {
              return (
                state.readingListStories.entities[id]?.story ||
                state.readingListStories.entities['drafts.' + id]?.story
              );
              // TODO: remove the drafts prefix when we have a real story id
            })
            .filter((s): s is ReadingListStory => s !== undefined),
        ];

        const readingList = {
          ...action.payload,
          stories,
        };

        readingListsAdapter.upsertOne(state.readingLists, {
          id: readingList.id,
          readingList,
          loading: 'succeeded',
          error: null,
        });
        state.loading = 'succeeded';
      })
      .addCase(deleteReadingListStory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteReadingListStory.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(deleteReadingListStory.fulfilled, (state, action) => {
        const stories = [
          ...action.payload.storyIds
            .map((id) => {
              return (
                state.readingListStories.entities[id]?.story ||
                state.readingListStories.entities['drafts.' + id]?.story
              );
              // TODO: remove the drafts prefix when we have a real story id
            })
            .filter((s): s is ReadingListStory => s !== undefined),
        ];

        const readingList = {
          ...action.payload,
          stories,
        };

        readingListsAdapter.upsertOne(state.readingLists, {
          id: readingList.id,
          readingList,
          loading: 'succeeded',
          error: null,
        });
        state.loading = 'succeeded';
      })
      .addCase(logOut, (state) => {
        // this is wild and testament to the goodness of RTK
        // we can use an action creator from another slice to affect state
        // in this slice. Good video on this: https://www.youtube.com/watch?v=oEEXhHy_i4I
        state.readingLists = initialState.readingLists;
        state.recentlyRead = initialState.recentlyRead;
      });
  },
});

export const {
  selectIds: selectReadingListIds,
  selectById: selectReadingListById,
  selectEntities: selectReadingLists,
  selectAll: selectAllReadingLists,
  selectTotal: selectReadingListTotal,
} = readingListsAdapter.getSelectors(
  (state: ReadingListsState) => state.readingLists,
);

export const {
  selectIds: selectReadingListStoryIds,
  selectById: selectReadingListStoryById,
  selectEntities: selectReadingListStories,
  selectAll: selectAllReadingListStories,
  selectTotal: selectReadingListStoryTotal,
} = readingListStoriesAdapter.getSelectors(
  (state: ReadingListsState) => state.readingListStories,
);

export const {
  selectIds: selectRecentlyReadIds,
  selectById: selectRecentlyReadById,
  selectEntities: selectRecentlyRead,
  selectAll: selectAllRecentlyRead,
  selectTotal: selectRecentlyReadTotal,
} = recentlyReadEntriesAdapter.getSelectors(
  (state: ReadingListsState) => state.recentlyRead,
);

export const {
  resetReadingLists,
  populateReadingListStories,
  populateRecentlyReadStories,
  addRecentlyRead,
} = slice.actions;

export default slice.reducer;
