import { createAsyncThunk } from '@reduxjs/toolkit';
import { isPlainObject, ReadingList } from '@/types';
import {
  deleteAuthedData,
  fetchAuthedData,
  postAuthedData,
  putAuthedData,
} from '@/app/readerDataClient';
import { RootState } from '@/app/store';

type FetchReadingListResponseData = {
  name: string;
  description: string;
  owner: boolean;
  uuid: string;
  reading_list_stories: { story_id: string }[];
}[];

type FetchReadingListByIdResponseData = FetchReadingListResponseData[0];

export const isFetchReadingListResponseData = (
  responseData: unknown,
): responseData is FetchReadingListResponseData => {
  if (!Array.isArray(responseData)) {
    return false;
  }
  return responseData.every(
    (item) =>
      isPlainObject(item) &&
      item.hasOwnProperty(`name`) &&
      item.hasOwnProperty(`description`) &&
      item.hasOwnProperty(`owner`) &&
      item.hasOwnProperty(`uuid`) &&
      typeof item.name === `string` &&
      typeof item.description === `string` &&
      typeof item.owner === `boolean` &&
      typeof item.uuid === `string`,
  );
};

export const isFetchReadingListByIdResponseData = (
  data: unknown,
): data is FetchReadingListByIdResponseData => {
  return (
    isPlainObject(data) &&
    data.hasOwnProperty(`name`) &&
    data.hasOwnProperty(`description`) &&
    data.hasOwnProperty(`owner`) &&
    data.hasOwnProperty(`uuid`) &&
    typeof data.name === `string` &&
    typeof data.description === `string` &&
    typeof data.owner === `boolean` &&
    typeof data.uuid === `string`
  );
};

type PostReadingListResponseData = {
  name: string;
  description: string;
  uuid: string;
  owner: boolean;
  reading_list_stories: { story_id: string }[];
};

type PutReadingListResponseData = PostReadingListResponseData;

export const isPostReadingListResponseData = (
  data: unknown,
): data is PostReadingListResponseData => {
  return (
    isPlainObject(data) &&
    data.hasOwnProperty(`name`) &&
    data.hasOwnProperty(`description`) &&
    typeof data[`name`] === `string` &&
    typeof data[`description`] === `string`
  );
};

export const isPutReadingListResponseData = isPostReadingListResponseData;

type PostReadingListStoryResponseData = {
  story_id: string;
};

export const isPostReadingListStoryResponseData = (
  data: unknown,
): data is PostReadingListStoryResponseData => {
  return (
    isPlainObject(data) &&
    data.hasOwnProperty(`story_id`) &&
    typeof data[`story_id`] === `string`
  );
};

export const isDeleteReadingListStoryResponseData =
  isPostReadingListResponseData;

type DeleteReadingListStoryResponseData = PostReadingListStoryResponseData;

export const postReadingList = createAsyncThunk<
  ReadingList,
  Omit<PostReadingListResponseData, 'uuid' | 'owner' | 'reading_list_stories'>,
  { state: RootState; rejectValue: string }
>('readingLists/postReadingList', async ({ name, description }, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await postAuthedData(
    '/reading-lists',
    { name, description },
    token,
  );

  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to post reading list: ${response.statusText}`,
    );
  }
  const data = await response.json();
  if (!isPostReadingListResponseData(data)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return {
    name: data.name,
    description: data.description,
    id: data.uuid,
    owner: data.owner,
    stories: [],
    storyIds: [],
  };
});

export const putReadingList = createAsyncThunk<
  ReadingList,
  Omit<PutReadingListResponseData, 'owner' | 'reading_list_stories'>,
  { state: RootState; rejectValue: string }
>(
  'readingLists/putReadingList',
  async ({ uuid, name, description }, thunkAPI) => {
    const token = thunkAPI.getState().user.user.jwt;
    if (!token) return thunkAPI.rejectWithValue('No token found');

    const response = await putAuthedData(
      `/reading-lists/${uuid}`,
      { name, description },
      token,
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(
        `Failed to put reading list ${uuid}: ${response.statusText}`,
      );
    }
    const data = await response.json();
    if (!isPutReadingListResponseData(data)) {
      return thunkAPI.rejectWithValue('Invalid response data');
    }
    return {
      name: data.name,
      description: data.description,
      id: data.uuid,
      owner: data.owner,
      stories: [],
      storyIds: data.reading_list_stories.map((item) => item.story_id),
    };
  },
);

export const deleteReadingList = createAsyncThunk<
  string,
  Omit<
    PutReadingListResponseData,
    'owner' | 'name' | 'description' | 'reading_list_stories'
  >,
  { state: RootState; rejectValue: string }
>('readingLists/deleteReadingList', async ({ uuid }, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await deleteAuthedData(`/reading-lists/${uuid}`, token);

  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to delete reading list ${uuid}: ${response.statusText}`,
    );
  }
  return uuid;
});

export const fetchReadingLists = createAsyncThunk<
  ReadingList[],
  void,
  { state: RootState; rejectValue: string }
>('readingLists/fetchReadingLists', async (_, thunkAPI) => {
  const { user } = thunkAPI.getState().user;
  const token = user?.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await fetchAuthedData('/reading-lists', token);
  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to fetch reading lists: ${response.statusText}`,
    );
  }
  const responseData = await response.json();
  if (!isFetchReadingListResponseData(responseData)) {
    return thunkAPI.rejectWithValue(
      `Invalid response data: ${JSON.stringify(responseData, null, 2)}`,
    );
  }
  return responseData.map((item) => ({
    name: item.name,
    description: item.description,
    id: item.uuid,
    owner: item.owner,
    stories: [],
    storyIds: item.reading_list_stories.map((item) => item.story_id),
  }));
});

export const fetchReadingListById = createAsyncThunk<
  ReadingList,
  string,
  { state: RootState; rejectValue: string }
>('readingLists/fetchReadingListById', async (id, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await fetchAuthedData(`/reading-lists/${id}`, token);

  if (!response.ok) {
    return thunkAPI.rejectWithValue(response.statusText);
  }

  const data = await response.json();
  if (!isFetchReadingListByIdResponseData(data)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return {
    name: data.name,
    description: data.description,
    id: data.uuid,
    owner: data.owner,
    stories: [],
    storyIds: data.reading_list_stories.map((item) => item.story_id),
  };
});

export const postReadingListStory = createAsyncThunk<
  ReadingList,
  PostReadingListStoryResponseData & { id: string },
  { state: RootState; rejectValue: string }
>('readingLists/postReadingListStory', async ({ id, story_id }, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await postAuthedData(
    `/reading-list-stories/`,
    { list_id: id, story_id },
    token,
  );

  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to post reading list: ${response.statusText}`,
    );
  }
  const data = await response.json();
  if (!isPostReadingListResponseData(data)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return {
    name: data.name,
    description: data.description,
    id: data.uuid,
    owner: data.owner,
    stories: [],
    storyIds: data.reading_list_stories.map((item) => item.story_id),
  };
});

export const deleteReadingListStory = createAsyncThunk<
  ReadingList,
  DeleteReadingListStoryResponseData & { id: string },
  { state: RootState; rejectValue: string }
>('readingLists/deleteReadingListStory', async ({ id, story_id }, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await deleteAuthedData(
    `/reading-list-stories/${id}/${story_id}`,
    token,
  );

  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to post reading list: ${response.statusText}`,
    );
  }
  const data = await response.json();
  if (!isDeleteReadingListStoryResponseData(data)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return {
    name: data.name,
    description: data.description,
    id: data.uuid,
    owner: data.owner,
    stories: [],
    storyIds: data.reading_list_stories.map((item) => item.story_id),
  };
});
