import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch } from 'react-redux';
import { populateBadges } from '@/app/features/badges/slice';
import { useEffect } from 'react';

export const BadgesDataProvider: React.FC = () => {
  const dispatch = useDispatch();
  const data = useStaticQuery(graphql`
    query BadgesQuery {
      allSanityStory {
        nodes {
          _id
          title
          thumbnail {
            asset {
              gatsbyImageData(
                width: 180
                height: 180
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          badges {
            _id
            name
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
            trigger
          }
        }
      }
    }
  `);
  useEffect(() => {
    dispatch(populateBadges(data.allSanityStory.nodes));
  }, [data]);
  return null;
};
