import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button1,
  dim,
  Header7,
  SignInStepBoundary,
  SignInStepForm,
  Text,
  TextInput,
} from '@astc/frontend-components';
import { GenericPageHeader } from '@/components/page/generic';
import styled from '@emotion/styled';
// @ts-ignore
import { Icon } from '@iconify/react';
import envelopeSimple from '@iconify/icons-ph/envelope-simple';
import eye from '@iconify/icons-ph/eye';
import eyeSlash from '@iconify/icons-ph/eye-slash';
import passwordIcon from '@iconify/icons-ph/password';
import { navigate } from 'gatsby';

export interface SignUpStepProps {
  title: string;
  subtitle: string;
  body: string;
  onLogIn(username: string, password: string): void;
}

const SignInButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${dim(16)};
`;

export const SignUpStep: React.FC<SignUpStepProps> = ({
  title,
  subtitle,
  body,
  onLogIn,
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const disabled = useMemo(() => {
    return !(
      email &&
      password &&
      passwordConfirm &&
      password === passwordConfirm
    );
  }, [password, passwordConfirm, email]);

  const handleUsernameChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setEmail(ev.currentTarget.value);
    },
    [],
  );

  const handlePasswordChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setPassword(ev.currentTarget.value);
    },
    [],
  );

  const handlePasswordConfirmChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setPasswordConfirm(ev.currentTarget.value);
    },
    [],
  );

  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      onLogIn(email, password);
    },
    [disabled, email, password],
  );

  return (
    <SignInStepBoundary>
      <GenericPageHeader>{title}</GenericPageHeader>
      <Header7>{subtitle}</Header7>
      <Text>{body}</Text>
      <SignInStepForm onSubmit={handleSubmit}>
        <TextInput
          onChange={handleUsernameChange}
          palette="blue"
          variant="primary"
          aria-label="username"
          placeholder="Email Address"
          iconLeft={<Icon icon={envelopeSimple} />}
        />
        <Box style={{ marginTop: dim(-16) }}>
          <TextInput
            onChange={handlePasswordChange}
            palette="blue"
            variant="secondary"
            aria-label="password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            iconLeft={<Icon icon={passwordIcon} />}
            iconRight={
              showPassword ? <Icon icon={eye} /> : <Icon icon={eyeSlash} />
            }
            onClickIconRight={toggleShowPassword}
          />
        </Box>
        <Box style={{ marginTop: dim(-8) }}>
          <TextInput
            onChange={handlePasswordConfirmChange}
            palette="blue"
            variant="primary"
            aria-label="confirm password"
            placeholder="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            iconLeft={<Icon icon={passwordIcon} />}
            iconRight={
              showPassword ? <Icon icon={eye} /> : <Icon icon={eyeSlash} />
            }
          />
        </Box>
        <SignInButtons>
          <Button1 variant="primary" palette="blue" disabled={disabled}>
            Sign Up
          </Button1>
          <Button1
            variant="primary"
            palette="blue"
            type="submit"
            onClick={() => {
              navigate('/onboard/signIn');
            }}
          >
            Log In
          </Button1>
        </SignInButtons>
      </SignInStepForm>
    </SignInStepBoundary>
  );
};
