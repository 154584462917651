import React, { useCallback, useMemo, useState } from 'react';

import {
  Text,
  SignInStepForm,
  Button2,
  TextInput,
  SignInStepBoundary,
  Logo,
  dim,
} from '@astc/frontend-components';
import { GenericPageHeader } from '../../../../../components/page/generic';

export interface ForgetPasswordStepProps {
  title: string;
  subtitle: string;
  body: string;

  onSubmit(username: string): void;
}

export const ForgetPasswordStep: React.FC<ForgetPasswordStepProps> = ({
  title,
  subtitle,
  body,
  onSubmit,
}) => {
  const [username, setUsername] = useState<string>('');
  const disabled = useMemo(() => {
    return !username;
  }, [username]);

  const handleUsernameChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setUsername(ev.currentTarget.value);
    },
    [],
  );

  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (!disabled)
        alert(
          'Please sign in through Google Classroom with a pre-approved email address during the Beta period',
        );
    },
    [disabled, username],
  );

  return (
    <SignInStepBoundary>
      <GenericPageHeader>
        {title}
        <Logo width={dim(68)} />
      </GenericPageHeader>
      <Text>{subtitle}</Text>
      <Text>{body}</Text>
      <SignInStepForm onSubmit={handleSubmit}>
        <TextInput
          onChange={handleUsernameChange}
          palette="blue"
          variant="primary"
          aria-label="username"
          placeholder="username"
        />
        <Button2
          palette="blue"
          type="submit"
          disabled={disabled}
          onClick={() => onSubmit(username)}
        >
          Send Password
        </Button2>
      </SignInStepForm>
    </SignInStepBoundary>
  );
};
