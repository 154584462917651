import { dim, IndexHeader, IndexHeaderLink } from '@astc/frontend-components';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

export const CollectionHeader = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  return (
    <IndexHeader mb={dim(48)} mt={[dim(22), 0]} px={[dim(16), dim(56)]}>
      <IndexHeaderLink
        active={
          locationPath === '/collections' || locationPath === '/collections/'
        }
      >
        <Link to={'/collections'}>Countries</Link>
      </IndexHeaderLink>
      <IndexHeaderLink
        active={
          locationPath === '/collections/categories' ||
          locationPath === '/collections/categories/'
        }
      >
        <Link to={'/collections/categories'}>Time Periods</Link>
      </IndexHeaderLink>
    </IndexHeader>
  );
};
