import React, { useCallback, useState } from 'react';
import { MenuItem, MenuOverlay, PageHeader } from '@astc/frontend-components';
import { Link, navigate } from 'gatsby';
import { useHasUser } from '@/utils/hooks';
import { StaticImage } from 'gatsby-plugin-image';

interface PageHeaderContainerProps {}

export const PageHeaderContainer: React.FC<PageHeaderContainerProps> = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const handleMenu = useCallback(() => {
    setMenu((prev) => !prev);
  }, [setMenu]);
  const hasUser = useHasUser();

  const handleLogoClick = () => {
    navigate('/');
  };
  const handleProfileViewClick = () => {
    navigate('/profile/view');
    setMenu(false);
  };
  const handleSignupViewClick = () => {
    navigate('/onboard/signIn');
    setMenu(false);
  };
  return (
    <>
      {menu && (
        <MenuOverlay
          hasUser={!!hasUser}
          onClose={handleMenu}
          onProfileClick={handleProfileViewClick}
          onSignupClick={handleSignupViewClick}
        >
          <MenuItem>
            <Link to="/" onClick={handleMenu}>
              Home
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={'/collections'} onClick={handleMenu}>
              Comics
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={'/resources'} onClick={handleMenu}>
              Educator Resources
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={'/behindTheScenes'} onClick={handleMenu}>
              Behind the Scenes
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={'/about'} onClick={handleMenu}>
              About
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={'/help'} onClick={handleMenu}>
              Help
            </Link>
          </MenuItem>
        </MenuOverlay>
      )}
      <PageHeader
        hasUser={!!hasUser}
        mobileLogo={
          <StaticImage
            src="../../images/logos/mobileLogo.png"
            alt="logo"
            placeholder="blurred"
            layout="constrained"
            width={180}
            height={64}
          />
        }
        desktopLogo={
          <StaticImage
            src="../../images/logos/desktopLogo.png"
            alt="logo"
            placeholder="blurred"
            layout="constrained"
            width={180}
            height={64}
          />
        }
        onLogoClick={handleLogoClick}
        onMenuClick={handleMenu}
        onProfileClick={handleProfileViewClick}
        onSignupClick={handleSignupViewClick}
      >
        <Link to={'/collections'}>Comics</Link>
        <Link to={'/resources'}>Educator Resources</Link>
        <Link to={'/behindTheScenes'}>Behind the Scenes</Link>
        <Link to={'/about'}>About</Link>
        <Link to={'/help'}>Help</Link>
      </PageHeader>
    </>
  );
};
