import { SanityImage } from '../../../../graphql-types';
import { BaseTheme, Box, dim } from '@astc/frontend-components';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { PageBackground } from '@/components/PageBackground';

interface StoryHeroBannerProps {
  heroBanner?: {
    desktop: SanityImage;
    mobile: SanityImage;
  };
  children: React.ReactNode;
}

const StyledStoryHeroBanner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => (theme as BaseTheme).colors.grays['80']};
  ${down('mobile')} {
    padding: ${({ theme }) => (theme as BaseTheme).space[3]};
    background: none;
    height: auto;
  }
`;

const StoryHeroBannerContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${dim(42)} 0;
  background-color: rgba(0, 0, 0, 0.73);
  ${down('mobile')} {
    background: none;
    padding: 0;
  }
`;

export const StoryHeroBanner: React.FC<StoryHeroBannerProps> = ({
  heroBanner,
  children,
}) => {
  const { desktop } = heroBanner || {};
  return (
    <StyledStoryHeroBanner>
      <PageBackground minHeight="auto" desktop={desktop}>
        <StoryHeroBannerContent>{children}</StoryHeroBannerContent>
      </PageBackground>
    </StyledStoryHeroBanner>
  );
};
