import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Box } from '@astc/frontend-components';
import { SignUpStep } from '@/app/features/user/components/SignUpStep';
import { useHasUser } from '@/utils/hooks';
import { fetchEmailSignUpUser } from '@/app/features/user/thunks';
import { useDispatch } from 'react-redux';

interface SignInPageContainerProps {}

const sipc: React.FC<SignInPageContainerProps> = () => {
  const hasUser = useHasUser();
  const dispatch = useDispatch();

  // TODO: double check that useEffect is what we want to do
  useEffect(() => {
    if (hasUser !== true) {
      return;
    }
    navigate('/profile/view'); // TODO: should this be handled elsewhere?
    // TODO: this might benefit from an onboarding state machine
  }, [hasUser]);

  // TODO: handle this case
  const handleLogIn = async (email: string, password: string) => {
    dispatch(fetchEmailSignUpUser({ email, password }));
  };

  return (
    <Box>
      <SignUpStep
        title="Sign Up"
        subtitle="Sign up to enjoy more features and collect unlockable content."
        body="Educators, be sure to use your professional email"
        onLogIn={handleLogIn}
      />
    </Box>
  );
};

export default sipc;
