import { ImagePanel } from '@astc/frontend-components';
import React from 'react';
import { SanityPanel } from '../../../../graphql-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
  getSourcedImageData,
  SourcedImage,
} from '@/components/media/SourcedImage';
import { WithAliasedImages } from '@/types';

type SanityPanelAliasedImages = WithAliasedImages<SanityPanel>;

export const ImagePanelContainer: React.FC<{
  panel: SanityPanelAliasedImages;
}> = ({ panel }) => {
  const { mobile: isMobile } = useBreakpoint();
  return isMobile ? (
    <ImagePanel
      key={panel._key}
      image={<SourcedImage source={panel.mobile} alt={panel.caption} />}
      width={getSourcedImageData(panel.mobile).width}
      height={getSourcedImageData(panel.mobile).height}
    >
      {panel.caption}
    </ImagePanel>
  ) : (
    <ImagePanel
      key={panel._key}
      image={<SourcedImage source={panel.desktop} alt={panel.caption} />}
      width={getSourcedImageData(panel.desktop).width}
      height={getSourcedImageData(panel.desktop).height}
    >
      {panel.caption}
    </ImagePanel>
  );
};
