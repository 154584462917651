import { ReadingListPage } from '@/app/features/readingLists/components/ReadingListPage';
import {
  useDeleteReadingList,
  useFetchReadingListById,
  usePutReadingList,
} from '@/app/features/readingLists/hooks';
import { useHasUser } from '@/utils/hooks';
import React, { useEffect, useMemo } from 'react';
import {
  useFetchFeedbacks,
  usePostFeedback,
} from '@/app/features/feedbacks/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectFeedbackById } from '@/app/features/feedbacks/slice';
import { ReadingListsOverlay, ShareOverlay } from '@astc/frontend-components';
import { redirectOnShareStory } from '@/utils/share';
import { useFetchBadgesWithBadgeReaders } from '@/app/features/badges/hooks';
import { navigate } from 'gatsby';
import { PageLoading } from '@/components/PageLoading';

interface ReadingListPageContainerProps {
  params: {
    id: string;
  };
}

const rlpc: React.FC<ReadingListPageContainerProps> = ({ params: { id } }) => {
  const [editReadingListOverlay, setEditReadingListOverlay] =
    React.useState(false);
  const [deleteReadingListOverlay, setDeleteReadingListOverlay] =
    React.useState(false);
  const [shareOverlay, setShareOverlay] = React.useState(false);
  const { readingListResult, loading: readingListLoading } =
    useFetchReadingListById(id);
  const hasUser = useHasUser();
  useFetchFeedbacks();
  const { handleFeedback } = usePostFeedback(id || '', 'reading-list');
  const hasFeedback = useSelector(
    (state: RootState) =>
      !!(id && selectFeedbackById(state.feedbacks, `reading-list:${id}`)),
  );
  const { handlePutReadingList } = usePutReadingList(id);
  const { handleDeleteReadingList } = useDeleteReadingList(id);

  const { badgeReaders, badgeStories } = useFetchBadgesWithBadgeReaders();

  const readStories = useMemo(() => {
    if (
      !readingListResult?.readingList?.stories ||
      !readingListResult?.readingList?.stories?.length
    )
      return [];

    if (!badgeReaders.length) return [];

    return badgeReaders
      .map((badgeReader) => {
        return readingListResult?.readingList?.stories?.find((story) => {
          return badgeStories[story.id]?.badges?.find(
            (badge) => badgeReader.badgeReader?.badgeId === badge.id,
          );
        })?.id;
      })
      .filter((br) => !!br);
  }, [badgeReaders, readingListResult?.readingList?.stories]);

  useEffect(() => {
    if (!readingListResult?.error && readingListLoading !== 'failed') {
      return;
    }
    navigate('/readingLists');
  }, [readingListResult?.error, readingListLoading]);

  if (!readingListResult || readingListLoading === 'pending') {
    return (
      <PageLoading
        loading={'pending'}
        error={readingListResult?.error || null}
      />
    );
  }

  const { readingList, loading, error } = readingListResult;

  return (
    <>
      <ReadingListPage
        readingList={readingList}
        badgeReaders={readStories}
        loading={loading}
        error={error}
        isReported={hasFeedback}
        readCount={readStories.length}
        onShareClick={() => setShareOverlay(true)}
        onReportClick={() => handleFeedback('reported by user')}
        onEditClick={() => setEditReadingListOverlay(true)}
        onDeleteClick={() => setDeleteReadingListOverlay(true)}
      />
      {hasUser && editReadingListOverlay && (
        <ReadingListsOverlay
          title="Edit Reading List"
          listName={readingList?.name}
          listDescription={readingList?.description}
          isNew={false}
          onReadingListChange={(name, description) => {
            handlePutReadingList(name, description);
            setEditReadingListOverlay(false);
          }}
          onRequestClose={() => {
            setEditReadingListOverlay(false);
          }}
        />
      )}
      {hasUser && deleteReadingListOverlay && (
        <ReadingListsOverlay
          title="Delete Reading List"
          listName={readingList?.name}
          listDescription={readingList?.description}
          isNew={false}
          isDeleting={true}
          onReadingListChange={() => {
            handleDeleteReadingList();
            setDeleteReadingListOverlay(false);
          }}
          onRequestClose={() => {
            setDeleteReadingListOverlay(false);
          }}
        />
      )}
      {shareOverlay && (
        <ShareOverlay
          title="Share"
          link={window.location.href}
          onShare={redirectOnShareStory(
            readingList?.name || '',
            readingList?.description || '',
          )}
          onRequestClose={() => {
            setShareOverlay(false);
          }}
        />
      )}
    </>
  );
};

export default rlpc;
