import { ReactNode } from 'react';
import { EntityState } from '@reduxjs/toolkit';
import {
  CountryData,
  GenreData,
  ImageData,
  ReadingExperience,
  StoryCreatorData,
  UserRole,
} from '@astc/frontend-components';
import {
  Maybe,
  SanityBlock,
  SanityCategory,
  SanityCountry,
  SanityImage,
  SanityMatchingAnswer,
  SanityMedium,
  SanityPagesHomeFeatured,
  SanityPagesHomeIntro,
  SanityPagesHomeSignup,
  SanityPanel,
  SanityPanelAnimation,
  SanityPanelComparison,
  SanityPanelDrawing,
  SanityPanelHotspot,
  SanityPanelImmersiveText,
  SanityPanelMatching,
  SanityPanelMultipleChoice,
  SanityPanelOrPanelAnimationOrPanelComparisonOrPanelDrawingOrPanelHotspotOrPanelImmersiveTextOrPanelMatchingOrPanelMultipleChoiceOrPanelShortResponseOrPanelTimeline,
  SanityPanelShortResponse,
  SanityPanelTimeline,
  SanityStoryCharacter,
  SanityStoryCreator,
  SanityStoryLocation,
  SanityStoryRecommended,
  SanityVisualLayeredThumbnail,
} from '../../graphql-types';
import { RouteComponentProps } from '@reach/router';

export interface BaseComponentProps {
  children?: ReactNode;
}

export interface LoadState {
  loading: LoadingState;
  error: string | null;
}

const loadingStates = ['idle', 'pending', 'succeeded', 'failed'] as const;
export type LoadingState = typeof loadingStates[number];

export type RawReadingListStory = {
  _id: string;
  title: string;
  thumbnail: unknown;
  imageHorizontal: unknown;
  imageVertical: unknown;
  country: SanityCountry;
  categories: SanityCategory[];
};

export interface ReadingListStory {
  id: string;
  title: string;
  image: unknown;
  imageHorizontal: unknown;
  imageVertical: unknown;
  tags: string[];
  stories?: string[];
}

export type ReadingListStoryResult = {
  id: string;
  rawStory?: RawReadingListStory;
  story?: ReadingListStory;
  loading: LoadingState;
  error: string | null;
};

export type RawRecentlyReadStory = {
  _id: string;
  title: string;
  thumbnail: unknown;
};

export interface RecentlyReadStory {
  id: string;
  title: string;
  image: unknown;
}

export type RecentlyReadStoryResult = {
  id: string;
  rawStory?: RawRecentlyReadStory;
  story?: RecentlyReadStory;
  loading: LoadingState;
  error: string | null;
};

export type RecentlyReadEntry = {
  id: string;
  read: number;
};

export interface ReadingList {
  id: string;
  name: string;
  description: string;
  owner: boolean;
  image?: ImageData;
  storyIds: string[];
  stories: ReadingListStory[];
}

export interface RawReadingList {
  id: string;
  attributes: {
    name: string;
    description: string;
    owner: boolean;
    reading_list_stories: {
      data: [
        {
          attributes: {
            story_id: string;
            order: number;
            completed: boolean;
          };
        },
      ];
    };
  };
}

export type ReadingListResult = {
  id: string;
  readingList?: ReadingList;
  loading: LoadingState;
  error: string | null;
};

export type ReadingListsResult = EntityState<ReadingListResult>;
export type ReadingListStoriesResult = EntityState<ReadingListStoryResult>;
export type RecentlyReadStoriesResult = EntityState<RecentlyReadStoryResult>;
export type RecentlyReadEntries = EntityState<RecentlyReadEntry>;

export interface ReadingListsState extends LoadState {
  readingLists: ReadingListsResult;
  readingListStories: ReadingListStoriesResult;
  recentlyReadStories: RecentlyReadStoriesResult;
  recentlyRead: RecentlyReadEntries;
}

// TODO fix this raw type split by unifying the schemas
export type RawBadge = {
  _id: string;
  title: string;
  thumbnail: unknown;
  badges: {
    _id: string;
    name: string;
    image: unknown;
    trigger: string;
  }[];
};

export type Badge = {
  id: string;
  name: string;
  trigger: string;
  images: {
    thumbnail: Maybe<SanityImage>;
    full: Maybe<SanityImage>;
  };
  // TODO fix the stories type
  stories: any[];
  unlocked: boolean;
};

export type BadgeReader = {
  badgeId: string;
};

export type BadgeReaderResult = {
  badgeReader?: BadgeReader;
  loading: LoadingState;
  error: string | null;
};

export type BadgeReadersResult = EntityState<BadgeReaderResult>;

// export type BadgeResult = {
//   id: string;
//   rawBadge?: RawBadge;
//   badge?: Badge;
//   badgeReader?: BadgeReader;
//   loading: LoadingState;
//   error: string | null;
// };

export type BadgeResult = Badge;

export type BadgesResult = EntityState<BadgeResult>;

export type BadgeStoryResult = {
  id: string;
  title: string;
  thumbnail: unknown;
  rawBadge?: RawBadge;
  badges?: Badge[];
  loading: LoadingState;
  error: string | null;
};

export type BadgeStoriesResult = EntityState<BadgeStoryResult>;

export interface BadgesState extends LoadState {
  badges: BadgesResult;
  badgeStories: BadgeStoriesResult;
  badgeReaders: BadgeReadersResult;
}

export interface PanelResponse {
  storyId: string;
  panelId: string;
  data: string | object;
}

export type PanelResponseResult = {
  panelResponse?: PanelResponse;
  loading: LoadingState;
  error: string | null;
};

export type PanelResponsesResult = EntityState<PanelResponseResult>;

export interface PanelResponseState extends LoadState {
  panelResponses: PanelResponsesResult;
}

export interface Feedback {
  uuid: string;
  body: string;
  target: string;
  type: string;
}

export type FeedbackResult = {
  feedback?: Feedback;
  loading: LoadingState;
  error: string | null;
};

export type FeedbacksResult = EntityState<FeedbackResult>;

export interface FeedbackState extends LoadState {
  feedbacks: FeedbacksResult;
}

export interface User {
  uuid: string;
  email: string;
  jwt: string;
  issuedAt: number;
  expiresAt: number;
  role: UserRole;
  mode: ReadingExperience;
}

export const isUser = (user: unknown): user is User => {
  return (
    isPlainObject(user) &&
    user.hasOwnProperty('uuid') &&
    'email' in user &&
    'jwt' in user &&
    'issuedAt' in user &&
    'expiresAt' in user &&
    'role' in user &&
    'mode' in user
  );
};

export interface UserDataState {
  user: Partial<User>;
}

export type UserState = UserDataState & LoadState;

export type RawBadgeReader = {
  attributes: {
    badge_id: string;
  };
};

export type PanelType =
  SanityPanelOrPanelAnimationOrPanelComparisonOrPanelDrawingOrPanelHotspotOrPanelImmersiveTextOrPanelMatchingOrPanelMultipleChoiceOrPanelShortResponseOrPanelTimeline;

export type SanityPanelInteractive =
  | SanityPanelAnimation
  | SanityPanelComparison
  | SanityPanelDrawing
  | SanityPanelHotspot
  | SanityPanelImmersiveText
  | SanityPanelMatching
  | SanityPanelMultipleChoice
  | SanityPanelShortResponse
  | SanityPanelTimeline;

export const isPanelImage = (panel?: PanelType): panel is SanityPanel => {
  return panel?._type === 'panel';
};
export const isPanelAnimation = (
  panel?: PanelType,
): panel is SanityPanelAnimation => {
  return panel?._type === 'panel-animation';
};
export const isPanelComparison = (
  panel?: PanelType,
): panel is SanityPanelComparison => {
  return panel?._type === 'panel-comparison';
};
export const isPanelDrawing = (
  panel?: PanelType,
): panel is SanityPanelDrawing => {
  return panel?._type === 'panel-drawing';
};
export const isPanelHotspot = (
  panel?: PanelType,
): panel is SanityPanelHotspot => {
  return panel?._type === 'panel-hotspot';
};
export const isPanelImmersiveText = (
  panel?: PanelType,
): panel is SanityPanelImmersiveText => {
  return panel?._type === 'panel-immersiveText';
};
export const isPanelMatching = (
  panel?: PanelType,
): panel is Omit<SanityPanelMatching, 'answers'> & {
  _answers: Maybe<Array<Maybe<SanityMatchingAnswer>>>;
} => {
  return panel?._type === 'panel-matching';
};
export const isPanelMultipleChoice = (
  panel?: PanelType,
): panel is SanityPanelMultipleChoice => {
  return panel?._type === 'panel-multipleChoice';
};
export const isPanelShortResponse = (
  panel?: PanelType,
): panel is SanityPanelShortResponse => {
  return panel?._type === 'panel-shortResponse';
};
export const isPanelTimeline = (
  panel?: PanelType,
): panel is SanityPanelTimeline => {
  return panel?._type === 'panel-timeline';
};
export const isPanelInteractive = (
  panel?: PanelType,
): panel is SanityPanelInteractive => {
  return (
    isPanelComparison(panel) ||
    isPanelDrawing(panel) ||
    isPanelHotspot(panel) ||
    isPanelImmersiveText(panel) ||
    isPanelMatching(panel) ||
    isPanelMultipleChoice(panel) ||
    isPanelShortResponse(panel) ||
    isPanelTimeline(panel)
  );
};

type NonNullFields<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export type StoryProps = {
  pageContext: {
    _id: string;
    title: string;
    creators: StoryCreatorData[];
    countries: CountryData[];
    medium: WithAliasedImages<SanityMedium>;
    location?: WithAliasedImages<SanityStoryLocation>;
    characters: WithAliasedImages<SanityStoryCharacter>[];
    genres: GenreData[];
    description: string | NonNullFields<SanityBlock>[];
    panels: PanelType[];
    recommended: SanityStoryRecommended[];
    badges: Badge[];
    images: {
      thumbnail: {
        desktop: SanityVisualLayeredThumbnail[];
        mobile: SanityVisualLayeredThumbnail[];
        recentlyRead: SanityImage;
        seo: SanityImage;
      };
      heroBanner?: {
        // TODO: make this required
        desktop: SanityImage;
        mobile: SanityImage;
      };
      featured?: {
        // TODO: make this required
        desktop: SanityImage;
        mobile: SanityImage;
      };
    };
  };
} & RouteComponentProps;
export type IntroProps = {
  intro: Omit<SanityPagesHomeIntro, 'mainArt' | 'artLion'> & {
    mainArtDesktop?: Maybe<SanityImage>;
    mainArtMobile?: Maybe<SanityImage>;
    artLionDesktop?: Maybe<SanityImage>;
    artLionMobile?: Maybe<SanityImage>;
  };
};

type RNNHelper<T, K extends string | number | symbol> = {
  [P in keyof T]: RecursiveNonNullable<T[P], K>;
};
type RecursiveNonNullable<T, K extends string | number | symbol> = T extends {
  [P in K]: any;
}
  ? T
  : Required<NonNullable<RNNHelper<T, K>>>;

export type FeaturedProps = {
  featured: SanityPagesHomeFeatured;
};
export type SignUpProps = {
  signUp: SanityPagesHomeSignup;
  className?: string;
};
export type FullCountry = SanityCountry & {
  name: Required<NonNullable<SanityCountry['name']>>;
};
export type FullCategory = SanityCategory & {
  name: Required<NonNullable<SanityCategory['name']>>;
};
export type FullStoryCreator = RecursiveNonNullable<
  SanityStoryCreator,
  'lowerLayer'
>;

interface PlainObject {
  hasOwnProperty<K extends string>(key: K): this is Record<K, unknown>;

  hasOwn<K extends string>(key: K): this is Record<K, unknown>;
}

export function isPlainObject(value: unknown): value is PlainObject {
  return !!value && typeof value === 'object' && !Array.isArray(value);
}

export const isCountryData = (data: unknown): data is CountryData => {
  if (!isPlainObject(data)) return false;
  if (!data) return false;
  if (!data.hasOwnProperty('name')) return false;
  const { name } = data;
  return typeof name === 'string';
};

export const isSanityBlock = (
  data: unknown,
): data is SanityBlock[] | string => {
  if (typeof data === 'string') return true;
  if (!Array.isArray(data)) return false;
  if (!data[0].hasOwnProperty('_type')) return false;
  const { _type } = data[0];
  return typeof _type === 'string';
};

export const isStoryCreatorData = (
  creator: unknown,
): creator is StoryCreatorData => {
  if (creator === null) return false;
  if (!creator) return false;
  if (!isPlainObject(creator)) return false;
  if (!creator.hasOwnProperty('creator')) return false;
  if (!isPlainObject(creator.creator)) return false;
  if (!creator.creator.hasOwnProperty('name')) return false;
  if (!creator.creator.hasOwnProperty('bio')) return false;
  return !!creator.creator && !!creator.creator.name && !!creator.creator.bio;
};

export type WithAliasedImages<T> = Omit<
  T & {
    desktop?: Maybe<SanityImage>;
    mobile?: Maybe<SanityImage>;
  },
  'image'
>;

export const isTextResponseData = (data: unknown): data is string => {
  return !!data && typeof data === 'string';
};

export const isObjectResponseData = (data: unknown): data is string => {
  return !!data && isPlainObject(data);
};
