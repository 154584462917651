import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useCallback, useEffect } from 'react';
import {
  resetFeedbacks,
  selectAllFeedbacks,
} from '@/app/features/feedbacks/slice';
import { fetchFeedbacks, postFeedback } from '@/app/features/feedbacks/thunks';

export function useFetchFeedbacks() {
  const dispatch = useDispatch();
  const feedbacks = useSelector((state: RootState) => {
    return selectAllFeedbacks(state.feedbacks);
  });
  const loading = useSelector((state: RootState) => state.feedbacks.loading);
  const error = useSelector((state: RootState) => state.feedbacks.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    if (loading !== 'idle') {
      dispatch(resetFeedbacks());
    }
  }, []);
  useEffect(() => {
    if (user && loading === 'idle') {
      dispatch(fetchFeedbacks());
    }
  }, [user, loading]);
  return { feedbacks, loading, error };
}

export function usePostFeedback(target: string, type: string) {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.feedbacks.loading);
  const error = useSelector((state: RootState) => state.feedbacks.error);
  const handleFeedback = useCallback(
    (body: string) => {
      dispatch(postFeedback({ body, target, type }));
    },
    [target, type],
  );
  return { handleFeedback, loading, error };
}
