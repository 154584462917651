import {
  GenericPageHeader,
  StyledGenericPage,
} from '@/components/page/generic';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useFetchUser } from '@/app/features/user/hooks';
import { PageLoading } from '@/components/PageLoading';
import { navigate } from 'gatsby';

export const ProfileRedirectPage: React.FC = () => {
  const [token] = useQueryParam('access_token', StringParam);
  const { loading, error } = useFetchUser(token);

  if (loading === 'succeeded') {
    navigate('/profile/view');
    return null;
  }

  return (
    <StyledGenericPage>
      <GenericPageHeader>Logging in...</GenericPageHeader>
      <PageLoading loading={loading} error={error} />
    </StyledGenericPage>
  );
};

export default ProfileRedirectPage;
