import { createSlice } from '@reduxjs/toolkit';
import { initialState, panelResponseAdapter } from './initialState';
import { PanelResponseResult, PanelResponseState } from '@/types';
import {
  fetchPanelResponses,
  postPanelResponse,
} from '@/app/features/panelResponses/thunks';
import { convertPanelResponseToPanelResponseResult } from '@/app/features/panelResponses/utils';
import { logOut } from '@/app/features/user/slice';

const slice = createSlice({
  name: 'panelResponses',
  initialState,
  reducers: {
    resetPanelResponses: (state) => {
      panelResponseAdapter.removeAll(state.panelResponses);
      state.loading = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPanelResponses.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchPanelResponses.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(fetchPanelResponses.fulfilled, (state, action) => {
        panelResponseAdapter.upsertMany(
          state.panelResponses,
          Object.values(action.payload).map<PanelResponseResult>(
            convertPanelResponseToPanelResponseResult,
          ),
        );
        state.loading = 'succeeded';
      })
      .addCase(postPanelResponse.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postPanelResponse.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(postPanelResponse.fulfilled, (state, action) => {
        panelResponseAdapter.upsertMany(
          state.panelResponses,
          Object.values([action.payload]).map<PanelResponseResult>(
            convertPanelResponseToPanelResponseResult,
          ),
        );
        state.loading = 'succeeded';
      })
      .addCase(logOut, (state) => {
        // this is wild and testament to the goodness of RTK
        // we can use an action creator from another slice to affect state
        // in this slice. Good video on this: https://www.youtube.com/watch?v=oEEXhHy_i4I
        state.panelResponses = initialState.panelResponses;
      });
  },
});

export const {
  selectIds: selectPanelResponseIds,
  selectById: selectPanelResponseById,
  selectEntities: selectPanelResponseEntities,
  selectAll: selectAllPanelResponses,
  selectTotal: selectTotalPanelResponses,
} = panelResponseAdapter.getSelectors(
  (state: PanelResponseState) => state.panelResponses,
);

export const { resetPanelResponses } = slice.actions;

export default slice.reducer;
