import { GatsbyBrowser } from 'gatsby';
import { baseTheme } from '@astc/frontend-components';
import { ThemeProvider } from '@emotion/react';
import { Provider as ReduxProvider } from 'react-redux';
import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from '@/app/store';
import { BadgesDataProvider } from '@/components/providers/BadgesDataProvider';
import { ReadingListStoriesDataProvider } from '@/components/providers/ReadingListStoriesDataProvider';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import { persistStore } from 'redux-persist';
import { RecentlyReadStoriesDataProvider } from '@/components/providers/RecentlyReadStoriesDataProvider';

const FrontendGate: React.FC = ({ children }) => {
  const [ssr, setSSR] = React.useState<boolean>(true);
  const persistor = persistStore(store);
  useEffect(() => {
    setSSR(false);
  }, []);

  if (ssr) {
    return <>{children}</>;
  }

  return (
    <PersistGate loading={children} persistor={persistor}>
      {children}
    </PersistGate>
  );
};

export const FrontendRoot: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <ThemeProvider theme={baseTheme}>
      <QueryParamProvider adapter={ReachAdapter}>
        <ReduxProvider store={store}>
          <FrontendGate>
            <BadgesDataProvider />
            <ReadingListStoriesDataProvider />
            <RecentlyReadStoriesDataProvider />
            {element}
          </FrontendGate>
        </ReduxProvider>
      </QueryParamProvider>
    </ThemeProvider>
  );
};
