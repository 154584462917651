import React from 'react';
import { GenericPage } from '@/components/page/generic';
import { graphql, PageProps } from 'gatsby';
import { SanityPageBehindTheScenesQuery } from '../../graphql-types';

type BTSProps = { data: SanityPageBehindTheScenesQuery } & PageProps;

export const BehindTheScenesPage: React.FC<BTSProps> = ({ data }) => {
  const { sanityPageGeneric } = data;
  return (
    <GenericPage
      title={sanityPageGeneric?.title}
      elements={sanityPageGeneric?.content?.elements || []}
    />
  );
};

export const gql = graphql`
  query SanityPageBehindTheScenes {
    sanityPageGeneric(slug: { current: { eq: "behind-the-scenes" } }) {
      slug {
        current
      }
      title
      content {
        elements {
          ... on SanityHeader {
            _key
            _type
            text
          }
          ... on SanityBody {
            _key
            _type
            text
          }
          ... on SanityImage {
            _key
            _type
            asset {
              gatsbyImageData
            }
          }
          ... on SanityVimeoId {
            _key
            _type
            id
          }
        }
      }
    }
  }
`;

export default BehindTheScenesPage;
