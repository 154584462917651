import {
  GenericPageHeader,
  StyledGenericPage,
} from '@/components/page/generic';
import { dim, Header4, Logo, Text } from '@astc/frontend-components';
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useHasUser } from '@/utils/hooks';

export const ProfileDeletePage: React.FC = () => {
  const hasUser = useHasUser();
  useEffect(() => {
    if (!hasUser) {
      navigate('/onboard/signIn');
      return;
    }
  }, []);

  return (
    <StyledGenericPage>
      <GenericPageHeader>
        Delete
        <Logo width={dim(68)} />
      </GenericPageHeader>
      <Header4 mb="3">Coming Soon</Header4>
      <Text mb="3">Check back with this feature in the near future!</Text>
    </StyledGenericPage>
  );
};

export default ProfileDeletePage;
