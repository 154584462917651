import {
  Box,
  CounterHeader,
  PageBoundary,
  randomListColor,
  ReadingListsCollection,
  ReadingListsItem,
} from '@astc/frontend-components';
import {
  LoadingState,
  ReadingList,
  ReadingListResult,
} from '../../../../../types';
import React from 'react';
import { SourcedImage } from '../../../../../components/media/SourcedImage';
import { Maybe, SanityImage } from '../../../../../../graphql-types';
import {
  ReadingListsPageDeleteOverlay,
  ReadingListsPageEditOverlay,
} from '../overlays/readingList';
import { PageLoading } from '@/components/PageLoading';

export interface ReadingListsPageProps {
  readingListResults: ReadingListResult[];
  onItemClick: (id: string) => () => void;
  loading: LoadingState;
  error: string | null;
}

export const ReadingListsPage: React.FC<ReadingListsPageProps> = ({
  readingListResults,
  onItemClick,
  loading,
  error,
}) => {
  const [editReadingListOverlay, setEditReadingListOverlay] =
    React.useState(false);
  const [deleteReadingListOverlay, setDeleteReadingListOverlay] =
    React.useState(false);
  const [activeReadingList, setActiveReadingList] =
    React.useState<ReadingList | null>(null);
  if (loading === 'failed') return <div>Error: {error}!</div>;
  if (loading === 'pending' || loading === 'idle')
    return <PageLoading loading={'pending'} error={null} />;
  return (
    <PageBoundary pt="5">
      <Box display="flex" flexDirection="column">
        <CounterHeader
          title="Reading Lists"
          amount={readingListResults.length}
          unit="List"
        />
        <ReadingListsCollection>
          {readingListResults.map(({ readingList, loading }) => {
            if (!readingList || loading !== 'succeeded') return null;

            const image = readingList.stories[0]
              ?.imageVertical as Maybe<SanityImage>;

            return (
              <>
                <ReadingListsItem
                  key={readingList.id}
                  readingList={{
                    ...readingList,
                    count: { total: readingList.stories.length, read: 0 },
                  }}
                  readingListImage={
                    !!image ? (
                      <SourcedImage source={image} style={{ width: '109px' }} />
                    ) : null
                  }
                  frameColor={randomListColor(readingList.id.substring(0, 2))}
                  onItemClick={onItemClick(readingList.id)}
                  onEditClick={() => {
                    setActiveReadingList(readingList);
                    setEditReadingListOverlay(true);
                  }}
                  onDeleteClick={() => {
                    setActiveReadingList(readingList);
                    setDeleteReadingListOverlay(true);
                  }}
                />
              </>
            );
          })}
        </ReadingListsCollection>
        {editReadingListOverlay && activeReadingList && (
          <ReadingListsPageEditOverlay
            readingList={activeReadingList}
            onClose={() => {
              setEditReadingListOverlay(false);
              setActiveReadingList(null);
            }}
          />
        )}
        {deleteReadingListOverlay && activeReadingList && (
          <ReadingListsPageDeleteOverlay
            readingList={activeReadingList}
            onClose={() => {
              setDeleteReadingListOverlay(false);
              setActiveReadingList(null);
            }}
          />
        )}
      </Box>
    </PageBoundary>
  );
};
