import { ProfileViewControl } from '@/app/features/user/components/ProfileViewControl';
import { PageBackground } from '@/components/PageBackground';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { logOut } from '@/app/features/user/slice';
import { useFetchUser } from '@/app/features/user/hooks';
import { useFetchReadingLists } from '@/app/features/readingLists/hooks';
import { useEffect } from 'react';
import { useFetchBadgesWithBadgeReaders } from '@/app/features/badges/hooks';
import { BadgeResult } from '@/types';
import { useHasUser } from '@/utils/hooks';
import { PageLoading } from '@/components/PageLoading';

interface ProfileViewPageContainerProps {}

const pvpc: React.FC<ProfileViewPageContainerProps> = () => {
  const { loading: userLoading, user } = useFetchUser();
  const hasUser = useHasUser();
  const { readingLists } = useFetchReadingLists(true);
  const { badges, badgeReaders } = useFetchBadgesWithBadgeReaders();
  const dispatch = useDispatch();

  const unlockedBadges =
    badges
      .filter((badgeResult): badgeResult is BadgeResult => !!badgeResult)
      .map((badgeResult: BadgeResult) => {
        return {
          ...badgeResult,
          unlocked: !!badgeReaders.find((badgeReader) => {
            return badgeReader.badgeReader?.badgeId === badgeResult.id;
          }),
        };
      }) || [];

  useEffect(() => {
    if (hasUser !== false) {
      return;
    }
    navigate('/onboard/signIn');
  }, [hasUser]);

  if (hasUser !== true || userLoading === 'pending') {
    return <PageLoading loading={'pending'} error={null} />;
  }

  return (
    <PageBackground>
      <ProfileViewControl
        email={user.email || ''}
        badges={unlockedBadges}
        readingListResults={
          readingLists.filter(
            (readingList) => readingList.readingList?.owner,
          ) || []
        }
        profileSettingsRoute={'/profile/settings'}
        badgesRoute={'/badges'}
        readingListsRoute={'/readingLists'}
        onReadingListProfileItemClick={(id) => {
          navigate(`/readingList/${id}`);
        }}
        onCreateAssignment={() => {
          window.location.href = `${process.env.GATSBY_READER_DATA_API_URL}/responses/classroom/google/create`;
        }}
        onLogOut={() => {
          dispatch(logOut());
          navigate('/onboard/signIn');
        }}
      />
    </PageBackground>
  );
};

export default pvpc;
