import {
  Box,
  dim,
  Header2,
  Header7,
  IconButton,
} from '@astc/frontend-components';
import styled from '@emotion/styled';
import { SourcedImage } from '@/components/media/SourcedImage';
import { PageBackground } from '@/components/PageBackground';
import { IntroProps } from '@/types';
import { down } from 'styled-breakpoints';
import { navigate } from 'gatsby';

const StyledIntro = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${dim(2442)};
  padding-top: ${dim(20)};
  overflow-x: hidden;
  ${down('mobile')} {
    height: ${dim(1558)};
  }
`;

const IntroText = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100vw;
  left: ${dim(323, 'vw', { isRelativeToDefault: true })};
  width: ${dim(436)};
  ${down('mobile')} {
    top: ${dim(964)};
    left: ${dim(22)};
    width: ${dim(196)};
  }
  @media (min-width: 1575px) {
    top: ${dim(1575)};
  }
`;

const _IntroLion: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const handleClick = () => {
    navigate('/about');
  };
  return (
    <Box className={className}>
      <IconButton onClick={handleClick}>{children}</IconButton>
    </Box>
  );
};

const IntroLion = styled(_IntroLion)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 142vw; // 1648 - 272, the real start of the intro text
  left: ${dim(140, 'vw', { isRelativeToDefault: true })};
  width: ${dim(366)};
  ${down('mobile')} {
    top: ${dim(1332)};
    left: ${dim(22)};
    width: ${dim(266)};
  }
  @media (min-width: 1312px) {
    top: 132vw; // 1648 - 272, the real start of the intro text
  }
  @media (min-width: 1575px) {
    top: ${dim(2075)}; // 1648 - 272, the real start of the intro text
  }
`;

const _Intro: React.FC<IntroProps> = ({ intro }) => {
  const {
    title,
    body,
    backgroundDesktop,
    backgroundMobile,
    mainArtDesktop,
    mainArtMobile,
    artLionDesktop,
    artLionMobile,
  } = intro;

  return (
    <PageBackground
      desktop={backgroundDesktop}
      mobile={backgroundMobile}
      minHeight={'1600px'}
      style={{
        backgroundSize: 'contain',
        backgroundPositionY: 'top',
        zIndex: 3,
      }}
    >
      <StyledIntro>
        <SourcedImage
          desktopSource={mainArtDesktop}
          mobileSource={mainArtMobile}
        />
        <IntroText>
          <Header2 mb={dim(60)}>{title}</Header2>
          <Header7 style={{ whiteSpace: 'pre-wrap', lineHeight: '1.1' }}>
            {body}
          </Header7>
        </IntroText>
        <IntroLion>
          <SourcedImage
            desktopSource={artLionDesktop}
            mobileSource={artLionMobile}
          />
        </IntroLion>
      </StyledIntro>
    </PageBackground>
  );
};

export const Intro = styled(_Intro)`
  background-position: top center;
  background-repeat: repeat-y;
  background-size: cover;
`;
