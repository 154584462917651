import React from 'react';
import { Maybe, SanityCountry, SanityImage, SanityStory } from 'graphql-types';
import styled from '@emotion/styled';
import { Box, dim } from '@astc/frontend-components';
import { PageBackground } from '@/components/PageBackground';
import { CountryListSectionHeader1 } from '@/components/collections/ListSectionHeader';
import { IndexComicCardContainer } from '@/components/FeaturedStories/IndexComicCardContainer';
import {
  isAlignment,
  ListSectionStories,
} from '@/components/collections/ListSectionStories';
import { ComicCardContainer } from '@/components/FeaturedStories/ComicCardContainer';

type CountryListSectionProps = {
  country: SanityCountry;
  alignment: string;
  alignmentMobile: string;
  titleOffset: number;
  storyOffset: number;
  stories: SanityStory[];
  backgroundDesktop?: Maybe<SanityImage>;
  backgroundMobile?: Maybe<SanityImage>;
};

const alignToFlex = (alignment?: string) => {
  if (!isAlignment(alignment)) return 'flex-start';
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
  }
};

const alignReverse = (alignment?: string): 'left' | 'right' => {
  if (!isAlignment(alignment)) return 'left';
  switch (alignment) {
    case 'left':
      return 'right';
    case 'right':
      return 'left';
  }
};

const _CountryListSection: React.FC<CountryListSectionProps> = ({
  country,
  alignment,
  alignmentMobile,
  titleOffset,
  storyOffset,
  stories,
  backgroundDesktop,
  backgroundMobile,
}) => {
  return (
    <PageBackground
      desktop={backgroundDesktop}
      mobile={backgroundMobile}
      style={{
        backgroundPositionY: 'top',
      }}
    >
      <Box key={country.name} mt="4" mb="3" width="100%">
        <Box
          width="100%"
          height="100%"
          position="absolute"
          top={[dim(80), dim(titleOffset)]}
          display="flex"
          flexDirection="row"
          justifyContent={alignToFlex(alignment)}
        >
          <CountryListSectionHeader1
            alignment={isAlignment(alignment) ? alignment : 'left'}
            alignmentMobile={
              isAlignment(alignmentMobile) ? alignmentMobile : 'left'
            }
          >
            {country.name}
          </CountryListSectionHeader1>
        </Box>
        <ListSectionStories
          alignment={alignReverse(alignment)}
          alignmentMobile={
            isAlignment(alignmentMobile) ? alignmentMobile : 'left'
          }
          top={[dim(376), storyOffset]}
        >
          {stories.map((story) => {
            return (
              <ComicCardContainer key={story._key} story={story} clear={true} />
            );
          })}
        </ListSectionStories>
      </Box>
    </PageBackground>
  );
};

export const CountryListSection = styled(_CountryListSection)`
  margin-top: ${dim(43)};
`;
