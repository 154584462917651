import {
  Maybe,
  SanityMatchingAnswer,
  SanityPanelMatching,
} from '../../../../graphql-types';
import { MatchingPanel } from '@astc/frontend-components';
import {
  getSourcedImageData,
  SourcedImage,
} from '@/components/media/SourcedImage';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useGetStoryId, useMobileSized } from '@/utils/hooks';
import { WithAliasedImages } from '@/types';
import { usePostPanelResponse } from '@/app/features/panelResponses/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectPanelResponseById } from '@/app/features/panelResponses/slice';

type SanityPanelMatchingAliasedImages = Omit<
  WithAliasedImages<SanityPanelMatching>,
  'answers'
> & {
  _answers: Maybe<Array<Maybe<SanityMatchingAnswer>>>;
};

export const MatchingPanelContainer: React.FC<{
  panel: SanityPanelMatchingAliasedImages;
}> = ({ panel }) => {
  if (!panel._key) return null;
  const { mobile: isMobile } = useBreakpoint();
  useMobileSized();
  const storyId = useGetStoryId();
  const { handlePanelResponse, loading, error } = usePostPanelResponse(
    storyId,
    panel._key,
  );
  const panelResponse = useSelector((state: RootState) => {
    if (!panel._key) return;
    return selectPanelResponseById(state.panelResponses, panel._key);
  });
  const [answers, setAnswers] = React.useState<Record<string, string>>({});
  const handleSubmit = () => {
    handlePanelResponse(JSON.stringify(answers));
  };
  const image = isMobile ? panel.mobile : panel.desktop;

  return (
    <>
      {loading === 'pending' && <div>Saving...</div>}
      {error && <div>Error: {error}</div>}
      <MatchingPanel
        key={panel._key}
        image={
          image ? (
            <SourcedImage alt={panel.caption || ''} source={image} />
          ) : null
        }
        width={getSourcedImageData(image).width}
        height={getSourcedImageData(image).height}
        caption={panel.caption || ''}
        cta={panel.cta || ''}
        instructions={panel.instructions || ''}
        questions={panel.questions ?? []}
        answers={panel._answers ?? []}
        response={
          typeof panelResponse?.panelResponse?.data === 'object'
            ? (panelResponse?.panelResponse?.data as Record<string, string>)
            : undefined
        }
        onAnswerChange={(question, answer) => {
          setAnswers((prev: Record<string, string>) => ({
            ...prev,
            [question]: answer,
          }));
        }}
        onSubmit={() => {
          handleSubmit();
        }}
      />
    </>
  );
};
