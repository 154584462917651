import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Maybe, SanityVisualLayeredThumbnail } from '../../graphql-types';
import { StoryContext, StoryContextType } from '../contexts/StoryContext';
import { RootState } from '../app/store';

export const useMobileSized = () => {
  const { mobile: isMobile } = useBreakpoint();
  const sized = isMobile !== undefined;
  return { isMobile, sized };
};

export function useLayeredThumbnails(
  type: string,
  desktop?: Maybe<Array<Maybe<SanityVisualLayeredThumbnail>>>,
  mobile?: Maybe<Array<Maybe<SanityVisualLayeredThumbnail>>>,
) {
  const { isMobile, sized } = useMobileSized();

  const desktopThumbs = desktop?.find((thumb) => thumb?.type === type);

  const mobileThumbs = mobile?.find((thumb) => thumb?.type === type);

  const { upperLayer: mu, midLayer: mm, lowerLayer: ml } = mobileThumbs || {};
  const { upperLayer: du, midLayer: dm, lowerLayer: dl } = desktopThumbs || {};

  const upperLayer = isMobile ? mu : du;
  const midLayer = isMobile ? mm : dm;
  const lowerLayer = isMobile ? ml : dl;
  return { sized, upperLayer, midLayer, lowerLayer };
}

// function postBadgeAward(token: string, badgeId: string) {
//   // TODO: add error handling
//   // TODO: add loading state
//   // TODO: use ENV variable for API URL
//   fetch('http://localhost:1337/api/reader-badges', {
//     method: 'POST',
//     body: JSON.stringify({ data: { badge_id: badgeId } }),
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

// function postPanelResponse(
//   token: string,
//   storyId: string,
//   panelId: string,
//   data: string,
// ) {
//   // TODO: add error handling
//   // TODO: add loading state
//   // TODO: use ENV variable for API URL
//   fetch('http://localhost:1337/api/reader-responses', {
//     method: 'POST',
//     body: JSON.stringify({
//       data: { story_id: storyId, panel_id: panelId, data },
//     }),
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

// export const useAwardBadge = (badgeId: string) => {
//   const token = useSelector((state: RootState) => state.user.user.jwt);
//   useEffect(() => {
//     if (!token) return;
//     //postBadgeAward(token, badgeId);
//   }, [token]);
// };
// export const useRespondPanel = (storyId: string) => {
//   const token = useSelector((state: RootState) => state.user.user.jwt);
//   return useCallback(
//     (panelId: string, data: string) => {
//       if (!token) return;
//       //postPanelResponse(token, storyId, panelId, data);
//     },
//     [token],
//   );
// };
// export type PanelResponse = ReturnType<typeof useRespondPanel>;
//
export const useGetStoryId = () => {
  const context = useContext<StoryContextType | null>(StoryContext ?? {});
  if (!context) {
    throw new Error('StoryContext is not defined');
  }
  const { storyId } = context;
  return storyId;
};

export const useHasUser = (): boolean | undefined => {
  const uuid = useSelector((state: RootState) => state.user.user.uuid);
  const rehydrated = useSelector((state: RootState) => {
    return state.user._persist.rehydrated;
  });
  if (rehydrated && uuid === undefined) return false; // persist reducer timeout case
  if (!rehydrated) return undefined; // persist reducer not rehydrated yet, leave ambiguous
  return !!uuid;
};
