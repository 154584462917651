import React from 'react';
import {
  Maybe,
  SanityImage,
  SanityPanelComparison,
} from '../../../../graphql-types';
import { ComparisonPanel } from '@astc/frontend-components';
import { getSourcedImageData } from '@/components/media/SourcedImage';
import { useGetStoryId } from '@/utils/hooks';
import { usePostPanelResponse } from '@/app/features/panelResponses/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectPanelResponseById } from '@/app/features/panelResponses/slice';
import { isTextResponseData, WithAliasedImages } from '@/types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type SanityPanelComparisonAliasImages =
  WithAliasedImages<SanityPanelComparison> & {
    desktopSecond?: Maybe<SanityImage>;
    mobileSecond?: Maybe<SanityImage>;
  };

export const ComparisonPanelContainer: React.FC<{
  panel: SanityPanelComparisonAliasImages;
}> = ({ panel }) => {
  const storyId = useGetStoryId();
  const { handlePanelResponse, loading, error } = usePostPanelResponse(
    storyId,
    panel._key,
  );
  const panelResponse = useSelector((state: RootState) => {
    if (!panel._key) return;
    return selectPanelResponseById(state.panelResponses, panel._key);
  });
  const { mobile: isMobile } = useBreakpoint();

  const handleSubmit = (answer: string) => {
    handlePanelResponse(JSON.stringify(answer));
  };
  const handleComparisonChange = () => {};

  return (
    <>
      {loading === 'pending' && <div>Saving...</div>}
      {error && <div>Error: {error}</div>}
      {isMobile ? (
        <ComparisonPanel
          key={panel._key}
          width={getSourcedImageData(panel.mobile).width}
          height={getSourcedImageData(panel.mobile).height}
          alt={panel.caption || ''}
          alt2={panel.caption || ''}
          cta={panel.caption || ''}
          onSubmit={handleSubmit}
          onComparisonChange={handleComparisonChange}
          question={panel.caption || ''}
          src={getSourcedImageData(panel.mobile).images.fallback?.src || ''}
          src2={
            getSourcedImageData(panel.mobileSecond).images.fallback?.src || ''
          }
          answer={
            isTextResponseData(panelResponse?.panelResponse?.data)
              ? panelResponse?.panelResponse?.data
              : ''
          }
        >
          {panel.caption}
        </ComparisonPanel>
      ) : (
        <ComparisonPanel
          key={panel._key}
          width={getSourcedImageData(panel.desktop).width}
          height={getSourcedImageData(panel.desktop).height}
          alt={panel.caption || ''}
          alt2={panel.caption || ''}
          cta={panel.caption || ''}
          onSubmit={handleSubmit}
          onComparisonChange={handleComparisonChange}
          question={panel.caption || ''}
          src={getSourcedImageData(panel.desktop).images.fallback?.src || ''}
          src2={
            getSourcedImageData(panel.desktopSecond).images.fallback?.src || ''
          }
        >
          {panel.caption}
        </ComparisonPanel>
      )}
    </>
  );
};
