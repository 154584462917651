import React from 'react';
import {
  Maybe,
  SanityStory,
  SanityVisualLayeredThumbnail,
} from '../../../../graphql-types';
import {
  Box,
  dim,
  StoryCreatorData,
  VerticalComicCard,
} from '@astc/frontend-components';
import { SourcedImage } from '@/components/media/SourcedImage';
import { FullCategory, isCountryData, isStoryCreatorData } from '@/types';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { useLayeredThumbnails } from '@/utils/hooks';

const StyledVerticalComicCardContainer = styled(Box)`
  margin-right: ${dim(24)};
  min-width: ${dim(604)};
  &:last-child {
    margin-right: 0;
  }
  ${down('mobile')} {
    min-width: ${dim(312)};
  }
`;

export const VerticalComicCardContainer: React.FC<{
  story: Maybe<
    SanityStory & {
      layeredThumbnailsDesktopVertical?: Maybe<
        Array<Maybe<SanityVisualLayeredThumbnail>>
      >;
      layeredThumbnailsMobileVertical?: Maybe<
        Array<Maybe<SanityVisualLayeredThumbnail>>
      >;
    }
  >;
  onlyAuthors?: boolean;
}> = ({ story, onlyAuthors = false }) => {
  const { sized, upperLayer, midLayer, lowerLayer } = useLayeredThumbnails(
    'vertical',
    story?.layeredThumbnailsDesktopVertical,
    story?.layeredThumbnailsMobileVertical,
  );

  if (!story?.title) return null;

  const country = isCountryData(story.country) ? story.country : null;

  let creatorsData =
    (story?.creators?.filter(isStoryCreatorData) as StoryCreatorData[]) || [];

  if (onlyAuthors) {
    creatorsData = creatorsData.filter(
      (creator) => creator.role === 'author' || creator.role === 'artist',
    );
  }

  return (
    <StyledVerticalComicCardContainer>
      <VerticalComicCard
        key={story._id}
        onClick={() => {
          navigate(`/story/${story._id}`);
        }}
        title={story.title}
        lowerLayer={sized && <SourcedImage source={lowerLayer} />}
        midLayer={sized && <SourcedImage source={midLayer} />}
        upperLayer={sized && <SourcedImage source={upperLayer} />}
        creators={creatorsData}
        genres={
          story?.categories?.filter(
            (category): category is FullCategory => !!category?.name,
          ) || []
        }
        country={country}
        prologue={story.description}
      />
    </StyledVerticalComicCardContainer>
  );
};
