import {
  Maybe,
  SanityBlock,
  SanityBody,
  SanityHeader,
  SanityImage,
  SanityPagesHelpItem,
} from '../../graphql-types';
import { isPlainObject } from '../types';

export const isSanityHeaderOrBody = (
  element: unknown,
): element is SanityHeader | SanityBody => {
  return (
    isPlainObject(element) &&
    element.hasOwnProperty('_type') &&
    (element._type === 'header' || element._type === 'body')
  );
};
export const isSanityImage = (image: unknown): image is SanityImage => {
  return (
    isPlainObject(image) &&
    image.hasOwnProperty('_type') &&
    image._type === 'image'
  );
};

export const isSanityVimeoVideo = (element: unknown): element is any => {
  return true // element.hasOwnProperty('_type') && element._type === 'vimeo_id';
};

export type SanityHelpItem = {
  _key: Required<SanityPagesHelpItem['_key']>;
  header: Required<SanityPagesHelpItem['header']>;
  body: Required<SanityPagesHelpItem['body']>;
};

export const isSanityHelpItem = (item: unknown): item is SanityHelpItem => {
  return (
    isPlainObject(item) &&
    item.hasOwnProperty('_key') &&
    !!item._key &&
    typeof item._key === 'string' &&
    item.hasOwnProperty('header') &&
    !!item.header &&
    typeof item.header === 'string' &&
    item.hasOwnProperty('body') &&
    !!item.body &&
    Array.isArray(item.body)
  );
};

// cf: https://www.sanity.io/docs/presenting-block-text#ac67a867dd69
export const convertSanityBlockToPlainText = (
  blocks: string | Maybe<SanityBlock[]> | undefined,
) => {
  if (typeof blocks === 'string') {
    return blocks;
  }
  return (blocks || ([] as SanityBlock[]))
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map((child) => child?.text || '').join('');
    })
    .join('\n\n');
};
