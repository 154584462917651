import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  resetReadingLists,
  selectAllReadingLists,
  selectAllRecentlyRead,
  selectReadingListById,
} from './slice';
import { useCallback, useEffect, useState } from 'react';
import {
  deleteReadingList,
  fetchReadingListById,
  fetchReadingLists,
  postReadingList,
  putReadingList,
} from './thunks';
import { ReadingListResult } from '@/types';

export function useFetchReadingLists(reset = false) {
  const dispatch = useDispatch();
  const readingLists = useSelector((state: RootState) => {
    return selectAllReadingLists(state.readingLists);
  });
  const loading = useSelector((state: RootState) => state.readingLists.loading);
  const error = useSelector((state: RootState) => state.readingLists.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    if (user && loading === 'idle') {
      if (reset) dispatch(resetReadingLists());
      dispatch(fetchReadingLists());
    }
  }, [user, loading]);
  return { readingLists, loading, error };
}

export const useFetchReadingListById = (id: string) => {
  const dispatch = useDispatch();
  const readingListResult = useSelector((state: RootState) =>
    selectReadingListById(state.readingLists, id),
  );
  const loading = useSelector((state: RootState) => state.readingLists.loading);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    // TODO decide how to handle single fetches without existing lists of reading lists
    if (user && loading === 'idle') {
      dispatch(fetchReadingListById(id));
    }
  }, [id, user, loading]);
  return { readingListResult, loading };
};

export const useCheckStoryInReadingList = (storyId?: string) => {
  if (!storyId) return { listCount: 0 };
  const [listCount, setListCount] = useState(0);
  const readingLists = useSelector((state: RootState) => {
    return selectAllReadingLists(state.readingLists);
  });
  const loading = useSelector((state: RootState) => state.readingLists.loading);
  const error = useSelector((state: RootState) => state.readingLists.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    if (user && loading !== 'pending' && loading !== 'failed' && !error) {
      const foundLists = readingLists.filter((list: ReadingListResult) => {
        return !!list.readingList?.storyIds.find((id) => id === storyId);
      });
      setListCount(foundLists.length);
    }
  }, [user, loading, error, storyId]);
  return { listCount };
};

export function usePostReadingList() {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.panelResponses.loading,
  );
  const error = useSelector((state: RootState) => state.panelResponses.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  const handlePostReadingList = useCallback(
    (name: string, description: string) => {
      if (!user) return;
      dispatch(postReadingList({ name, description }));
    },
    [user],
  );
  return { handlePostReadingList, loading, error };
}

export function usePutReadingList(uuid: string) {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.panelResponses.loading,
  );
  const error = useSelector((state: RootState) => state.panelResponses.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  const handlePutReadingList = useCallback(
    (name: string, description: string) => {
      if (!user) return;
      dispatch(putReadingList({ uuid, name, description }));
    },
    [user],
  );
  return { handlePutReadingList, loading, error };
}

export function useDeleteReadingList(uuid: string) {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.panelResponses.loading,
  );
  const error = useSelector((state: RootState) => state.panelResponses.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  const handleDeleteReadingList = useCallback(() => {
    if (!user) return;
    dispatch(deleteReadingList({ uuid }));
  }, [user]);
  return { handleDeleteReadingList, loading, error };
}

export const useGetRecentlyReadStories = (current?: string) => {
  const recentlyReadStories = useSelector(
    (state: RootState) => state.readingLists.recentlyReadStories,
  );
  const recentlyRead = useSelector((state: RootState) =>
    selectAllRecentlyRead(state.readingLists),
  )
    .filter((entry) => entry.id !== current)
    .sort((a, b) => {
      if (new Date(a.read).getTime() < new Date(b.read).getTime()) return 1;
      return -1;
    });
  return recentlyRead.map((entry) => {
    return recentlyReadStories.entities[entry.id];
  });
};
