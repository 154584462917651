import React from 'react';
import styled from '@emotion/styled';
import {
  BaseTheme,
  Box,
  dim,
  FeaturedArtInfo,
  Header2,
  isThemePalette,
} from '@astc/frontend-components';
import { down } from 'styled-breakpoints';
import { SourcedImage } from '@/components/media/SourcedImage';
import { FeaturedProps, FullCategory, FullCountry } from '@/types';
import { FeaturedStories } from '@/components/FeaturedStories';
import { navigate } from 'gatsby';
import { PageBackground } from '@/components/PageBackground';

const StyledFeatured = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${dim(64)};
  background-color: ${({ theme }) => (theme as BaseTheme).colors.grays['10']};
  ${down('mobile')} {
    padding-bottom: 0;
    margin-bottom: ${dim(48)};
  }
`;

const FeaturedBackground = styled(PageBackground)`
  margin-top: ${dim(-325)};
  padding-top: ${dim(325)};
  background-color: unset;
  height: unset !important;
`;

const FeaturedHeader = styled(Header2)`
  width: 100%;
  box-sizing: border-box;
  line-height: 1;
  max-width: ${dim(1440)};
  padding: ${dim(145)} ${dim(76)} ${dim(172)};
  margin: 0 auto ${dim(8)};
  ${down('mobile')} {
    max-width: ${dim(1080)};
    padding-left: ${dim(12)};
  }
`;

const FeaturedContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dim(1440)};
  padding: 0 ${dim(104)};
  margin: 0 auto ${dim(300)};
  ${down('mobile')} {
    padding: 0;
    margin: 0 auto ${dim(120)};
  }
`;

const FeaturedStory = styled(Box)<{ alignSelf: 'flex-start' | 'flex-end' }>`
  margin: 0 ${dim(112)} 0;
  align-self: ${(props) => props.alignSelf};
  ${down('mobile')} {
    padding-left: ${dim(24)};
    padding-right: ${dim(24)};
    margin: ${dim(142)} auto 0;
  }
`;

const getAlignment = (index: number) =>
  index % 2 === 0 ? 'flex-start' : 'flex-end';

export const Featured: React.FC<FeaturedProps> = ({ featured }) => {
  return (
    <StyledFeatured>
      <FeaturedBackground
        desktop={featured.backgroundDesktop}
        mobile={featured.backgroundMobile}
      >
        <FeaturedHeader>{featured.title}</FeaturedHeader>
        <FeaturedContent>
          {featured.stories?.map((story, index) => (
            <FeaturedStory alignSelf={getAlignment(index)}>
              <SourcedImage source={story?.featuredArt} />
              <FeaturedArtInfo
                countries={
                  !!story?.country
                    ? [story.country].filter(
                        (country): country is FullCountry => !!country?.name,
                      )
                    : []
                }
                genres={
                  story?.categories?.filter(
                    (category): category is FullCategory => !!category?.name,
                  ) || []
                }
                buttonPalette={
                  story && isThemePalette(story.featuredArtButtonTheme)
                    ? story.featuredArtButtonTheme
                    : 'blue'
                }
                captionColor={story?.featuredArtCaptionColor?.hex || '#000'}
                onButtonClick={() => {
                  navigate(`/story/${story?._id || ''}`);
                }}
                name={
                  story?.creators
                    ?.map((creator) => {
                      return creator?.creator?.name || '';
                    })
                    .join(', ') || ''
                }
              />
            </FeaturedStory>
          ))}
        </FeaturedContent>
        {featured.featured && (
          <FeaturedStories
            stories={featured.featured.stories}
            title={featured.featured.title}
            subtitle={featured.featured.subtitle}
            size={featured.featured.size}
          />
        )}
      </FeaturedBackground>
    </StyledFeatured>
  );
};
