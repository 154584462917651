import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { populateRecentlyReadStories } from '@/app/features/readingLists/slice';

export const RecentlyReadStoriesDataProvider: React.FC = () => {
  const dispatch = useDispatch();
  const data = useStaticQuery(graphql`
    query RecentlyReadStoriesQuery {
      allSanityStory {
        nodes {
          _id
          title
          thumbnail {
            asset {
              gatsbyImageData(
                layout: FIXED
                placeholder: BLURRED
                width: 100
                height: 100
              )
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    dispatch(populateRecentlyReadStories(data.allSanityStory.nodes));
  }, [data]);
  return null;
};
