import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useEffect } from 'react';
import { fetchEmailUser, fetchUser } from '@/app/features/user/thunks';

export function useFetchUser(token?: string | null) {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.user.loading);
  const error = useSelector((state: RootState) => state.user.error);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (loading === 'idle' && token) {
      dispatch(fetchUser(token));
    }
  }, [loading, token]);
  return { loading, error, user };
}

export function useFetchEmailUser(
  email?: string | null,
  password?: string | null,
) {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.user.loading);
  const error = useSelector((state: RootState) => state.user.error);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (loading === 'idle' && email && password) {
      dispatch(fetchEmailUser({ email, password }));
    }
  }, [loading, email, password]);
  return { loading, error, user };
}

export function useFetchEmailSignUpUser(
  email?: string | null,
  password?: string | null,
) {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.user.loading);
  const error = useSelector((state: RootState) => state.user.error);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (loading === 'idle' && email && password) {
      dispatch(fetchEmailUser({ email, password }));
    }
  }, [loading, email, password]);
  return { loading, error, user };
}
