import { createAsyncThunk } from '@reduxjs/toolkit';
import { isPlainObject, Feedback } from '@/types';
import { RootState } from '@/app/store';
import fetch from 'isomorphic-fetch';
import { postAuthedData } from '@/app/readerDataClient';

export type FetchFeedbackResponseData = {
  uuid: string;
  body: string;
  target: string;
  type: string;
}[];

export const isFetchFeedbackResponseData = (
  data: unknown,
): data is FetchFeedbackResponseData => {
  if (!Array.isArray(data)) {
    return false;
  }
  return data.every(
    (item) =>
      isPlainObject(item) &&
      item.hasOwnProperty(`body`) &&
      typeof item.body === `string` &&
      item.hasOwnProperty(`target`) &&
      typeof item.target === `string` &&
      item.hasOwnProperty(`type`) &&
      typeof item.type === `string`,
  );
};

export type PostFeedbackResponseData = {
  uuid: string;
  body: string;
  target: string;
  type: string;
};

export const isPostFeedbackResponseData = (
  data: unknown,
): data is PostFeedbackResponseData => {
  return (
    isPlainObject(data) &&
    data.hasOwnProperty(`body`) &&
    typeof data.body === `string` &&
    data.hasOwnProperty(`target`) &&
    typeof data.target === `string` &&
    data.hasOwnProperty(`type`) &&
    typeof data.type === `string`
  );
};

export const fetchFeedbacks = createAsyncThunk<
  Feedback[],
  void,
  { state: RootState; rejectValue: string }
>('feedback/fetchFeedbacks', async (_, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) thunkAPI.rejectWithValue('No token found');
  const response = await fetch(
    `${process.env.GATSBY_READER_DATA_API_URL}/feedbacks`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to fetch panel responses: ${response.statusText}`,
    );
  }
  const responseData = await response.json();
  if (!isFetchFeedbackResponseData(responseData)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return responseData.map((item) => ({
    ...item,
  }));
});

export const postFeedback = createAsyncThunk<
  Feedback,
  Omit<Feedback, 'uuid'>,
  { state: RootState; rejectValue: string }
>('feedback/postFeedback', async ({ body, target, type }, thunkAPI) => {
  const token = thunkAPI.getState().user.user.jwt;
  if (!token) return thunkAPI.rejectWithValue('No token found');

  const response = await postAuthedData(
    '/feedbacks',
    { body, target, type },
    token,
  );

  if (!response.ok) {
    return thunkAPI.rejectWithValue(
      `Failed to feedback: ${response.statusText}`,
    );
  }
  const data = await response.json();
  if (!isPostFeedbackResponseData(data)) {
    return thunkAPI.rejectWithValue('Invalid response data');
  }
  return {
    ...data,
  };
});
