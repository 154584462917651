import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BadgeReaderResult,
  BadgeResult,
  BadgesState,
  BadgeStoryResult,
  RawBadge,
} from '@/types';
import {
  fetchBadgeReaders,
  postBadgeReader,
} from '@/app/features/badges/thunks';
import {
  constructBadgeUpdate,
  convertBadgeReaderToBadgeReaderResult,
  convertRawBadgeToBadgeResult,
  convertRawBadgeToBadgeStoryResult,
} from '@/app/features/badges/utils';
import {
  badgeReadersAdapter,
  badgesAdapter,
  badgeStoriesAdapter,
  initialState,
} from '@/app/features/badges/initialState';
import { logOut } from '@/app/features/user/slice';

const slice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    populateBadges: (state, action: PayloadAction<RawBadge[]>) => {
      // TODO unify the schema and get rid of the raw badge distinction
      const badgesResult = action.payload
        .map<BadgeResult[]>(convertRawBadgeToBadgeResult)
        .flat();
      badgesAdapter.upsertMany(state.badges, badgesResult);
      const badgeStoriesResult = action.payload.map<BadgeStoryResult>(
        convertRawBadgeToBadgeStoryResult,
      );
      badgeStoriesAdapter.upsertMany(state.badgeStories, badgeStoriesResult);
    },
    resetBadgeReaders: (state) => {
      state.loading = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBadgeReaders.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchBadgeReaders.fulfilled, (state, action) => {
        badgeReadersAdapter.upsertMany(
          state.badgeReaders,
          Object.values(action.payload).map<BadgeReaderResult>(
            convertBadgeReaderToBadgeReaderResult,
          ),
        );
        badgesAdapter.updateMany(
          state.badges,
          Object.values(action.payload)
            .map(constructBadgeUpdate(state.badges))
            .flat(),
        );
        state.loading = 'succeeded';
      })
      .addCase(postBadgeReader.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postBadgeReader.fulfilled, (state, action) => {
        badgeReadersAdapter.upsertMany(
          state.badgeReaders,
          Object.values([action.payload]).map<BadgeReaderResult>(
            convertBadgeReaderToBadgeReaderResult,
          ),
        );
        badgesAdapter.updateMany(
          state.badges,
          Object.values([action.payload])
            .map(constructBadgeUpdate(state.badges))
            .flat(),
        );
        state.loading = 'succeeded';
      })
      .addCase(logOut, (state) => {
        // this is wild and testament to the goodness of RTK
        // we can use an action creator from another slice to affect state
        // in this slice. Good video on this: https://www.youtube.com/watch?v=oEEXhHy_i4I
        state.badgeReaders = initialState.badgeReaders;
      });
  },
});

export const {
  selectIds: selectBadgeIds,
  selectById: selectBadgeById,
  selectEntities: selectBadges,
  selectAll: selectAllBadges,
  selectTotal: selectBadgesTotal,
} = badgesAdapter.getSelectors((state: BadgesState) => state.badges);

export const {
  selectIds: selectBadgeReaderIds,
  selectById: selectBadgeReaderById,
  selectEntities: selectBadgeReaders,
  selectAll: selectAllBadgeReaders,
  selectTotal: selectBadgeReadersTotal,
} = badgeReadersAdapter.getSelectors(
  (state: BadgesState) => state.badgeReaders,
);

export const {
  selectIds: selectBadgeStoryIds,
  selectById: selectBadgeStoryById,
  selectEntities: selectBadgeStories,
  selectAll: selectAllBadgeStories,
  selectTotal: selectBadgeStoriesTotal,
} = badgeStoriesAdapter.getSelectors(
  (state: BadgesState) => state.badgeStories,
);

export const { populateBadges, resetBadgeReaders } = slice.actions;

export default slice.reducer;
