import { BadgeResult } from '@/types';
import { BadgeThumbnail, Box } from '@astc/frontend-components';
import { SourcedImage } from '@/components/media/SourcedImage';
import { SanityImage } from '../../graphql-types';

export const generateBadgeThumbnails = (
  badgeResults: BadgeResult[],
  onBadgeClick: (badge: BadgeResult) => void,
) => {
  return badgeResults.map((badge) => {
    if (!badge) return null;

    return (
      <Box
        onClick={() => {
          onBadgeClick(badge);
        }}
        key={badge.name}
      >
        <BadgeThumbnail unlocked={badge.unlocked}>
          <SourcedImage
            source={badge.images.thumbnail as SanityImage}
            alt={badge.name}
          />
        </BadgeThumbnail>
      </Box>
    );
  });
};
