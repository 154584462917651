import React from 'react';
import { graphql } from 'gatsby';
import { StoryProps } from '@/types';
import { StoryPage } from '@/components/story/StoryPage';
import { StoryContext } from '@/contexts/StoryContext';
import { Seo } from '@/components/Seo';
import { convertSanityBlockToPlainText } from '@/utils/sanity';

export const Story: React.FC<StoryProps> = ({
  pageContext: {
    _id: id,
    title,
    genres = [],
    creators = [],
    medium,
    location,
    characters,
    countries = [],
    description,
    panels,
    images,
    badges,
    recommended,
  },
}) => {
  return (
    <StoryContext.Provider value={{ storyId: id }}>
      <Seo
        image={images.thumbnail?.seo?.asset?.gatsbyImageData}
        title={title}
        description={
          typeof description === 'string'
            ? description
            : convertSanityBlockToPlainText(description)
        }
      />
      <StoryPage
        genres={genres}
        creators={creators}
        characters={characters}
        location={location}
        medium={medium}
        countries={countries}
        title={title}
        description={description}
        panels={panels}
        images={images}
        badges={badges}
        recommended={recommended}
      />
    </StoryContext.Provider>
  );
};

// noinspection JSUnusedGlobalSymbols
/**
 * This query doesn't actually make the call, it's only here for type generation.
 */
export const allStoryQuery = graphql`
  query AllSanityStoryPage {
    allSanityStory {
      nodes {
        _id
        title
        creators {
          _key
          role
          creator {
            name
            location
            image {
              asset {
                gatsbyImageData
              }
            }
            bio {
              _key
              children {
                text
                marks
                _type
                _key
              }
              style
              _type
            }
          }
        }
        characters {
          _key
          name
          bio
          image {
            asset {
              gatsbyImageData(
                layout: FIXED
                placeholder: BLURRED
                width: 224
                height: 200
              )
            }
          }
          age
          location
        }
        medium {
          name
          date
          description
          image {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 224)
            }
          }
        }
        location {
          name
          date
          description
          image {
            asset {
              gatsbyImageData(
                layout: FIXED
                placeholder: BLURRED
                width: 224
                height: 200
              )
            }
          }
        }
        description {
          _key
          children {
            text
            marks
            _type
            _key
          }
          style
          _type
        }
        country {
          _key
          name
        }
        categories {
          _key
          name
        }
        badges {
          _id
          _key
          name
          trigger
          image {
            asset {
              gatsbyImageData(
                layout: FIXED
                placeholder: DOMINANT_COLOR
                width: 10
              )
            }
          }
        }
        recommended {
          authors
          tags
          title
          url
          _key
          image {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 158)
            }
          }
        }
        panels {
          ...ASTCPanelImage
          ...ASTCPanelAnimation
          ...ASTCPanelComparison
          ...ASTCPanelDrawing
          ...ASTCPanelHotspot
          ...ASTCPanelImmersiveText
          ...ASTCPanelMatching
          ...ASTCPanelMultipleChoice
          ...ASTCPanelShortResponse
          ...ASTCPanelTimeline
        }
        thumbnail {
          asset {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              width: 100
              height: 100
            )
          }
        }
        seo: thumbnail {
          asset {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              width: 1200
              height: 1200
            )
          }
        }
        layeredThumbnailsDesktop: layeredThumbnails {
          midLayer {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 205)
            }
          }
          upperLayer {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 205)
            }
          }
          alt
          lowerLayer {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 205)
            }
          }
          type
        }
        layeredThumbnailsMobile: layeredThumbnails {
          midLayer {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 120)
            }
          }
          upperLayer {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 120)
            }
          }
          alt
          lowerLayer {
            asset {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 120)
            }
          }
          type
        }
        heroBannerDesktop: imageHorizontal {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 1440, height: 600)
          }
        }
        heroBannerMobile: imageHorizontal {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 1440, height: 450)
          }
        }
      }
    }
  }
`;

export default Story;
