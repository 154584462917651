import { graphql, PageProps } from 'gatsby';
import React from 'react';
import {
  BaseTheme,
  Box,
  dim,
  Header2,
  Header4,
  Logo,
  Text,
} from '@astc/frontend-components';
import { SourcedImage } from '@/components/media/SourcedImage';
import styled from '@emotion/styled';
import {
  SanityPageAboutQuery,
  SanityPagesAboutContact,
  SanityPagesAboutIntro,
} from '../../graphql-types';
import { isSanityHeaderOrBody, isSanityImage } from '@/utils/sanity';

type AboutProps = { data: SanityPageAboutQuery } & PageProps;

const StyledAboutPage = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dim(956)};
  padding: 0 ${({ theme }) => (theme as BaseTheme).space[3]};
`;

const AboutPageHeader = styled(Header2)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
  margin-bottom: ${({ theme }) => (theme as BaseTheme).space[4]};
`;

const AboutPage: React.FC<AboutProps> = ({ data }) => {
  const { sanityPageAbout } = data;

  if (!sanityPageAbout) return null;

  const { intro, contact } = sanityPageAbout;

  if (!intro) return null;
  if (!contact) return null;

  const { elements } = intro as SanityPagesAboutIntro;

  const { contactBlocks } = contact as SanityPagesAboutContact;

  return (
    <StyledAboutPage>
      <AboutPageHeader>
        About
        <Logo width={dim(68)} />
      </AboutPageHeader>
      {elements &&
        elements.map((element) => {
          if (isSanityHeaderOrBody(element)) {
            return element._type === 'header' ? (
              <Header4 key={element._key} mb="3">
                {element.text}
              </Header4>
            ) : (
              <Box key={element._key} mb="3">
                {element.text?.split('\n').map((para) => (
                  <Text key={para.substring(0, 15)} mb="2">
                    {para}
                  </Text>
                ))}
              </Box>
            );
          }
          if (isSanityImage(element)) {
            return (
              <Box mb="4">
                <SourcedImage key={element._key} source={element} />
              </Box>
            );
          }
          return null;
        })}
      {contactBlocks &&
        contactBlocks.map((block) => {
          if (!block) return null;
          return (
            <Box key={block._key} mb="4">
              <Text>
                <a
                  href={`mailto:${block.email}`}
                  style={{ textDecoration: 'none' }}
                >
                  {block.email}
                </a>
              </Text>
              <Text>{block.purpose}</Text>
            </Box>
          );
        })}
    </StyledAboutPage>
  );
};

export const query = graphql`
  query SanityPageAbout {
    sanityPageAbout {
      intro {
        elements {
          ... on SanityBody {
            _key
            _type
            text
          }
          ... on SanityHeader {
            _key
            _type
            text
          }
          ... on SanityImage {
            _key
            _type
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 956
              )
            }
          }
        }
      }
      contact {
        contactBlocks {
          _key
          email
          purpose
        }
      }
    }
  }
`;
export default AboutPage;
