import { createAsyncThunk } from '@reduxjs/toolkit';
import { BadgeReader, isPlainObject } from '@/types';
import { RootState } from '@/app/store';
import fetch from 'isomorphic-fetch';
import { postAuthedData } from '@/app/readerDataClient';

export type FetchReaderBadgesResponseData = { badge_id: string }[];

export const isFetchReaderBadgesResponseData = (
  data: unknown,
): data is FetchReaderBadgesResponseData => {
  if (!Array.isArray(data)) {
    return false;
  }
  return data.every(
    (item) =>
      isPlainObject(item) &&
      item.hasOwnProperty(`badge_id`) &&
      typeof item[`badge_id`] === `string`,
  );
};

export type PostReaderBadgeResponseData = { badge_id: string };

export const isPostReaderBadgeResponseData = (
  data: unknown,
): data is PostReaderBadgeResponseData => {
  return (
    isPlainObject(data) &&
    data.hasOwnProperty(`badge_id`) &&
    typeof data[`badge_id`] === `string`
  );
};

async function checkResponseData<ResponseData>(
  guard: (data: unknown) => data is ResponseData,
  response: Response,
  uuid?: string,
) {
  if (!response.ok) {
    throw Error(
      `Failed to fetch or post badge readers: ${response.statusText}`,
    );
  }
  const data = await response.json();
  if (!guard(data)) {
    throw Error('Invalid response data');
  }
  const readerId = uuid;
  if (!readerId) {
    throw Error('No user uuid found');
  }
  return { data, readerId };
}

export const fetchBadgeReaders = createAsyncThunk<
  BadgeReader[],
  void,
  { state: RootState; rejectValue: Error }
>('badges/fetchBadgeReaders', async (_, { rejectWithValue, getState }) => {
  try {
    const { jwt, uuid } = getState().user.user;
    if (!jwt) rejectWithValue(Error('No token found'));
    const response = await fetch(
      `${process.env.GATSBY_READER_DATA_API_URL}/reader-badges`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      },
    );
    const { data, readerId } = await checkResponseData(
      isFetchReaderBadgesResponseData,
      response,
      uuid,
    );
    return data.map((item) => ({
      badgeId: item.badge_id,
      readerId,
    }));
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const postBadgeReader = createAsyncThunk<
  BadgeReader,
  { badgeId: string },
  { state: RootState; rejectValue: Error }
>(
  'badges/postBadgeReader',
  async ({ badgeId }, { rejectWithValue, getState }) => {
    try {
      const { jwt, uuid } = getState().user.user;
      if (!jwt) return rejectWithValue(Error('No token found'));

      const response = await postAuthedData(
        '/reader-badges',
        { badge_id: badgeId },
        jwt,
      );

      const { data, readerId } = await checkResponseData(
        isPostReaderBadgeResponseData,
        response,
        uuid,
      );

      return {
        badgeId: data.badge_id,
        readerId,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
