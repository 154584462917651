import {
  ReadingListResult,
  ReadingListsState,
  ReadingListStoryResult,
  RecentlyReadEntry,
  RecentlyReadStoryResult,
} from '@/types';
import { createEntityAdapter } from '@reduxjs/toolkit';

export const readingListsAdapter = createEntityAdapter<ReadingListResult>({});
export const readingListStoriesAdapter =
  createEntityAdapter<ReadingListStoryResult>({});
export const recentlyReadStoriesAdapter =
  createEntityAdapter<RecentlyReadStoryResult>({});
export const recentlyReadEntriesAdapter =
  createEntityAdapter<RecentlyReadEntry>({});
export const initialState: ReadingListsState = {
  readingLists: readingListsAdapter.getInitialState({}),
  readingListStories: readingListStoriesAdapter.getInitialState({}),
  recentlyReadStories: recentlyReadStoriesAdapter.getInitialState({}),
  recentlyRead: recentlyReadEntriesAdapter.getInitialState(),
  loading: 'idle',
  error: null,
};
