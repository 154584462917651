exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-badges-index-tsx": () => import("./../../../src/pages/badges/index.tsx" /* webpackChunkName: "component---src-pages-badges-index-tsx" */),
  "component---src-pages-behind-the-scenes-tsx": () => import("./../../../src/pages/behindTheScenes.tsx" /* webpackChunkName: "component---src-pages-behind-the-scenes-tsx" */),
  "component---src-pages-classroom-create-tsx": () => import("./../../../src/pages/classroom/create.tsx" /* webpackChunkName: "component---src-pages-classroom-create-tsx" */),
  "component---src-pages-collections-categories-tsx": () => import("./../../../src/pages/collections/categories.tsx" /* webpackChunkName: "component---src-pages-collections-categories-tsx" */),
  "component---src-pages-collections-index-tsx": () => import("./../../../src/pages/collections/index.tsx" /* webpackChunkName: "component---src-pages-collections-index-tsx" */),
  "component---src-pages-copyright-tsx": () => import("./../../../src/pages/copyright.tsx" /* webpackChunkName: "component---src-pages-copyright-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboard-forget-password-tsx": () => import("./../../../src/pages/onboard/forgetPassword.tsx" /* webpackChunkName: "component---src-pages-onboard-forget-password-tsx" */),
  "component---src-pages-onboard-reading-experience-tsx": () => import("./../../../src/pages/onboard/readingExperience.tsx" /* webpackChunkName: "component---src-pages-onboard-reading-experience-tsx" */),
  "component---src-pages-onboard-sign-in-tsx": () => import("./../../../src/pages/onboard/signIn.tsx" /* webpackChunkName: "component---src-pages-onboard-sign-in-tsx" */),
  "component---src-pages-onboard-sign-up-tsx": () => import("./../../../src/pages/onboard/signUp.tsx" /* webpackChunkName: "component---src-pages-onboard-sign-up-tsx" */),
  "component---src-pages-onboard-welcome-tsx": () => import("./../../../src/pages/onboard/welcome.tsx" /* webpackChunkName: "component---src-pages-onboard-welcome-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profile-classroom-tsx": () => import("./../../../src/pages/profile/classroom.tsx" /* webpackChunkName: "component---src-pages-profile-classroom-tsx" */),
  "component---src-pages-profile-delete-tsx": () => import("./../../../src/pages/profile/delete.tsx" /* webpackChunkName: "component---src-pages-profile-delete-tsx" */),
  "component---src-pages-profile-feedback-tsx": () => import("./../../../src/pages/profile/feedback.tsx" /* webpackChunkName: "component---src-pages-profile-feedback-tsx" */),
  "component---src-pages-profile-password-change-tsx": () => import("./../../../src/pages/profile/password/change.tsx" /* webpackChunkName: "component---src-pages-profile-password-change-tsx" */),
  "component---src-pages-profile-redirect-tsx": () => import("./../../../src/pages/profile/redirect.tsx" /* webpackChunkName: "component---src-pages-profile-redirect-tsx" */),
  "component---src-pages-profile-settings-tsx": () => import("./../../../src/pages/profile/settings.tsx" /* webpackChunkName: "component---src-pages-profile-settings-tsx" */),
  "component---src-pages-profile-view-tsx": () => import("./../../../src/pages/profile/view.tsx" /* webpackChunkName: "component---src-pages-profile-view-tsx" */),
  "component---src-pages-reading-list-[id]-tsx": () => import("./../../../src/pages/readingList/[id].tsx" /* webpackChunkName: "component---src-pages-reading-list-[id]-tsx" */),
  "component---src-pages-reading-lists-index-tsx": () => import("./../../../src/pages/readingLists/index.tsx" /* webpackChunkName: "component---src-pages-reading-lists-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */)
}

