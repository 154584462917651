import React from 'react';
import { SanityPanelDrawing } from '../../../../graphql-types';
import { DrawingAreaLine, DrawingPanel } from '@astc/frontend-components';
import { getSourcedImageData } from '@/components/media/SourcedImage';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { WithAliasedImages } from '@/types';
import { useGetStoryId } from '@/utils/hooks';
import { usePostPanelResponse } from '@/app/features/panelResponses/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectPanelResponseById } from '@/app/features/panelResponses/slice';

type SanityPanelDrawingAliasedImages = WithAliasedImages<SanityPanelDrawing>;

export const DrawingPanelContainer: React.FC<{
  panel: SanityPanelDrawingAliasedImages;
}> = ({ panel }) => {
  const storyId = useGetStoryId();
  const { handlePanelResponse, loading, error } = usePostPanelResponse(
    storyId,
    panel._key,
  );
  const panelResponse = useSelector((state: RootState) => {
    if (!panel._key) return;
    return selectPanelResponseById(state.panelResponses, panel._key);
  });

  const { mobile: isMobile } = useBreakpoint();

  const handleSubmit = (answer: DrawingAreaLine[]) => {
    handlePanelResponse(JSON.stringify(answer));
  };

  const image = isMobile ? panel.mobile : panel.desktop;

  return (
    <>
      {loading === 'pending' && <div>Saving...</div>}
      {error && <div>Error: {error}</div>}
      <DrawingPanel
        src={getSourcedImageData(image).images.fallback?.src || ''}
        width={getSourcedImageData(image).width}
        height={getSourcedImageData(image).height}
        alt={panel.caption || ''}
        onDraw={() => {}}
        onWarn={() => {}}
        cta={panel.caption || ''}
        question={panel.question || ''}
        lines={
          !!panelResponse?.panelResponse?.data &&
          Array.isArray(panelResponse?.panelResponse?.data)
            ? panelResponse?.panelResponse?.data
            : []
        }
        onSubmit={handleSubmit}
      >
        {panel.caption}
      </DrawingPanel>
    </>
  );
};
