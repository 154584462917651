import { LoadingState } from '@/types';
import { Box, Header4 } from '@astc/frontend-components';

export const PageLoading: React.FC<{
  loading: Exclude<LoadingState, 'succeeded'>;
  error: string | null;
}> = ({ loading, error }) => {
  if (error !== null) return <div>Error: {error}</div>;
  if (loading === 'failed') return <div>Unknown Error</div>;
  return (
    <Box mt="5">
      <Header4>Loading...</Header4>
    </Box>
  );
};
