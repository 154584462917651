import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import {
  Maybe,
  SanityImage,
  SanityImageAsset,
} from '../../../../graphql-types';
import styled from '@emotion/styled';
import { down, up } from 'styled-breakpoints';
import React from 'react';

type SourceImageProps = Omit<GatsbyImageProps, 'image' | 'alt'> & {
  source?: Maybe<
    SanityImage & {
      desktopAsset?: Maybe<SanityImageAsset>;
      mobileAsset?: Maybe<SanityImageAsset>;
    }
  >;
  desktopSource?: Maybe<
    SanityImage & {
      desktopAsset?: Maybe<SanityImageAsset>;
      mobileAsset?: Maybe<SanityImageAsset>;
    }
  >;
  mobileSource?: Maybe<
    SanityImage & {
      desktopAsset?: Maybe<SanityImageAsset>;
      mobileAsset?: Maybe<SanityImageAsset>;
    }
  >;
  alt?: Maybe<string>;
  size?: 'desktop' | 'mobile';
};

export const getSourcedImageData = (
  source?: Maybe<
    SanityImage & {
      desktopAsset?: Maybe<SanityImageAsset>;
      mobileAsset?: Maybe<SanityImageAsset>;
    }
  >,
  size?: 'desktop' | 'mobile',
  desktopSource?: Maybe<SanityImage>,
  mobileSource?: Maybe<SanityImage>,
): IGatsbyImageData => {
  if (source?.asset?.gatsbyImageData) {
    return source.asset.gatsbyImageData as IGatsbyImageData;
  }
  if (source?.desktopAsset?.gatsbyImageData && size === 'desktop') {
    return source.desktopAsset.gatsbyImageData as IGatsbyImageData;
  }
  if (source?.mobileAsset?.gatsbyImageData && size === 'mobile') {
    return source.mobileAsset.gatsbyImageData as IGatsbyImageData;
  }
  if (desktopSource?.asset?.gatsbyImageData && size === 'desktop') {
    return desktopSource.asset.gatsbyImageData as IGatsbyImageData;
  }
  if (mobileSource?.asset?.gatsbyImageData && size === 'mobile') {
    return mobileSource.asset.gatsbyImageData as IGatsbyImageData;
  }
  // these are not useful defaults, but this case should never happen
  return {
    backgroundColor: '',
    images: {
      sources: [],
      fallback: { src: '' },
    },
    layout: 'fixed',
    placeholder: undefined,
    width: 0,
    height: 0,
  };
};

const DesktopImage = styled(GatsbyImage)<GatsbyImageProps>`
  ${down('mobile')} {
    display: none;
  }
`;

const MobileImage = styled(GatsbyImage)<GatsbyImageProps>`
  ${up('mobile')} {
    display: none;
  }
`;

export const SourcedImage: React.FC<SourceImageProps> = (props) => {
  const {
    source,
    desktopSource,
    mobileSource,
    alt: _alt = '',
    size = 'mobile',
    ...rest
  } = props;
  const alt = _alt || ''; // needed to cast as string instead of Maybe<string>

  if (source?.asset?.gatsbyImageData) {
    return (
      <GatsbyImage alt={alt} image={source.asset.gatsbyImageData} {...rest} />
    );
  }

  if (source?.desktopAsset?.gatsbyImageData && size === 'desktop') {
    return (
      <DesktopImage
        alt={alt}
        image={source.desktopAsset.gatsbyImageData}
        {...rest}
      />
    );
  }

  if (source?.mobileAsset?.gatsbyImageData && size === 'mobile') {
    return (
      <MobileImage
        alt={alt}
        image={source.mobileAsset.gatsbyImageData}
        {...rest}
      />
    );
  }

  if (
    desktopSource?.asset?.gatsbyImageData &&
    mobileSource?.asset?.gatsbyImageData
  ) {
    return (
      <React.Fragment>
        <DesktopImage
          alt={alt}
          image={desktopSource.asset.gatsbyImageData}
          {...rest}
        />

        <MobileImage
          alt={alt}
          image={mobileSource.asset.gatsbyImageData}
          {...rest}
        />
      </React.Fragment>
    );
  }

  if (desktopSource?.asset?.gatsbyImageData) {
    return (
      <DesktopImage
        alt={alt}
        image={desktopSource.asset.gatsbyImageData}
        {...rest}
      />
    );
  }

  if (mobileSource?.asset?.gatsbyImageData) {
    return (
      <MobileImage
        alt={alt}
        image={mobileSource.asset.gatsbyImageData}
        {...rest}
      />
    );
  }

  return null;
};
