import {
  BadgeModal,
  BaseTheme,
  Box,
  CounterHeader,
  dim,
  ShareOverlay,
} from '@astc/frontend-components';
import React from 'react';
import { BadgeResult, BadgeStoryResult, LoadingState } from '@/types';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { generateBadgeThumbnails } from '@/utils/render';
import { PageLoading } from '@/components/PageLoading';
import { SourcedImage } from '@/components/media/SourcedImage';
import { SanityImage } from '../../../../../../graphql-types';
import { Dictionary } from '@reduxjs/toolkit';
import { redirectOnShareBadge } from '@/utils/share';
import { navigate } from 'gatsby';
import fetch from 'isomorphic-fetch';

export type BadgesPageProps = {
  badgeResults: BadgeResult[];
  badgeStories: Dictionary<BadgeStoryResult>;
  loading: LoadingState;
  error: string | null;
};

const StyledBadgesPage = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dim(664)};
  padding: 0 ${({ theme }) => (theme as BaseTheme).space[3]};
  ${down('mobile')} {
    max-width: ${dim(375)};
  }
`;

const BadgesPageGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: ${dim(24)};
  grid-row-gap: ${dim(24)};
  background: ${({ theme }) => (theme as BaseTheme).colors.white};
  box-shadow: 0 ${dim(4)} ${dim(4)} rgba(0, 0, 0, 0.25);
  border-radius: ${dim(8)};
  padding: ${({ theme }) => (theme as BaseTheme).space[4]};
  ${down('mobile')} {
    grid-template-columns: 1fr 1fr;
    box-shadow: none;
    background-color: transparent;
  }
`;

const isValidTrigger = (
  trigger: string | null,
): trigger is 'reading' | 'interactive' => {
  return trigger === 'reading' || trigger === 'interactive';
};

const fetchBadgeImage = async (name: string, path: string): Promise<void> => {
  return fetch(path, {
    method: 'GET',
  }).then((response) => {
    const filename = `${name.replace(' ', '_')}.png` || `badge.png`;

    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);

      // Create, append, then remove a temporary link element
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = filename;
      alink.id = 'downloadLink';
      alink.click();
      alink.remove();
    });
  });
};

export const BadgesPage: React.FC<BadgesPageProps> = ({
  badgeResults,
  badgeStories,
  loading,
  error,
}) => {
  const [displayBadgeOverlay, setDisplayBadgeOverlay] = React.useState(false);
  const [displayShareOverlay, setDisplayShareOverlay] = React.useState(false);
  const [selectedBadge, setSelectedBadge] = React.useState<BadgeResult | null>(
    null,
  );
  const onBadgeClick = (badge: BadgeResult) => {
    setDisplayBadgeOverlay(true);
    setSelectedBadge(badge);
  };
  if (loading === 'failed') return <div>Error: {error}!</div>;
  if (loading === 'pending')
    return <PageLoading loading={'pending'} error={null} />;
  return (
    <StyledBadgesPage>
      <CounterHeader
        title="Badges"
        amount={
          badgeResults.filter((badgeResult) => badgeResult.unlocked).length
        }
        unit="Unlocked"
        unitPlural="Unlocked"
        mt={[4, 5]}
        mb={[1, 3]}
      />
      <BadgesPageGrid>
        {generateBadgeThumbnails(badgeResults, onBadgeClick)}
      </BadgesPageGrid>
      {displayBadgeOverlay && selectedBadge && (
        <BadgeModal
          storyTitle={badgeStories[selectedBadge.stories?.[0]]?.title || ''}
          badgeThumbnail={
            <SourcedImage
              source={selectedBadge.images.thumbnail as SanityImage}
              alt={selectedBadge.name}
            />
          }
          storyThumbnail={
            <SourcedImage
              source={
                badgeStories[selectedBadge.stories?.[0]]
                  ?.thumbnail as SanityImage
              }
              alt={badgeStories[selectedBadge.stories?.[0]]?.title || ''}
            />
          }
          unlocked={selectedBadge.unlocked}
          trigger={
            isValidTrigger(selectedBadge.trigger)
              ? selectedBadge.trigger
              : 'reading'
          }
          onShare={() => {
            setDisplayShareOverlay(true);
            setDisplayBadgeOverlay(false);
          }}
          onDownload={() => {
            fetchBadgeImage(
              selectedBadge.name,
              (selectedBadge.images.thumbnail as SanityImage)?.asset
                ?.gatsbyImageData.images.fallback.src,
            ).then(() => setDisplayBadgeOverlay(false));
          }}
          onStoryClick={() => {
            navigate(`/story/${selectedBadge.stories?.[0]}`);
            setDisplayBadgeOverlay(false);
          }}
          onRequestClose={() => {
            setDisplayBadgeOverlay(false);
            setSelectedBadge(null);
          }}
        />
      )}
      {selectedBadge && displayShareOverlay && (
        <ShareOverlay
          title="Share"
          link={`I earned the ${selectedBadge.name} badge!`}
          onShare={redirectOnShareBadge(
            selectedBadge.name || '',
            `/story/${selectedBadge.stories?.[0]}` || '',
          )}
          onRequestClose={() => {
            setDisplayShareOverlay(false);
            setSelectedBadge(null);
          }}
        />
      )}
    </StyledBadgesPage>
  );
};
