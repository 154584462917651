import { createSlice } from '@reduxjs/toolkit';
import { initialState, feedbackAdapter } from './initialState';
import { FeedbackResult, FeedbackState } from '@/types';
import { fetchFeedbacks, postFeedback } from '@/app/features/feedbacks/thunks';
import { convertFeedbackToFeedbackResult } from '@/app/features/feedbacks/utils';
import { logOut } from '@/app/features/user/slice';

const slice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    resetFeedbacks: (state) => {
      feedbackAdapter.removeAll(state.feedbacks);
      state.loading = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbacks.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchFeedbacks.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(fetchFeedbacks.fulfilled, (state, action) => {
        feedbackAdapter.upsertMany(
          state.feedbacks,
          Object.values(action.payload).map<FeedbackResult>(
            convertFeedbackToFeedbackResult,
          ),
        );
        state.loading = 'succeeded';
      })
      .addCase(postFeedback.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postFeedback.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload ?? null;
      })
      .addCase(postFeedback.fulfilled, (state, action) => {
        feedbackAdapter.upsertMany(
          state.feedbacks,
          Object.values([action.payload]).map<FeedbackResult>(
            convertFeedbackToFeedbackResult,
          ),
        );
        state.loading = 'succeeded';
      })
      .addCase(logOut, (state) => {
        // this is wild and testament to the goodness of RTK
        // we can use an action creator from another slice to affect state
        // in this slice. Good video on this: https://www.youtube.com/watch?v=oEEXhHy_i4I
        state.feedbacks = initialState.feedbacks;
      });
  },
});

export const {
  selectIds: selectFeedbackIds,
  selectById: selectFeedbackById,
  selectEntities: selectFeedbackEntities,
  selectAll: selectAllFeedbacks,
  selectTotal: selectTotalFeedbacks,
} = feedbackAdapter.getSelectors((state: FeedbackState) => state.feedbacks);

export const { resetFeedbacks } = slice.actions;

export default slice.reducer;
