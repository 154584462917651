import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  Maybe,
  SanityImage,
  SanityPageEducatorResourcesQuery,
} from '../../graphql-types';
import {
  BaseTheme,
  Box,
  Button1,
  dim,
  Header7,
  Text,
} from '@astc/frontend-components';
import { isSanityHeaderOrBody, isSanityImage } from '@/utils/sanity';
import { SourcedImage } from '@/components/media/SourcedImage';
import styled from '@emotion/styled';
import { PageBackground } from '@/components/PageBackground';
import { down } from 'styled-breakpoints';

type EducatorResourcesProps = {
  data: SanityPageEducatorResourcesQuery;
} & PageProps;

export const StyledEducatorResourcesPage = styled(Box)`
  display: block;
  width: 100%;
  max-width: ${dim(1320)};
  padding: 0 ${({ theme }) => (theme as BaseTheme).space[3]};

  ${down('mobile')} {
    padding-top: ${dim(24)};
  }
`;

export const EducatorResourcesPageContent = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const EducatorResourcesPageElements = styled(Box)`
  position: relative;
  margin: 20% 0 ${dim(48)};
  width: 42%;
  ${down('mobile')} {
    width: 100%;
    margin: 125vw 0 ${dim(48)};
  }
  @media (min-width: 768px) and (max-width: 1079px) {
    margin: 0 0 ${dim(48)};
  }
`;

export const EducatorResourcesPageHeader = styled(Header7)`
  // assuming this is all non-AOA custom styling
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  color: #2e3455;

  ${down('mobile')} {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110.8%;
    letter-spacing: -0.05em;
    color: #2e3455;
  }
`;

export const EducatorResourcesPageText = styled(Text)`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #a9a9a9;

  ${down('mobile')} {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a9a9a9;
  }
`;

export const EducatorResourcesPage: React.FC<EducatorResourcesProps> = ({
  data,
}) => {
  const { sanityPageResources } = data;
  return (
    <PageBackground
      desktop={sanityPageResources?.backgroundDesktop as Maybe<SanityImage>}
      mobile={sanityPageResources?.backgroundMobile as Maybe<SanityImage>}
      style={{
        backgroundPositionX: 'right',
        backgroundPositionY: 'bottom',
      }}
    >
      <StyledEducatorResourcesPage>
        <PageBackground
          desktop={sanityPageResources?.foregroundDesktop as Maybe<SanityImage>}
          mobile={sanityPageResources?.foregroundMobile as Maybe<SanityImage>}
          style={{
            backgroundPositionX: 'right',
            backgroundPositionY: 'top',
            backgroundSize: 'contain',
            display: 'block',
          }}
          size="contain"
          maxWidth={dim(1320)}
        >
          <EducatorResourcesPageContent>
            <EducatorResourcesPageElements>
              {sanityPageResources?.content?.elements?.map((element) => {
                if (isSanityHeaderOrBody(element)) {
                  return element._type === 'header' ? (
                    <EducatorResourcesPageHeader key={element._key} mb="3">
                      {element.text}
                    </EducatorResourcesPageHeader>
                  ) : (
                    <Box key={element._key} mb="3">
                      {element.text?.split('\n').map((para) => (
                        <EducatorResourcesPageText
                          key={para.substring(0, 15)}
                          mb="2"
                        >
                          {para}
                        </EducatorResourcesPageText>
                      ))}
                    </Box>
                  );
                }
                if (isSanityImage(element)) {
                  return (
                    <Box mb="4">
                      <SourcedImage key={element._key} source={element} />
                    </Box>
                  );
                }
                return null;
              })}
            </EducatorResourcesPageElements>
            {sanityPageResources?.url && (
              <a
                href={sanityPageResources.url}
                style={{ textDecoration: 'none' }}
                target="_blank"
              >
                <Button1 palette="blue" variant="primary" size="large">
                  Join Asia in the Classroom
                </Button1>
              </a>
            )}
          </EducatorResourcesPageContent>
        </PageBackground>
      </StyledEducatorResourcesPage>
    </PageBackground>
  );
};

export const gql = graphql`
  query SanityPageEducatorResources {
    sanityPageResources {
      backgroundDesktop {
        asset {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 1440
          )
        }
      }
      foregroundDesktop {
        asset {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 1440
          )
        }
      }
      backgroundMobile {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 500)
        }
      }
      foregroundMobile {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 500)
        }
      }
      url
      content {
        elements {
          ... on SanityImage {
            _key
            _type
            asset {
              gatsbyImageData
            }
          }
          ... on SanityHeader {
            _key
            _type
            text
          }
          ... on SanityBody {
            _key
            _type
            text
          }
        }
      }
    }
  }
`;

export default EducatorResourcesPage;
