import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { populateReadingListStories } from '@/app/features/readingLists/slice';

export const ReadingListStoriesDataProvider: React.FC = () => {
  const dispatch = useDispatch();
  const data = useStaticQuery(graphql`
    query ReadingListStoriesQuery {
      allSanityStory {
        nodes {
          _id
          title
          country {
            name
          }
          categories {
            name
          }
          thumbnail {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 218
              )
            }
          }
          imageVertical {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 218
              )
            }
          }
          imageHorizontal {
            asset {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    dispatch(populateReadingListStories(data.allSanityStory.nodes));
  }, [data]);
  return null;
};
