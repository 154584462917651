import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import {
  ProfileData,
  ReadingExperience,
  FieldLabel,
  OptionLabel,
  Text,
  ProfileDataSettingsReadingExperience,
  StyledProfileSettings,
  Button1,
  BaseTheme,
  PageBoundary,
  Box,
  dim,
  Header4,
} from '@astc/frontend-components';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';

export type ProfileSettingsProps = Omit<
  ProfileData,
  'badges' | 'readingLists'
> & {
  changePasswordRoute: string;
  changePasswordText: string;
  feedbackRoute: string;
  feedbackText: string;
  helpRoute: string;
  helpText: string;
  deleteAccountRoute: string;
  deleteAccountText: string;
  accountType: 'google' | 'email';
  onReadingExperienceSelect(mode: ReadingExperience): void;
  onLogOut(): void;
};

export const ProfileSettingsTitle = styled(Header4)`
  color: ${({ theme }) => (theme as BaseTheme).colors.blues[60]};
  font-weight: normal;
`;

export const ProfileSettingsFieldLabel = styled(FieldLabel)`
  color: ${({ theme }) => (theme as BaseTheme).colors.grays[80]};
  margin-top: ${({ theme }) => (theme as BaseTheme).space[4]};
`;

export const ProfileSettingsOptionSet = styled(OptionLabel)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: ${({ theme }) => (theme as BaseTheme).space[2]};
`;

export const ProfileSettingsOptionLabel = styled(OptionLabel)`
  color: ${({ theme }) => (theme as BaseTheme).colors.grays[80]};
  font-size: ${dim(20)};
  margin: 0;
  ${down('mobile')} {
    font-size: ${({ theme }) => (theme as BaseTheme).fontSizes[2]};
  }
`;

export const ProfileSettingsFieldText = styled(Text)`
  color: ${({ theme }) => (theme as BaseTheme).colors.blues[50]};
  margin-top: ${({ theme }) => (theme as BaseTheme).space[1]};
  font-size: ${dim(20)};
`;

export const ProfileSettingsFieldLink = styled(Link)`
  color: ${({ theme }) => (theme as BaseTheme).colors.blues[50]};
  margin-top: ${({ theme }) => (theme as BaseTheme).space[1]};
  font-size: ${dim(20)};
  text-decoration: none;
`;

const ProfileSettingsActions = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => (theme as BaseTheme).space[4]};
`;

export const ProfileSettingsControl: React.FC<ProfileSettingsProps> = ({
  email,
  readingExperience,
  changePasswordRoute,
  changePasswordText,
  feedbackRoute,
  feedbackText,
  helpRoute,
  helpText,
  deleteAccountRoute,
  deleteAccountText,
  accountType,
  onReadingExperienceSelect,
  onLogOut,
}) => {
  // const { colors } = useTheme() as BaseTheme;

  const [readingExperienceValue, setReadingExperienceValue] =
    useState(readingExperience);

  const handleReadingExperienceInteractiveChange = useCallback(() => {
    setReadingExperienceValue(ReadingExperience.INTERACTIVE);
    onReadingExperienceSelect(ReadingExperience.INTERACTIVE);
  }, [onReadingExperienceSelect]);

  const handleReadingExperienceReadOnlyChange = useCallback(() => {
    setReadingExperienceValue(ReadingExperience.READ_ONLY);
    onReadingExperienceSelect(ReadingExperience.READ_ONLY);
  }, [onReadingExperienceSelect]);

  return (
    <PageBoundary pt="5">
      <Box display="flex" flexDirection="column" mt="3" alignItems="center">
        <StyledProfileSettings>
          <ProfileSettingsTitle>Settings</ProfileSettingsTitle>
          <ProfileSettingsFieldLabel>Email</ProfileSettingsFieldLabel>
          <ProfileSettingsFieldText>{email}</ProfileSettingsFieldText>
          {accountType !== 'google' && (
            <>
              <ProfileSettingsFieldLabel>Password</ProfileSettingsFieldLabel>
              <ProfileSettingsFieldText>
                <ProfileSettingsFieldLink to={changePasswordRoute}>
                  {changePasswordText}
                </ProfileSettingsFieldLink>
              </ProfileSettingsFieldText>
            </>
          )}
          <ProfileSettingsFieldLabel>
            Reading Experience
          </ProfileSettingsFieldLabel>
          <ProfileDataSettingsReadingExperience>
            <ProfileSettingsOptionSet>
              <input
                type="radio"
                id="interactive"
                name="readingExperience"
                checked={
                  readingExperienceValue === ReadingExperience.INTERACTIVE
                }
                onChange={handleReadingExperienceInteractiveChange}
              />
              <ProfileSettingsOptionLabel htmlFor="interactive">
                Learning Mode
              </ProfileSettingsOptionLabel>
            </ProfileSettingsOptionSet>
            <ProfileSettingsOptionSet>
              <input
                type="radio"
                id="read-only"
                name="readingExperience"
                checked={readingExperienceValue === ReadingExperience.READ_ONLY}
                onChange={handleReadingExperienceReadOnlyChange}
              />
              <ProfileSettingsOptionLabel htmlFor="read-only">
                Read-Only Mode
              </ProfileSettingsOptionLabel>
            </ProfileSettingsOptionSet>
          </ProfileDataSettingsReadingExperience>
          <ProfileSettingsFieldLabel>Feedback</ProfileSettingsFieldLabel>
          <ProfileSettingsFieldText>
            <ProfileSettingsFieldLink to={feedbackRoute}>
              {feedbackText}
            </ProfileSettingsFieldLink>
          </ProfileSettingsFieldText>
          <ProfileSettingsFieldLabel>Help</ProfileSettingsFieldLabel>
          <ProfileSettingsFieldText>
            <ProfileSettingsFieldLink to={helpRoute}>
              {helpText}
            </ProfileSettingsFieldLink>
          </ProfileSettingsFieldText>
          <ProfileSettingsFieldLabel>Delete Account</ProfileSettingsFieldLabel>
          <ProfileSettingsFieldText>
            <ProfileSettingsFieldLink to={deleteAccountRoute}>
              {deleteAccountText}
            </ProfileSettingsFieldLink>
          </ProfileSettingsFieldText>
          <ProfileSettingsActions>
            <Button1 palette="gray" variant="secondary" onClick={onLogOut}>
              Log Out
            </Button1>
          </ProfileSettingsActions>
        </StyledProfileSettings>
      </Box>
    </PageBoundary>
  );
};
