import { createEntityAdapter } from '@reduxjs/toolkit';
import { FeedbackState, FeedbackResult } from '@/types';

export const feedbackAdapter = createEntityAdapter<FeedbackResult>({
  selectId: (result) => `${result.feedback?.type}:${result.feedback?.target}`,
});
export const initialState: FeedbackState = {
  feedbacks: feedbackAdapter.getInitialState({}),
  loading: 'idle',
  error: null,
};
