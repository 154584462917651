import { BadgesPage } from '@/app/features/badges/components/BadgesPage';
import { useFetchBadgesWithBadgeReaders } from '@/app/features/badges/hooks';
import { useHasUser } from '@/utils/hooks';
// import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { BadgeResult } from '@/types';
import { navigate } from 'gatsby';
import { PageLoading } from '@/components/PageLoading';

interface BadgesPageContainerProps {}

const bspc: React.FC<BadgesPageContainerProps> = () => {
  const { badges, badgeReaders, badgeStories, loading, error } =
    useFetchBadgesWithBadgeReaders();
  const hasUser = useHasUser();

  useEffect(() => {
    if (hasUser === false) {
      navigate('/onboard/signIn');
    }
  }, [hasUser]);

  if (hasUser !== true) {
    return <PageLoading loading={'pending'} error={null} />;
  }

  const unlockedBadges =
    badges
      .filter((badgeResult): badgeResult is BadgeResult => !!badgeResult)
      .map((badgeResult: BadgeResult) => {
        return {
          ...badgeResult,
          unlocked: !!badgeReaders.find((badgeReader) => {
            return badgeReader.badgeReader?.badgeId === badgeResult.id;
          }),
        };
      }) || [];

  return (
    <BadgesPage
      badgeResults={unlockedBadges}
      badgeStories={badgeStories}
      loading={loading}
      error={error}
    />
  );
};

export default bspc;
