import { ShareTarget } from '@astc/frontend-components';

export const redirectOnShareStory =
  (title: string, description: string) => (target: ShareTarget) => () => {
    if (target === ShareTarget.FACEBOOK) {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href,
        '_blank',
      );
    }
    if (target === ShareTarget.TUMBLR) {
      window.open(
        'https://www.tumblr.com/widgets/share/tool?canonicalUrl=' +
          window.location.href,
        '_blank',
      );
    }
    if (target === ShareTarget.TWITTER) {
      window.open(
        `https://twitter.com/intent/tweet?text=${title}: ${description}&url=` +
          window.location.href,
        '_blank',
      );
    }
    if (target === ShareTarget.REDDIT) {
      window.open(
        'https://www.reddit.com/submit?url=' + window.location.href,
        '_blank',
      );
    }
    if (target === ShareTarget.URL) {
      window.open(
        `mailto:?subject=${title}&body=${window.location.href}`,
        '_blank',
      );
    }
  };

export const redirectOnShareBadge =
  (title: string, path: string) => (target: ShareTarget) => () => {
    if (target === ShareTarget.FACEBOOK) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?quote=I earned the ${title} badge!&u=${window.location.origin}${path}`,
        '_blank',
      );
    }
    if (target === ShareTarget.TUMBLR) {
      window.open(
        `https://www.tumblr.com/widgets/share/tool?caption=I earned the ${title} badge!&canonicalUrl=${window.location.origin}${path}`,
        '_blank',
      );
    }
    if (target === ShareTarget.TWITTER) {
      window.open(
        `https://twitter.com/intent/tweet?text=I earned the ${title} badge!&url=${window.location.origin}${path}`,
        '_blank',
      );
    }
    if (target === ShareTarget.REDDIT) {
      window.open(
        `https://www.reddit.com/submit?url=${window.location.origin}${path}&title=I earned the ${title} badge!`,
        '_blank',
      );
    }
    if (target === ShareTarget.URL) {
      window.open(
        `mailto:?subject=I earned the ${title} badge!&body=See the story at ${window.location.origin}${path}`,
        '_blank',
      );
    }
  };
