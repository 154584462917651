import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Box, dim } from '@astc/frontend-components';
import {
  Maybe,
  SanityCountry,
  SanityImage,
  SanityStory,
  StoryIndexByCountryQuery,
} from '../../../graphql-types';
import styled from '@emotion/styled';
import { CollectionHeader } from '@/components/collections/CollectionHeader';
import { CountryListSection } from '@/components/collections';
import { isPlainObject } from '@/types';

type CountryIndexPageProps = { data: StoryIndexByCountryQuery } & PageProps;

export const StyledIndexPage = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dim(1440)};
  padding: 0 0;
`;

type CompleteCountryStories = {
  stories: SanityStory[];
  alignment: string;
  alignmentMobile: string;
  titleOffset: number;
  storyOffset: number;
  country: SanityCountry;
  backgroundDesktop: Maybe<SanityImage>;
  backgroundMobile: Maybe<SanityImage>;
};

const hasStories = (
  countryStories: unknown,
): countryStories is CompleteCountryStories =>
  isPlainObject(countryStories) &&
  countryStories.hasOwnProperty('stories') &&
  !!countryStories.stories &&
  countryStories.hasOwnProperty('country') &&
  !!countryStories.country &&
  countryStories.hasOwnProperty('backgroundDesktop') &&
  countryStories.hasOwnProperty('backgroundMobile') &&
  isPlainObject(countryStories.country) &&
  countryStories.country.hasOwnProperty('name') &&
  Array.isArray(countryStories.stories) &&
  countryStories.stories.length > 0;

export const CountryIndexPage: React.FC<CountryIndexPageProps> = ({ data }) => {
  const { allSanityPageCountryList } = data;
  const countryStories = allSanityPageCountryList.nodes[0].countryStories;
  return (
    <StyledIndexPage>
      <CollectionHeader />
      {countryStories?.filter(hasStories).map((countryStory) => {
        return (
          <CountryListSection
            alignment={countryStory.alignment}
            alignmentMobile={countryStory.alignmentMobile}
            titleOffset={countryStory.titleOffset}
            storyOffset={countryStory.storyOffset}
            key={countryStory.country.name}
            country={countryStory.country}
            stories={countryStory.stories}
            backgroundDesktop={countryStory.backgroundDesktop}
            backgroundMobile={countryStory.backgroundMobile}
          />
        );
      })}
    </StyledIndexPage>
  );
};

export default CountryIndexPage;

export const gql = graphql`
  query StoryIndexByCountry {
    allSanityPageCountryList {
      nodes {
        countryStories {
          country {
            name
          }
          alignment
          alignmentMobile
          titleOffset
          storyOffset
          stories {
            title
            country {
              name
              id
            }
            categories {
              name
              id
            }
            creators {
              creator {
                name
                id
                bio {
                  ...SanityBlockFragment
                }
                image {
                  asset {
                    gatsbyImageData
                  }
                }
              }
            }
            _id
            imageVerticalDesktop: imageVertical {
              asset {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 400
                )
              }
            }
            imageVerticalMobile: imageVertical {
              asset {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 250
                )
              }
            }
            layeredThumbnailsDesktopHorizontal: layeredThumbnails {
              ...SanityVisualDesktopHorizontalLayeredThumbnailFragment
            }
            layeredThumbnailsMobileHorizontal: layeredThumbnails {
              ...SanityVisualMobileHorizontalLayeredThumbnailFragment
            }
            layeredThumbnailsDesktopVertical: layeredThumbnails {
              ...SanityVisualDesktopVerticalLayeredThumbnailFragment
            }
            layeredThumbnailsMobileVertical: layeredThumbnails {
              ...SanityVisualMobileVerticalLayeredThumbnailFragment
            }
          }
          backgroundDesktop {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 1440
              )
            }
          }
          backgroundMobile {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 412
              )
            }
          }
        }
      }
    }
  }
`;
