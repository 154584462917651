import { graphql, PageProps } from 'gatsby';
import React from 'react';
import {
  BaseTheme,
  Box,
  dim,
  Header2,
  Header4,
  Header6,
  Logo,
  HelpItem,
} from '@astc/frontend-components';
import styled from '@emotion/styled';
import { SanityHelpPageQuery } from '../../graphql-types';
import { isSanityHelpItem } from '@/utils/sanity';

type HelpProps = { data: SanityHelpPageQuery } & PageProps;

const StyledHelpPage = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${dim(956)};
  padding: 0 ${({ theme }) => (theme as BaseTheme).space[3]};
`;

const HelpPageHeader = styled(Header2)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
  margin-bottom: ${({ theme }) => (theme as BaseTheme).space[4]};
`;

const HelpPage: React.FC<HelpProps> = ({ data }) => {
  const { sanityPageHelp } = data;

  if (!sanityPageHelp) return null;

  const { intro, title, subtitle, helpItems } = sanityPageHelp;

  if (!intro) return null;
  if (!title) return null;
  if (!subtitle) return null;
  if (!helpItems) return null;

  return (
    <StyledHelpPage>
      <HelpPageHeader>
        {title}
        <Logo width={dim(68)} />
      </HelpPageHeader>
      <Header4>{subtitle}</Header4>
      <Box width={dim(400)} mt="3" mb="4" as="section">
        <Header6>{intro}</Header6>
      </Box>
      {helpItems &&
        helpItems?.map((item) => {
          if (isSanityHelpItem(item)) {
            const { _key, header, body } = item;
            return (
              <HelpItem
                key={_key}
                header={header || ''}
                body={body || []}
                onToggleExpand={() => {}}
              />
            );
          }
          return null;
        })}
    </StyledHelpPage>
  );
};

export const query = graphql`
  query SanityHelpPage {
    sanityPageHelp {
      title
      subtitle
      intro
      helpItems {
        _key
        header
        body {
          ...SanityBlockFragment
        }
      }
    }
  }
`;
export default HelpPage;
