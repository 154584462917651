import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useCallback, useEffect, useState } from 'react';
import {
  resetPanelResponses,
  selectAllPanelResponses,
  selectPanelResponseById,
} from '@/app/features/panelResponses/slice';
import {
  fetchPanelResponses,
  postPanelResponse,
} from '@/app/features/panelResponses/thunks';
import { resetBadgeReaders } from '@/app/features/badges/slice';
import { Maybe } from '../../../../graphql-types';
import { SimpleCourse } from '@/app/features/panelResponses/components/CreateAssignmentPage';
import { fetchAuthedData } from '@/app/readerDataClient';

export function useFetchPanelResponses() {
  const dispatch = useDispatch();
  const panelResponses = useSelector((state: RootState) => {
    return selectAllPanelResponses(state.panelResponses);
  });
  const loading = useSelector(
    (state: RootState) => state.panelResponses.loading,
  );
  const error = useSelector((state: RootState) => state.panelResponses.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    if (loading !== 'idle') {
      dispatch(resetPanelResponses());
    }
  }, []);
  useEffect(() => {
    if (user && loading === 'idle') {
      dispatch(fetchPanelResponses());
    }
  }, [user, loading]);
  return { panelResponses, loading, error };
}

export function useFetchPanelResponseById(id: string) {
  const dispatch = useDispatch();
  const panelResponse = useSelector((state: RootState) => {
    return selectPanelResponseById(state.panelResponses, id);
  });
  const loading = useSelector(
    (state: RootState) => state.panelResponses.loading,
  );
  const error = useSelector((state: RootState) => state.panelResponses.error);
  const user = useSelector((state: RootState) => !!state.user.user.uuid);
  useEffect(() => {
    if (user && loading === 'idle') {
      dispatch(fetchPanelResponses());
    }
  }, [user, loading]);
  return { panelResponse, loading, error };
}

export function usePostPanelResponse(storyId: string, panelId?: Maybe<string>) {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.panelResponses.loading,
  );
  const error = useSelector((state: RootState) => state.panelResponses.error);
  const handlePanelResponse = useCallback(
    (data: string) => {
      if (!panelId) return;

      dispatch(postPanelResponse({ storyId, panelId, data }));
      // reset the badge readers if they need to be read again for earned badges
      dispatch(resetBadgeReaders());
    },
    [storyId],
  );
  return { handlePanelResponse, loading, error };
}

export const useCreateAssignment = (
  token?: Maybe<string>,
  jwt?: Maybe<string>,
) => {
  const [submitted, setSubmitted] = useState(false);
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  const handleCreate = (
    course: SimpleCourse,
    title: string,
    description: string,
  ) => {
    if (!token || !jwt) {
      setError('You must be logged in to create an assignment');
      return;
    }
    fetch(
      `${process.env.GATSBY_READER_DATA_API_URL}/responses/courseWork?token=${token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: { course_id: course.id, title, description },
        }),
      },
    ).then(async (response) => {
      if (!response.ok) {
        // TODO handle the error
        if (response.status === 403)
          setError(
            `Unfortunately, you are not allowed to create an assignment.
                    You are either not a teacher or
                    you will need to check with your system administrator.`,
          );
        if (response.status !== 403)
          setError(
            `Unfortunately, an error occurred creating the assignment.
            Check with your system administrator.`,
          );
        setSubmitted(false);
        return;
      }
      const result = await response.json();
      if (result.error) {
        // TODO handle the error
        setError(result.error);
        setSubmitted(false);
        return;
      }
      setUrl(result.alternateLink);
      setSubmitted(false);
    });
  };

  if (!token || !jwt) {
    return {
      submitted,
      url,
      error,
      handleCreate: () => {
        setError('You must be logged in to create an assignment');
        return;
      },
    };
  }

  return { submitted, url, error, handleCreate };
};

export const useAvailableCourses = (
  token?: Maybe<string>,
  jwt?: Maybe<string>,
) => {
  const [courses, setCourses] = useState<SimpleCourse[]>([]);

  useEffect(() => {
    if (!token || !jwt) {
      return;
    }
    fetchAuthedData(`/responses/courses?token=${token}`, jwt)
      .then(async (response) => {
        if (!response.ok)
          throw new Error(`Backend error: ${response.statusText}`);
        setCourses(await response.json());
      })
      .catch((e) => {
        console.log('error', e);
      });
  }, [jwt, token]);

  return courses;
};
