import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

export type GatsbyImageDataType = {
  images: {
    fallback: {
      src: string;
    };
  };
  width: number;
  height: number;
};

export type SeoProps = {
  title?: string;
  description?: string;
  image?: GatsbyImageDataType;
};

export const Seo: React.FC<SeoProps> = (props) => {
  const { site, featuredImage } = useStaticQuery(graphql`
    query SeoMetaData {
      site {
        siteMetadata {
          title
          description
          siteUrl
          og {
            siteName
          }
        }
      }
      featuredImage: file(
        absolutePath: { glob: "**/src/images/featured-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  `);

  const ogImage =
    props.image ??
    (featuredImage?.childImageSharp?.gatsbyImageData as unknown as
      | GatsbyImageDataType
      | undefined);

  // determine title and description
  const title = props.title ?? site?.siteMetadata?.title;
  const description = props.description ?? site?.siteMetadata?.description;

  // Use the location hook to get current page URL
  const location = useLocation();

  // construct the meta array for passing into react helmet.
  const metas = [
    {
      name: 'description',
      content: description,
    },
    !!ogImage && {
      name: 'og:image',
      content: ogImage.images.fallback.src,
    },
    !!ogImage && {
      name: 'og:image:width',
      content: `${ogImage.width}`,
    },
    !!ogImage && {
      name: 'og:image:height',
      content: `${ogImage.height}`,
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:site_name',
      content: site!.siteMetadata!.og!.siteName,
    },
    {
      name: 'og:url',
      content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    !!ogImage && {
      name: 'twitter:image',
      content: ogImage.images.fallback.src,
    },
    !!ogImage && {
      name: 'twitter:image:alt',
      content: title,
    },
  ].filter((meta): meta is { name: string; content: string } => !!meta);

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{title}</title>
      {metas.map((meta) => (
        <meta key={meta.name} name={meta.name} content={meta.content} />
      ))}
    </Helmet>
  );
};
