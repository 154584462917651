import { GatsbyBrowser } from 'gatsby';
import { BaseTheme, Box, dim, GlobalStyle } from '@astc/frontend-components';
import ErrorBoundaryContainer from '@/components/ErrorBoundaryContainer';
import { PageHeaderContainer } from '@/components/PageHeaderContainer';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { Seo } from '@/components/Seo';
import { PageFooterContainer } from '@/components/PageFooterContainer';

const StyledFrontendPage = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  padding-top: ${dim(104)};
  background-color: ${({ theme }) => (theme as BaseTheme).colors.white};
  ${down('mobile')} {
    padding-top: ${dim(66)};
  }
`;

export const FrontendPage: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const isStory = props.location.pathname.includes('/story/');
  return (
    <ErrorBoundaryContainer>
      <GlobalStyle />
      <StyledFrontendPage>
        {!isStory && <Seo title={props.pageContext.title as string} />}
        <PageHeaderContainer />
        {element}
        <PageFooterContainer />
      </StyledFrontendPage>
    </ErrorBoundaryContainer>
  );
};
