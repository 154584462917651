import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  BadgeReaderResult,
  BadgeResult,
  BadgesState,
  BadgeStoryResult,
} from '@/types';

export const badgesAdapter = createEntityAdapter<BadgeResult>({});
export const badgeStoriesAdapter = createEntityAdapter<BadgeStoryResult>({});

export const badgeReadersAdapter = createEntityAdapter<BadgeReaderResult>({
  selectId: (badge) => badge.badgeReader?.badgeId ?? '0',
});
export const initialState: BadgesState = {
  badges: badgesAdapter.getInitialState({}),
  badgeStories: badgeStoriesAdapter.getInitialState({}),
  badgeReaders: badgeReadersAdapter.getInitialState({}),
  loading: 'idle',
  error: null,
};
