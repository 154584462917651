import {
  RawReadingList,
  RawReadingListStory,
  RawRecentlyReadStory,
  ReadingList,
  ReadingListResult,
  ReadingListStory,
  ReadingListStoryResult,
  RecentlyReadStoryResult,
} from '@/types';
import { Maybe, SanityImage } from '../../../../graphql-types';
import { Dictionary } from '@reduxjs/toolkit';

export const updateSuccessfulReadingLists = (
  allStories: Dictionary<ReadingListStoryResult>,
) => {
  return (readingList: ReadingList): ReadingListResult => {
    const stories = [
      ...readingList.storyIds
        .map((id) => {
          return allStories[id]?.story || allStories['drafts.' + id]?.story;
          // TODO: remove the drafts prefix when we have a real story id
        })
        .filter((s): s is ReadingListStory => s !== undefined),
    ];

    const updatedReadingList = {
      ...readingList,
      stories,
    };

    return {
      id: readingList.id,
      readingList: updatedReadingList,
      loading: 'succeeded',
      error: null,
    };
  };
};

export const convertRawReadingListToReadingList = (
  data: RawReadingList,
): ReadingList => {
  return {
    id: data.id,
    name: data.attributes.name,
    description: data.attributes.description,
    image: { src: 'foo' },
    storyIds: data.attributes.reading_list_stories.data.map(
      (story) => story.attributes.story_id,
    ),
    stories: [],
    owner: true,
    // stories: data.attributes.reading_list_stories.data.map(
    //   (story) => story.attributes.story_id,
    // ),
  };
};

export const convertRawReadingListStoryToReadingListStoryResult = (
  rawStory: RawReadingListStory,
): ReadingListStoryResult => {
  const { thumbnail: image, imageHorizontal, imageVertical } = rawStory;
  return {
    id: rawStory._id,
    rawStory,
    story: {
      id: rawStory._id,
      title: rawStory.title,
      tags: [
        rawStory.country?.name || '',
        ...(rawStory.categories || []).map((c) => c.name || ''),
      ],
      image,
      imageHorizontal,
      imageVertical,
    },
    loading: 'succeeded',
    error: null,
  };
};

export const convertRawRecentlyReadStoryToRecentlyReadStoryResult = (
  rawStory: RawRecentlyReadStory,
): RecentlyReadStoryResult => {
  const image = rawStory.thumbnail as Maybe<SanityImage>;
  return {
    id: rawStory._id,
    rawStory,
    story: {
      id: rawStory._id,
      title: rawStory.title,
      image,
    },
    loading: 'succeeded',
    error: null,
  };
};
