import { Maybe, SanityPanelImmersiveText } from '../../../../graphql-types';
import React from 'react';
import {
  ImmersiveTextArea,
  ImmersiveTextPanel,
} from '@astc/frontend-components';
import { getSourcedImageData } from '@/components/media/SourcedImage';
import { useGetStoryId } from '@/utils/hooks';
import { usePostPanelResponse } from '@/app/features/panelResponses/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectPanelResponseById } from '@/app/features/panelResponses/slice';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { WithAliasedImages } from '@/types';

// TODO: make this respond to more rich immersiveText data
export const parsePanelImmersiveTextData = (
  textArea?: Maybe<string>,
): ImmersiveTextArea => {
  return JSON.parse(textArea || '{}');
};

type SanityPanelImmersiveTextAliasedImages =
  WithAliasedImages<SanityPanelImmersiveText>;

export const ImmersiveTextPanelContainer: React.FC<{
  panel: SanityPanelImmersiveTextAliasedImages;
}> = ({ panel }) => {
  if (!panel._key) return null;
  const storyId = useGetStoryId();
  const { handlePanelResponse, loading, error } = usePostPanelResponse(
    storyId,
    panel._key,
  );
  const panelResponse = useSelector((state: RootState) => {
    if (!panel._key) return;
    return selectPanelResponseById(state.panelResponses, panel._key);
  });
  const { mobile: isMobile } = useBreakpoint();

  const handleSubmit = (answer: string) => {
    handlePanelResponse(JSON.stringify(answer));
  };

  return (
    <>
      {loading === 'pending' && <div>Saving...</div>}
      {error && <div>Error: {error}</div>}
      {isMobile ? (
        <ImmersiveTextPanel
          width={getSourcedImageData(panel.mobile).width}
          height={getSourcedImageData(panel.mobile).height}
          cta={panel.caption || ''}
          src={getSourcedImageData(panel.mobile).images.fallback?.src ?? ''}
          alt={panel.caption || ''}
          content={
            typeof panelResponse?.panelResponse?.data === 'string'
              ? panelResponse?.panelResponse?.data
              : ''
          }
          textArea={parsePanelImmersiveTextData(panel.immersiveText)}
          onSubmit={handleSubmit}
        />
      ) : (
        <ImmersiveTextPanel
          width={getSourcedImageData(panel.desktop).width}
          height={getSourcedImageData(panel.desktop).height}
          cta={'Immersive Text'}
          src={getSourcedImageData(panel.desktop).images.fallback?.src ?? ''}
          alt={panel.caption || ''}
          content={
            typeof panelResponse?.panelResponse?.data === 'string'
              ? panelResponse?.panelResponse?.data
              : ''
          }
          textArea={parsePanelImmersiveTextData(panel.immersiveText)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
