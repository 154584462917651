import { Maybe, SanityPanelHotspot } from '../../../../graphql-types';
import React, { useCallback, useEffect, useState } from 'react';
import { HotspotData, HotspotPanel } from '@astc/frontend-components';
import { getSourcedImageData } from '@/components/media/SourcedImage';
import { WithAliasedImages } from '@/types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// TODO: make this respond to more rich hotspot data
export const parsePanelHotspotData = (
  hotspot?: Maybe<string>,
): Record<number, HotspotData> => {
  const pointSets = JSON.parse(hotspot || '[]');
  return pointSets.reduce((acc: Record<number, any>, points: any) => {
    return {
      ...acc,
      [points.key]: {
        region: { lines: [points] },
        active: false,
        key: points.key,
        body: points.body,
        header: points.header,
      },
    };
  }, {});
};

type SanityPanelHotspotAliasedImages = WithAliasedImages<SanityPanelHotspot>;

export const HotspotPanelContainer: React.FC<{
  panel: SanityPanelHotspotAliasedImages;
}> = ({ panel }) => {
  const { mobile: isMobile } = useBreakpoint();
  //const [selectedHotspot, setSelectedHotspot] = useState<number | null>(null);
  const [hotspots, setHotspots] = useState<Record<number, HotspotData>>(
    panel.hotspot ? parsePanelHotspotData(panel.hotspot) : {},
  );
  useEffect(() => {
    setHotspots(panel.hotspot ? parsePanelHotspotData(panel.hotspot) : {});
  }, [panel.hotspot]);
  const handleHotspotToggled = useCallback(
    (key: number) => {
      console.log('handleHotspotToggled', key);
      console.table(hotspots);
      setHotspots((prevState) => {
        const hotspot = prevState[key];
        if (!hotspot) return prevState;
        return {
          ...prevState,
          [key]: {
            ...hotspot,
            active: !hotspot.active,
          },
        };
      });
    },
    [setHotspots],
  );

  return (
    <>
      {isMobile ? (
        <HotspotPanel
          width={getSourcedImageData(panel.mobile).width}
          height={getSourcedImageData(panel.mobile).height}
          cta={'Hotspots'}
          src={getSourcedImageData(panel.mobile).images.fallback?.src || ''}
          alt={panel.caption || ''}
          hotspots={hotspots}
          onHotspotToggled={handleHotspotToggled}
        />
      ) : (
        <HotspotPanel
          width={getSourcedImageData(panel.desktop).width}
          height={getSourcedImageData(panel.desktop).height}
          cta={'Hotspots'}
          src={getSourcedImageData(panel.desktop).images.fallback?.src || ''}
          alt={panel.caption || ''}
          hotspots={hotspots}
          onHotspotToggled={handleHotspotToggled}
        />
      )}
    </>
  );
};
