import React from 'react';
import { BaseTheme, Box, dim, Text } from '@astc/frontend-components';
import styled from '@emotion/styled';

const DividerText = styled(Text)`
  display: flex;
  color: ${({ theme }) => (theme as BaseTheme).colors.grays[30]};
  padding: 0 ${dim(16)};
`;
const DividerLine = styled.hr`
  width: ${dim(69)};
  height: 0;
  border: 0;
  border-bottom: 1px solid
    ${({ theme }) => (theme as BaseTheme).colors.grays[30]};
  z-index: -1;
`;
const _Divider: React.FC<{
  children: string;
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, className, style }) => {
  return (
    <Box
      className={className}
      style={{ display: 'flex', flexDirection: 'row', ...style }}
    >
      <DividerLine />
      <DividerText>{children}</DividerText>
      <DividerLine />
    </Box>
  );
};
export const Divider = styled(_Divider)`
  margin: ${dim(16)} auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${dim(269)};
`;
