import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Box } from '@astc/frontend-components';
import { SignInStep } from '@/app/features/user/components/SignInStep';
import { useHasUser } from '@/utils/hooks';
import { fetchEmailUser } from '@/app/features/user/thunks';
import { useDispatch } from 'react-redux';

interface SignInPageContainerProps {}

const sipc: React.FC<SignInPageContainerProps> = () => {
  const hasUser = useHasUser();
  const dispatch = useDispatch();

  // TODO: double check that useEffect is what we want to do
  useEffect(() => {
    if (hasUser !== true) {
      return;
    }
    navigate('/profile/view'); // TODO: should this be handled elsewhere?
    // TODO: this might benefit from an onboarding state machine
  }, [hasUser]);

  // TODO: handle this case
  const handleLogIn = async (email: string, password: string) => {
    dispatch(fetchEmailUser({ email, password }));
  };

  const handleGoogleClassroomSignIn = () => {
    if (!process.env.GATSBY_AUTH_CONNECT_URL) return;
    window.location.href = process.env.GATSBY_AUTH_CONNECT_URL;
  };

  return (
    <Box>
      <SignInStep
        title="Sign In"
        subtitle="Sign in or sign up to enjoy more features and collect unlockable content."
        body="Educators, be sure to use your professional email"
        forgetPasswordRoute="/onboard/forgetPassword"
        onLogIn={handleLogIn}
        onGoogleClassroomSignIn={handleGoogleClassroomSignIn}
      />
    </Box>
  );
};

export default sipc;
