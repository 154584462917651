import { Box, Header4, ReadingExperience } from '@astc/frontend-components';
import { LoadingState } from '@/types';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, updateMode } from '@/app/features/user/slice';
import { ProfileSettingsControl } from '@/app/features/user/components/ProfileSettingsControl';
import { RootState } from '@/app/store';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

interface ProfileSettingsPageContainerProps {}

const ProfileSettingsPageLoading: React.FC<{
  loading: Exclude<LoadingState, 'succeeded' | 'idle'>;
  error: string | null;
}> = ({ loading, error }) => {
  if (error !== null) return <div>Error: {error}</div>;
  if (loading === 'failed') return <div>Unknown Error</div>;
  return (
    <Box mt="5">
      <Header4>Loading...</Header4>
    </Box>
  );
};

const pspc: React.FC<ProfileSettingsPageContainerProps> = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.user.loading);
  const error = useSelector((state: RootState) => state.user.error);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (!user.uuid) {
      navigate('/onboard/signIn');
    }
  }, [user]);

  const onLogOut = () => {
    dispatch(logOut());
  };
  const onReadingExperienceSelect = (mode: ReadingExperience) => {
    dispatch(updateMode(mode));
  };

  if (!(loading === 'idle' || loading === 'succeeded')) {
    return <ProfileSettingsPageLoading loading={loading} error={error} />;
  }

  return (
    <ProfileSettingsControl
      email={user.email || ''}
      readingExperience={user.mode}
      accountType="google"
      changePasswordRoute={'/profile/password/change'}
      changePasswordText={'Do you want to change your password?'}
      deleteAccountRoute={'/profile/delete'}
      deleteAccountText={'Do you want to delete your account?'}
      feedbackRoute={'/profile/feedback'}
      feedbackText={'Help us create a better experience'}
      helpRoute={'/help'}
      helpText={'Do you need help using our site?'}
      onLogOut={onLogOut}
      onReadingExperienceSelect={onReadingExperienceSelect}
    />
  );
};

export default pspc;
