import { graphql, navigate, PageProps } from 'gatsby';
import { ReadingListsPage } from '@/app/features/readingLists/components/ReadingListsPage';
import { useFetchReadingLists } from '@/app/features/readingLists/hooks';
import {
  SanityPageReadingLists,
  SanityPageReadingListsQuery,
} from '../../../graphql-types';
import { PageBackground } from '@/components/PageBackground';
import { BaseTheme, dim } from '@astc/frontend-components';
import { resetPanelResponses } from '@/app/features/panelResponses/slice';
import styled from '@emotion/styled';
import { useHasUser } from '@/utils/hooks';
import { useEffect } from 'react';
import { PageLoading } from '@/components/PageLoading';
import { down } from 'styled-breakpoints';

type ReadingListsPageContainerProps = {
  data: SanityPageReadingListsQuery;
} & PageProps;

const ReadingListsPageBackground = styled(PageBackground)`
  background-color: ${({ theme }) => (theme as BaseTheme).colors.white};
  background-size: contain;
  ${down('mobile')} {
    border-radius: ${dim(8)};
  }
`;

const rlspc: React.FC<ReadingListsPageContainerProps> = ({ data }) => {
  const { sanityPageReadingLists } = data;
  const { backgroundDesktop, backgroundMobile } =
    sanityPageReadingLists as SanityPageReadingLists;
  resetPanelResponses();
  const { readingLists, loading, error } = useFetchReadingLists();

  const hasUser = useHasUser();

  useEffect(() => {
    if (hasUser === false) {
      navigate('/onboard/signIn');
    }
  }, [hasUser]);

  if (hasUser !== true) {
    return <PageLoading loading={'pending'} error={null} />;
  }

  return (
    <ReadingListsPageBackground
      desktop={backgroundDesktop}
      mobile={backgroundMobile}
      position={`center ${dim(157)}`}
    >
      <ReadingListsPage
        readingListResults={readingLists}
        onItemClick={(id) => async () => {
          await navigate(`/readingList/${id}`);
        }}
        loading={loading}
        error={error}
      />
    </ReadingListsPageBackground>
  );
};

export const query = graphql`
  query SanityPageReadingLists {
    sanityPageReadingLists {
      backgroundDesktop {
        asset {
          gatsbyImageData(width: 1440, layout: CONSTRAINED)
        }
      }
      backgroundMobile {
        asset {
          gatsbyImageData(width: 450, layout: CONSTRAINED)
        }
      }
    }
  }
`;

export default rlspc;
