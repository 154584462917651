import React, { useCallback, useState } from 'react';
import { SanityPanelTimeline } from '../../../../graphql-types';
import { TimelinePanel } from '@astc/frontend-components';
import {
  getSourcedImageData,
  SourcedImage,
} from '@/components/media/SourcedImage';
import { useGetStoryId } from '@/utils/hooks';
import { usePostPanelResponse } from '@/app/features/panelResponses/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectPanelResponseById } from '@/app/features/panelResponses/slice';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { isTextResponseData } from '@/types';

type SanityPanelTimelineAliasImages = Omit<
  SanityPanelTimeline & {
    desktopImages?: SanityPanelTimeline['images'];
    mobileImages?: SanityPanelTimeline['images'];
  },
  'images'
>;

export const TimelinePanelContainer: React.FC<{
  panel: SanityPanelTimelineAliasImages;
}> = ({ panel }) => {
  const [currentDesktopImage, setCurrentDesktopImage] = useState(
    panel.desktopImages?.[0],
  );
  const [currentMobileImage, setCurrentMobileImage] = useState(
    panel.mobileImages?.[0],
  );

  const storyId = useGetStoryId();
  const { handlePanelResponse, loading, error } = usePostPanelResponse(
    storyId,
    panel._key,
  );
  const panelResponse = useSelector((state: RootState) => {
    if (!panel._key) return;
    return selectPanelResponseById(state.panelResponses, panel._key);
  });
  const { mobile: isMobile } = useBreakpoint();

  const segment = 100 / (panel.desktopImages?.length || 1);

  const handleTimelineChange = useCallback(
    (value: number) => {
      const index = Math.floor(value / segment);
      setCurrentDesktopImage(panel.desktopImages?.[index]);
      setCurrentMobileImage(panel.mobileImages?.[index]);
    },
    [panel.desktopImages],
  );

  const handleSubmit = (answer: string) => {
    handlePanelResponse(JSON.stringify(answer));
  };

  return (
    <>
      {loading === 'pending' && <div>Saving...</div>}
      {error && <div>Error: {}</div>}
      {isMobile ? (
        <TimelinePanel
          key={'foo'}
          width={getSourcedImageData(currentMobileImage).width}
          height={getSourcedImageData(currentMobileImage).height}
          cta={panel.caption || ''}
          onSubmit={handleSubmit}
          question={panel.caption || ''}
          answer={
            isTextResponseData(panelResponse?.panelResponse?.data)
              ? panelResponse?.panelResponse?.data
              : ''
          }
          image={<SourcedImage source={currentMobileImage} />}
          onTimelineChange={handleTimelineChange}
        ></TimelinePanel>
      ) : (
        <TimelinePanel
          key={'foo'}
          width={getSourcedImageData(currentDesktopImage).width}
          height={getSourcedImageData(currentDesktopImage).height}
          cta={panel.caption || ''}
          onSubmit={handleSubmit}
          question={panel.caption || ''}
          image={<SourcedImage source={currentDesktopImage} />}
          onTimelineChange={handleTimelineChange}
        ></TimelinePanel>
      )}
    </>
  );
};
