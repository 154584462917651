import React, { useState } from 'react';
import {
  BaseTheme,
  Box,
  Button1,
  dim,
  Header4,
  Header5,
  PageBoundary,
  RoundedBox,
  Text,
  Textarea,
  TextInput,
} from '@astc/frontend-components';
import { PageBackground } from '@/components/PageBackground';
import styled from '@emotion/styled';
import { LoadingState } from '@/types';

export type SimpleCourse = { id: string; name: string };
export const isSimpleCourse = (course: unknown): course is SimpleCourse => {
  if (!course || typeof course !== 'object') {
    return false;
  }
  return course.hasOwnProperty('id') && course.hasOwnProperty('name');
};

export type SimpleCourseWork = { id: string; title: string };
export const isSimpleCourseWork = (cw: unknown): cw is SimpleCourseWork => {
  if (!cw || typeof cw !== 'object') {
    return false;
  }
  return cw.hasOwnProperty('id') && cw.hasOwnProperty('title');
};

const CreateAssignmentHeader = styled(Header4)`
  color: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
`;

const CreateAssignmentSubheader = styled(Header5)`
  font-weight: 400;
  color: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
`;

export const SelectInput = styled('select')`
  width: 100%;
  padding: 1em;
  margin: 0 0 0 ${({ theme }) => (theme as BaseTheme).space[2]};
  outline: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
  border: ${dim(3)} solid
    ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
  border-radius: ${({ theme }) => (theme as BaseTheme).space[3]};
  color: ${({ theme }) => (theme as BaseTheme).colors.blues['60']};
  font-family: ${({ theme }) => (theme as BaseTheme).fonts['body']};
  font-size: 1.2rem;
  filter: drop-shadow(-0.0625rem 0.125rem 0 #0047ff)
    drop-shadow(0 0.125rem 0.5rem rgba(0, 0, 0, 0.07))
    drop-shadow(0 0.125rem 0.625rem rgba(0, 0, 0, 0.08));
`;

export interface CreateAssignmentPageProps {
  courses: SimpleCourse[];
  loading: LoadingState;
  error: string | null;
  url: string;
  onCreate: (course: SimpleCourse, title: string, description: string) => void;
}

export const CreateAssignmentPage: React.FC<CreateAssignmentPageProps> = ({
  courses = [],
  loading,
  error,
  url,
  onCreate,
}) => {
  const [activeCourse, setActiveCourse] = useState<SimpleCourse | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleCourseSelect = (event: React.FormEvent<HTMLSelectElement>) => {
    const id = event.currentTarget.value;
    const course = courses?.find((course) => course.id === id);
    if (isSimpleCourse(course)) setActiveCourse(course);
  };

  const handleNameChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setTitle(ev.currentTarget.value);
  };

  const handleDescriptionChange = (
    ev: React.FormEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(ev.currentTarget.value);
  };

  const isDisabled = !isSimpleCourse(activeCourse) || !title || !description;

  const handleCreate = () => {
    if (isSimpleCourse(activeCourse))
      onCreate(activeCourse, title, description);
  };

  return (
    <PageBackground>
      <PageBoundary>
        <CreateAssignmentHeader mt="4">
          Create Assignment
        </CreateAssignmentHeader>
        {!url && loading !== 'failed' && (
          <Text>
            Create a google classroom assignment that will automatically be sent
            to your class to allow students to submit homework for it.
          </Text>
        )}
        {url && loading !== 'failed' && (
          <Text>
            Great! You can see your new assignment on Google Classroom by going{' '}
            <a href={url}>here</a>.
          </Text>
        )}
        {loading === 'pending' && <Text> Submitting... </Text>}
        {loading === 'failed' && <Text> {error} </Text>}
        {!url && (
          <RoundedBox>
            <Box mb="4">
              <CreateAssignmentSubheader>
                Select A Class For The Assignment
              </CreateAssignmentSubheader>
              <SelectInput onChange={handleCourseSelect}>
                <option>Select a class</option>
                {(courses ?? []).map((course) => (
                  <option value={course.id}>{course.name}</option>
                ))}
              </SelectInput>
            </Box>
            <Box mb="4">
              <CreateAssignmentSubheader>
                Assignment Name
              </CreateAssignmentSubheader>
              <TextInput
                variant="primary"
                palette={'blue'}
                value={title}
                onChange={handleNameChange}
                disabled={loading === 'pending'}
              />
            </Box>
            <Box mb="3">
              <CreateAssignmentSubheader>
                Assignment Description
              </CreateAssignmentSubheader>
              <Textarea
                value={description}
                onChange={handleDescriptionChange}
                disabled={loading === 'pending'}
              />
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mb="4">
              <Button1
                palette="blue"
                variant="secondary"
                onClick={handleCreate}
                disabled={isDisabled || loading === 'pending'}
              >
                Create Assignment
              </Button1>
            </Box>
          </RoundedBox>
        )}
      </PageBoundary>
    </PageBackground>
  );
};
