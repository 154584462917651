import {
  ReadingListHeader,
  PageBoundary,
  ReadingListStory,
  dim,
  BaseTheme,
  RoundedBox,
  Box,
} from '@astc/frontend-components';
import { LoadingState, ReadingList } from '@/types';
import { PageBackground } from '@/components/PageBackground';
import { SourcedImage } from '@/components/media/SourcedImage';
import { Maybe, SanityImage } from '../../../../../../graphql-types';
import styled from '@emotion/styled';
import { down, up } from 'styled-breakpoints';
import { navigate } from 'gatsby';

export interface ReadingListPageProps {
  readingList?: ReadingList;
  badgeReaders?: (string | undefined)[];
  loading: LoadingState;
  error: string | null;
  isReported: boolean;
  readCount: number;
  onShareClick(): void;
  onReportClick(): void;
  onEditClick(): void;
  onDeleteClick(): void;
}

const ReadingListBackground = styled(PageBackground)`
  position: relative;
  top: 0;
  width: 100%;
  min-height: ${dim(424)};
  background-color: ${({ theme }) => (theme as BaseTheme).colors.grays['80']};
  margin: 0 auto;
  ${down('mobile')} {
    display: none;
  }
`;

const ReadingListBackgroundMobile = styled(PageBackground)`
  position: relative;
  top: 0;
  width: 100%;
  max-width: ${dim(1440)};
  min-height: ${dim(262)};
  background-color: ${({ theme }) => (theme as BaseTheme).colors.grays['80']};
  border-radius: ${dim(8)};
  margin: 0 auto;
  overflow: hidden;
  ${up('mobile')} {
    display: none;
  }
`;

const ReadingListSpacer = styled(Box)`
  position: relative;
  top: 0;
  width: 100%;
  min-height: ${dim(424)};
  background-color: rgba(0, 0, 0, 0.73);
  z-index: -1;
  margin: 0 auto;
  ${down('mobile')} {
    min-height: ${dim(262)};
    border-radius: ${dim(8)};
    top: 0;
  }
`;

export const ReadingListPage: React.FC<ReadingListPageProps> = ({
  readingList,
  badgeReaders,
  loading,
  error,
  isReported,
  readCount = 0,
  onShareClick,
  onReportClick,
  onEditClick,
  onDeleteClick,
}) => {
  if (loading === 'failed') return <div>Error: {error}!</div>;
  if (loading === 'pending' || loading === 'idle' || !readingList)
    return <div>Loading...</div>;
  return (
    <PageBackground
      position="center center"
      mb={[dim(-262), dim(-424)]}
      style={{
        flexDirection: 'column',
      }}
    >
      <ReadingListBackground
        minHeight={dim(424)}
        desktop={readingList.stories[0]?.imageHorizontal as Maybe<SanityImage>}
        position="center center"
      >
        <ReadingListSpacer />
      </ReadingListBackground>
      <ReadingListBackgroundMobile
        minHeight={dim(262)}
        mobile={readingList.stories[0]?.imageHorizontal as Maybe<SanityImage>}
        position="center center"
      >
        <ReadingListSpacer />
      </ReadingListBackgroundMobile>
      <PageBoundary top={[dim(-262), dim(-424)]}>
        <ReadingListHeader
          name={readingList.name}
          body={readingList.description}
          id={readingList.id}
          count={{ read: readCount, total: readingList.stories?.length || 0 }}
          isReported={isReported}
          onShare={onShareClick}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          onReportClick={onReportClick}
        />
        <RoundedBox>
          {readingList.stories?.map((story) => (
            <ReadingListStory
              title={story.title}
              image={
                <SourcedImage source={story.image as Maybe<SanityImage>} />
              }
              isRead={!!badgeReaders?.includes(story.id)}
              tags={story.tags}
              onClick={() => {
                navigate(`/story/${story.id}`);
              }}
            />
          ))}
        </RoundedBox>
      </PageBoundary>
    </PageBackground>
  );
};
