import React from 'react';
import { PageFooter } from '@astc/frontend-components';
import { Link } from 'gatsby';

interface PageFooterContainerProps {}

export const PageFooterContainer: React.FC<PageFooterContainerProps> = () => {
  return (
    <PageFooter>
      <Link to={'/help'} style={{ textDecoration: 'none' }}>
        Help
      </Link>
      <Link to={'/terms'} style={{ textDecoration: 'none' }}>
        Terms
      </Link>
      <Link to={'/copyright'} style={{ textDecoration: 'none' }}>
        Copyright
      </Link>
      <Link to={'/privacy'} style={{ textDecoration: 'none' }}>
        Privacy
      </Link>
    </PageFooter>
  );
};
