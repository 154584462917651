import { createEntityAdapter } from '@reduxjs/toolkit';
import { PanelResponseResult, PanelResponseState } from '@/types';

export const panelResponseAdapter = createEntityAdapter<PanelResponseResult>({
  selectId: (result) => result.panelResponse?.panelId ?? '0',
});
export const initialState: PanelResponseState = {
  panelResponses: panelResponseAdapter.getInitialState({}),
  loading: 'idle',
  error: null,
};
