import React, { useCallback, useMemo, useState } from 'react';

import {
  BaseTheme,
  Box,
  Button1,
  dim,
  Header7,
  SignInStepBoundary,
  SignInStepForm,
  Text,
  TextInput,
} from '@astc/frontend-components';
import { Link, navigate } from 'gatsby';
import { GenericPageHeader } from '@/components/page/generic';
import styled from '@emotion/styled';
// @ts-ignore
import google from './google_classroom_signin.png';

import { Divider } from '@/app/features/user/components/SignInStep/Divider';
import { Icon } from '@iconify/react';
import envelopeSimple from '@iconify/icons-ph/envelope-simple';
import eye from '@iconify/icons-ph/eye';
import eyeSlash from '@iconify/icons-ph/eye-slash';
import passwordIcon from '@iconify/icons-ph/password';

export interface SignInStepProps {
  title: string;
  subtitle: string;
  body: string;
  forgetPasswordRoute: string;

  onLogIn(email: string, password: string): void;

  onGoogleClassroomSignIn(): void;
}

const SignInButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${dim(16)};
`;

const SignInForgotPassword = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ theme }) => (theme as BaseTheme).colors.grays[50]};
  text-decoration: none;
  text-align: center;
  margin-top: ${dim(24)};

  a,
  a:visited,
  a:active,
  a:hover {
    color: ${({ theme }) => (theme as BaseTheme).colors.grays[50]};
    text-decoration: none;
  }
`;

export const SignInStep: React.FC<SignInStepProps> = ({
  title,
  subtitle,
  body,
  onLogIn,
  forgetPasswordRoute,
  onGoogleClassroomSignIn,
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const disabled = useMemo(() => {
    return !(email && password);
  }, [password, email]);

  const handleEmailChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setEmail(ev.currentTarget.value);
    },
    [],
  );

  const handlePasswordChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setPassword(ev.currentTarget.value);
    },
    [],
  );

  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      onLogIn(email, password);
    },
    [disabled, email, password],
  );

  return (
    <SignInStepBoundary>
      <GenericPageHeader>{title}</GenericPageHeader>
      <Header7>{subtitle}</Header7>
      <Text>{body}</Text>
      <SignInStepForm onSubmit={handleSubmit}>
        <TextInput
          onChange={handleEmailChange}
          palette="blue"
          variant="primary"
          aria-label="email"
          placeholder="Email Address"
          iconLeft={<Icon icon={envelopeSimple} />}
        />
        <Box style={{ marginTop: dim(-16) }}>
          <TextInput
            onChange={handlePasswordChange}
            palette="blue"
            variant="secondary"
            aria-label="password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            iconLeft={<Icon icon={passwordIcon} />}
            iconRight={
              showPassword ? <Icon icon={eye} /> : <Icon icon={eyeSlash} />
            }
            onClickIconRight={toggleShowPassword}
          />
        </Box>
        <SignInButtons>
          <Button1
            variant="primary"
            palette="blue"
            onClick={() => {
              navigate('/onboard/signUp');
            }}
          >
            Sign Up
          </Button1>
          <Button1
            variant="primary"
            palette="blue"
            type="submit"
            disabled={disabled}
          >
            Log In
          </Button1>
        </SignInButtons>
        <SignInForgotPassword>
          <Link to={forgetPasswordRoute}>Forgot Password?</Link>
        </SignInForgotPassword>
        <Divider>OR log in with</Divider>
      </SignInStepForm>
      <input
        type={'image'}
        onClick={onGoogleClassroomSignIn}
        src={google}
        alt="Sign In With Google Classroom"
      />
    </SignInStepBoundary>
  );
};
