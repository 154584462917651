import React from 'react';
import { SanityPanelAnimation } from '../../../../graphql-types';
import { AnimationPanel } from '@astc/frontend-components';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const AnimationPanelContainer: React.FC<{
  panel: SanityPanelAnimation;
}> = ({ panel }) => {
  const handleReady = () => {};
  const { mobile: isMobile } = useBreakpoint();

  const width = isMobile ? 320 : 504;

  return (
    <AnimationPanel
      key={panel._key}
      src={panel.animation?.asset?.url || ''}
      width={width}
      alt={panel.caption || ''}
      autoplay
      onReady={handleReady}
    />
  );
};
