import { SanityPanelMultipleChoice } from '../../../../graphql-types';
import { MultipleChoicePanel } from '@astc/frontend-components';
import {
  getSourcedImageData,
  SourcedImage,
} from '@/components/media/SourcedImage';
import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useGetStoryId, useMobileSized } from '@/utils/hooks';
import { WithAliasedImages } from '@/types';
import { usePostPanelResponse } from '@/app/features/panelResponses/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { selectPanelResponseById } from '@/app/features/panelResponses/slice';

type SanityPanelMultipleChoiceAliasedImages =
  WithAliasedImages<SanityPanelMultipleChoice>;

export const MultipleChoicePanelContainer: React.FC<{
  panel: SanityPanelMultipleChoiceAliasedImages;
}> = ({ panel }) => {
  const [selected, setSelected] = useState<string | undefined>();
  const storyId = useGetStoryId();
  const { handlePanelResponse, loading, error } = usePostPanelResponse(
    storyId,
    panel._key,
  );
  const panelResponse = useSelector((state: RootState) => {
    if (!panel._key) return;
    return selectPanelResponseById(state.panelResponses, panel._key);
  });
  const handleAnswerClick = useCallback((value: string) => {
    setSelected(value);
    handlePanelResponse(JSON.stringify(value));
  }, []);
  const { mobile: isMobile } = useBreakpoint();
  useMobileSized();
  useEffect(() => {
    if (
      panelResponse?.panelResponse?.data &&
      typeof panelResponse.panelResponse.data === 'string'
    ) {
      setSelected(panelResponse.panelResponse.data);
    }
  }, [panelResponse?.panelResponse?.data]);

  const image = isMobile ? panel.mobile : panel.desktop;

  return (
    <>
      {loading === 'pending' && <div>Saving...</div>}
      {error && <div>Error: {error}</div>}
      <MultipleChoicePanel
        key={panel._key}
        image={
          image ? (
            <SourcedImage alt={panel.caption || ''} source={image} />
          ) : null
        }
        width={getSourcedImageData(image).width}
        height={getSourcedImageData(image).height}
        answers={
          panel.answers?.map((answer) => ({
            id: answer || '',
            label: answer || '',
            selected: answer === selected,
            value: answer || '',
          })) || []
        }
        caption={panel.caption || ''}
        onAnswerClick={handleAnswerClick}
        question={panel.question || ''}
      />
    </>
  );
};
