import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReadingExperience, UserRole } from '@astc/frontend-components';
import { logOutReducer } from '@/app/features/user/reducers';
import { initialState } from '@/app/features/user/initialState';
import {
  fetchEmailSignUpUser,
  fetchEmailUser,
  fetchUser,
} from '@/app/features/user/thunks';

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateRole(state, action: PayloadAction<UserRole>) {
      state.user.role = action.payload;
    },
    updateMode(state, action: PayloadAction<ReadingExperience>) {
      state.user.mode = action.payload;
    },
    logOut: logOutReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: 'succeeded' };
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.message || null;
      })
      .addCase(fetchEmailUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEmailUser.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: 'succeeded' };
      })
      .addCase(fetchEmailUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.message || null;
      })
      .addCase(fetchEmailSignUpUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEmailSignUpUser.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: 'succeeded' };
      })
      .addCase(fetchEmailSignUpUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.message || null;
      });
  },
});

export const { updateRole, updateMode, logOut } = slice.actions;

export default slice.reducer;
