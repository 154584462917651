import React from 'react';
import {
  Button2,
  Header1,
  ReadingExperience,
  READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE,
  ReadingExperienceChoice,
  ReadingExperienceChoices,
  ReadingExperienceImage,
  ReadingExperienceStepBoundary,
  Text,
} from '@astc/frontend-components';

export interface ReadingExperienceStepProps {
  title: string;
  subtitle: string;
  footer: string;

  onReadingExperienceSelect(mode: ReadingExperience): () => void;

  onNextClick(): void;
}

export const ReadingExperienceStep: React.FC<ReadingExperienceStepProps> = ({
  title,
  subtitle,
  footer,
  onReadingExperienceSelect,
  onNextClick,
}) => {
  return (
    <ReadingExperienceStepBoundary>
      <Header1>{title}</Header1>
      <Text>{subtitle}</Text>
      <ReadingExperienceChoices>
        <ReadingExperienceChoice>
          <ReadingExperienceImage
            src={`https://placehold.jp/${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.width}x${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.height}.png`}
            alt="interactive mode example thumbnail"
          />
          <Button2
            palette="green"
            onClick={onReadingExperienceSelect(ReadingExperience.INTERACTIVE)}
          >
            Interactive Mode
          </Button2>
        </ReadingExperienceChoice>
        <ReadingExperienceChoice>
          <ReadingExperienceImage
            src={`https://placehold.jp/${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.width}x${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.height}.png`}
            alt="read-only mode example thumbnail"
          />
          <Button2
            palette="green"
            onClick={onReadingExperienceSelect(ReadingExperience.READ_ONLY)}
          >
            Read Only Mode
          </Button2>
        </ReadingExperienceChoice>
      </ReadingExperienceChoices>
      <Text textAlign="center">{footer}</Text>
      <Button2 palette="blue" onClick={onNextClick}>
        Next
      </Button2>
    </ReadingExperienceStepBoundary>
  );
};
export default ReadingExperienceStep;
