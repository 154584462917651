import {
  BaseTheme,
  Box,
  Caption,
  dim,
  Header4,
} from '@astc/frontend-components';
import React from 'react';
import styled from '@emotion/styled';
import { SanityBlockFeatured } from '../../../graphql-types';
import { ComicCardContainer } from '@/components/FeaturedStories/ComicCardContainer';
import { HorizontalComicCardContainer } from '@/components/FeaturedStories/HorizontalComicCardContainer';
import { VerticalComicCardContainer } from '@/components/FeaturedStories/VerticalComicCardContainer';
import { down } from 'styled-breakpoints';

type FeaturedStoriesProps =
  | Omit<SanityBlockFeatured, 'id' | 'children' | 'internal'> & {
      alignment?: string;
      clear?: boolean;
    };

const StyledFeaturedStories = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 0 0;
  max-width: ${dim(1440)};
  margin: ${dim(64)} auto;
  ${down('mobile')} {
    margin: 0 auto;
  }
`;

const FeaturedStoriesHeader = styled(Box)`
  padding: 0 0 ${({ theme }) => (theme as BaseTheme).space[1]} ${dim(104)};
  ${down('mobile')} {
    padding: 0 ${({ theme }) => (theme as BaseTheme).space[3]}
      ${({ theme }) => (theme as BaseTheme).space[1]};
  }
`;

const FeaturedStoriesContent = styled(Box)<{
  size: string;
  alignment?: string;
}>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: ${({ alignment }) =>
    !!alignment && alignment === 'right' ? 'row-reverse' : 'row'};
  width: 100%;
  height: ${({ size }) => dim(size === 'sm' ? 640 : 448)};
  padding: ${({ size }) => (size === 'md' ? dim(8) : dim(64))}
    ${({ theme }) => (theme as BaseTheme).space[3]} ${dim(8)} ${dim(104)};
  justify-content: flex-start;
  align-items: ${({ size }) => (size === 'md' ? 'center' : 'flex-start')};
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  ${down('mobile')} {
    align-items: flex-start;

    height: ${({ size }) =>
      dim(size === 'lg' ? 288 : size === 'sm' ? 420 : 350)};
    padding: ${({ theme }) => (theme as BaseTheme).space[2]}
      ${({ theme }) => (theme as BaseTheme).space[3]}
      ${({ theme }) => (theme as BaseTheme).space[3]};
  }
`;

export const FeaturedStories: React.FC<FeaturedStoriesProps> = ({
  title,
  subtitle,
  stories,
  size,
  alignment,
  clear = false,
}) => {
  return (
    <StyledFeaturedStories>
      {(title || subtitle) && (
        <FeaturedStoriesHeader>
          {title && <Header4>{title}</Header4>}
          {subtitle && <Caption>{subtitle}</Caption>}
        </FeaturedStoriesHeader>
      )}
      <FeaturedStoriesContent size={size || 'lg'} alignment={alignment}>
        {/*<Box style={{ display: 'flex', flexDirection: 'row' }}>*/}
        {stories?.map((story) => {
          if (size === 'sm') {
            return (
              <ComicCardContainer onlyAuthors story={story} clear={clear} />
            );
          }
          if (size === 'md') {
            return <HorizontalComicCardContainer onlyAuthors story={story} />;
          }
          if (size === 'lg') {
            return <VerticalComicCardContainer onlyAuthors story={story} />;
          }
          return null;
        })}
        {/*</Box>*/}
      </FeaturedStoriesContent>
    </StyledFeaturedStories>
  );
};
