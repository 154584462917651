import React, { useCallback } from 'react';
import { Link } from 'gatsby';
import {
  BadgeThumbnail,
  BaseTheme,
  dim,
  Gear,
  PageBoundary,
  ProfileViewAccount,
  ProfileViewAccountMobile,
  ProfileViewActions,
  ProfileViewBadges,
  ProfileViewBadgesHeader,
  ProfileViewCreateAssignmentHeader,
  ProfileViewHeader,
  ProfileViewTitle,
  ProfileViewHeaderTitle,
  ProfileViewReadingLists,
  ProfileViewReadingListsHeader,
  ReadingExperience,
  ReadingListsItem,
  Text,
  Box,
  Header5,
  CounterHeader,
  randomListColor,
  Button1,
  RoundedBox,
  ProfileViewHeaderDesktop,
  ProfileViewHeaderMobile,
  ProfileViewCreateAssignment,
  ProfileViewInstructions,
} from '@astc/frontend-components';
import { Icon } from '@iconify/react';
import playFill from '@iconify/icons-bi/play-fill';
import { Badge, ReadingList, ReadingListResult } from '@/types';
import { useTheme } from '@emotion/react';
import { SourcedImage } from '@/components/media/SourcedImage';
import { Maybe, SanityImage } from '../../../../../../graphql-types';
import {
  ReadingListsPageDeleteOverlay,
  ReadingListsPageEditOverlay,
} from '../../../readingLists/components/overlays/readingList';
import styled from '@emotion/styled';
// @ts-ignore
import googleClassroomIcon from './google_classroom_icon.png';

export interface ProfileData {
  email: string;
  readingExperience?: ReadingExperience;
  badges: Badge[];
  readingListResults: ReadingListResult[];
}

export type ProfileViewProps = Omit<ProfileData, 'readingExperience'> & {
  profileSettingsRoute: string;
  badgesRoute: string;
  readingListsRoute: string;
  onReadingListProfileItemClick(id: string): void;
  onCreateAssignment(): void;
  onLogOut(): void;
};

export const ProfileViewControl: React.FC<ProfileViewProps> = ({
  email,
  badges,
  readingListResults,
  profileSettingsRoute,
  badgesRoute,
  readingListsRoute,
  onReadingListProfileItemClick,
  onCreateAssignment,
  onLogOut,
}) => {
  const {
    colors: { blues },
  } = useTheme() as BaseTheme;
  const [editReadingListOverlay, setEditReadingListOverlay] =
    React.useState(false);
  const [deleteReadingListOverlay, setDeleteReadingListOverlay] =
    React.useState(false);
  const [activeReadingList, setActiveReadingList] =
    React.useState<ReadingList | null>(null);
  const handleItemClick = useCallback(
    (id: string) => {
      return () => {
        onReadingListProfileItemClick(id);
      };
    },
    [onReadingListProfileItemClick],
  );
  return (
    <PageBoundary pt="5">
      <ProfileViewHeader>
        <ProfileViewTitle>My Profile</ProfileViewTitle>
        <Link to={profileSettingsRoute} aria-label="Profile settings">
          <Gear />
        </Link>
      </ProfileViewHeader>
      <ProfileViewAccount>
        <Box flex="1">
          <ProfileViewHeaderDesktop style={{ marginBottom: dim(8) }}>
            Account Email
          </ProfileViewHeaderDesktop>
          <ProfileViewHeaderMobile>Account Email</ProfileViewHeaderMobile>
          <Text>{email}</Text>
        </Box>
        <Box
          display="flex"
          flex="0"
          minWidth={dim(196)}
          flexDirection="column"
          justifyContent="center"
        >
          <Button1 palette="gray" variant="secondary" onClick={onLogOut}>
            Log Out
          </Button1>
        </Box>
      </ProfileViewAccount>
      <ProfileViewAccountMobile>{email}</ProfileViewAccountMobile>
      {!!badges && (
        <>
          <ProfileViewBadgesHeader>
            <CounterHeader
              title="Badges"
              amount={badges.filter((badge) => badge.unlocked).length}
              unit="Unlocked"
              unitPlural="Unlocked"
            />
            <Link to={badgesRoute} aria-label="See all badges">
              <Icon icon={playFill} color={blues['60']} width={32} />
            </Link>
          </ProfileViewBadgesHeader>
          <ProfileViewInstructions>
            Unlock badges by reading through stories and completing the learning
            panels.
          </ProfileViewInstructions>
          <ProfileViewBadges>
            {badges?.map((badge) => {
              if (!badge) return null;
              return (
                <li key={badge.name}>
                  <BadgeThumbnail unlocked={badge.unlocked}>
                    <SourcedImage
                      source={badge.images.thumbnail as SanityImage}
                    />
                  </BadgeThumbnail>
                </li>
              );
            })}
          </ProfileViewBadges>
        </>
      )}
      <>
        <ProfileViewCreateAssignmentHeader>
          <ProfileViewHeaderDesktop mt="0">
            Create Assignment
          </ProfileViewHeaderDesktop>
          <ProfileViewHeaderMobile mt="0">
            Create Assignment
          </ProfileViewHeaderMobile>
        </ProfileViewCreateAssignmentHeader>
        <RoundedBox mb={[4, 2]}>
          <Box
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="space-between"
            mb={[0, 4]}
          >
            <ProfileViewInstructions>
              Create Google classroom assignments
              <br /> to share with your students
            </ProfileViewInstructions>
            <ProfileViewCreateAssignment
              iconLeft={
                <img src={googleClassroomIcon} alt="" width={33} height={33} />
              }
              onCreateAssignment={onCreateAssignment}
            />
          </Box>
        </RoundedBox>
      </>
      {!!readingListResults && (
        <>
          <ProfileViewReadingListsHeader>
            <CounterHeader
              title="Reading Lists"
              amount={readingListResults.length}
              unit="List"
            />
            <Link to={readingListsRoute} aria-label="See all reading lists">
              <Icon icon={playFill} width={32} color={blues['60']} />
            </Link>
          </ProfileViewReadingListsHeader>
          <ProfileViewReadingLists>
            {readingListResults.map((listResult) => {
              const { readingList: list } = listResult;
              if (!list) return;
              const image = list.stories[0]?.imageVertical;
              <SourcedImage
                source={list.stories[0]?.imageVertical as Maybe<SanityImage>}
                style={{ width: '109px' }}
              />;

              return (
                <ReadingListsItem
                  key={list.id}
                  readingList={{
                    ...list,
                    count: { total: list.stories.length, read: 0 },
                  }}
                  readingListImage={
                    image ? (
                      <SourcedImage source={image} style={{ width: '109px' }} />
                    ) : null
                  }
                  frameColor={randomListColor(list.id.substring(0, 2))}
                  onItemClick={handleItemClick(list.id)}
                  onEditClick={() => {
                    setActiveReadingList(list);
                    setEditReadingListOverlay(true);
                  }}
                  onDeleteClick={() => {
                    setActiveReadingList(list);
                    setDeleteReadingListOverlay(true);
                  }}
                />
              );
            })}
          </ProfileViewReadingLists>
          {editReadingListOverlay && activeReadingList && (
            <ReadingListsPageEditOverlay
              readingList={activeReadingList}
              onClose={() => {
                setEditReadingListOverlay(false);
                setActiveReadingList(null);
              }}
            />
          )}
          {deleteReadingListOverlay && activeReadingList && (
            <ReadingListsPageDeleteOverlay
              readingList={activeReadingList}
              onClose={() => {
                setDeleteReadingListOverlay(false);
                setActiveReadingList(null);
              }}
            />
          )}
        </>
      )}
      <ProfileViewActions>
        <Button1 palette="gray" variant="secondary" onClick={onLogOut}>
          Log Out
        </Button1>
      </ProfileViewActions>
    </PageBoundary>
  );
};
