import React from 'react';
import { GenericPage } from '@/components/page/generic';
import { graphql, PageProps } from 'gatsby';
import { SanityPageCopyrightQuery } from '../../graphql-types';

type BTSProps = { data: SanityPageCopyrightQuery } & PageProps;

export const CopyrightPage: React.FC<BTSProps> = ({ data }) => {
  const { sanityPageGeneric } = data;
  return (
    <GenericPage
      title={sanityPageGeneric?.title}
      elements={sanityPageGeneric?.content?.elements || []}
    />
  );
};

export const gql = graphql`
  query SanityPageCopyright {
    sanityPageGeneric(slug: { current: { eq: "copyright" } }) {
      slug {
        current
      }
      title
      content {
        elements {
          ... on SanityHeader {
            _key
            _type
            text
          }
          ... on SanityBody {
            _key
            _type
            text
          }
          ... on SanityImage {
            _key
            _type
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default CopyrightPage;
